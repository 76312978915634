import { produce } from 'immer';
import { create } from 'zustand';



export type TWSysLayoutPageMode = 'LASTONLY' | 'COLUMNS' | 'POPUPS';




export const TWSysColorDeriveMethods = ['DIRECT', 'SHADE'] as const;
export type TWSysColorDeriveMethod = typeof TWSysColorDeriveMethods[number];

export interface WSysColorValue {
    value: string;
    derived?: {
        method: TWSysColorDeriveMethod;
        color1?: string;
        color2?: string;
        p1?: number;
        transp?: number;
    };
}

export interface WSysColors {
    theme1: WSysColorValue;
    theme2: WSysColorValue;
    menu: WSysColorValue;
    tableHead: WSysColorValue;

}

export type TWSysColor = keyof WSysColors;

export interface IWSysCorePreferences {

    darkMode: boolean;

    baseColor: string;
    selectionColor: string;

    colors: WSysColors;

    /*secondaryColor: string;
    selectionColor: string;
    mutationColor: string;
    contrast: keyof IBG_PATTERN;*/

    modeMain: TWSysLayoutPageMode;
    modePopup: TWSysLayoutPageMode;
    modeAttach: TWSysLayoutPageMode;

    layoutAlign: 'LEFT' | 'CENTER';
}


export interface IWSysCoreLayoutState {
    showPreferences: boolean,
    showDebug: boolean,

    dumpPath: boolean;
    dumpApi: boolean;
    dumpLayout: boolean,
    dumpLayoutCalc: boolean,
    dumpFormTokens: boolean,
    /*dumpClick: boolean,
    dumpPanels: boolean,
    dumpPanelsScroll: boolean,
    dumpAutocomplete: boolean,
    dumpTable: boolean,
    dumpForm: boolean,*/
}





// ------------------------------- LOAD --------------------------------------
let savedPreferences: Partial<IWSysCorePreferences> = {}
try {
    savedPreferences = JSON.parse(window.localStorage.getItem('PREFERENCES') as any || {}) as any;
} catch (ex) {
    console.error(ex);
}
let savedState: Partial<IWSysCoreLayoutState> = {}
try {
    savedState = JSON.parse(window.localStorage.getItem('UX_STATE') || "{}") as any;
} catch (ex) {
    console.error(ex);
}

console.log(savedPreferences);


// ------------------------------- ISTATE --------------------------------------
export interface IWSysCoreState {
    layout: IWSysCoreLayoutState;
    preferences: IWSysCorePreferences;
    unsavedforms: { url: string }[];

    showPreferencesSet: (v: boolean) => void;
    showDebugSet: (v: boolean) => void;
    preferencesSet: (v: Partial<IWSysCorePreferences>) => void;
    colorSet: (v: { colorKey: TWSysColor, colorValue: WSysColorValue }) => void;
    coreStateSet: (v: Partial<IWSysCoreLayoutState>) => void;
    unsavedAdd: (v: string) => void;
    unsavedRemove: (v: string) => void;
}




export const useWSysCoreSlice = create<IWSysCoreState>()(
    (set, get) => ({
        layout: {
            showPreferences: false,
            showDebug: false,

            dumpPath: false,
            dumpApi: false,
            dumpLayout: false,
            dumpLayoutCalc: false,
            dumpFormTokens: false,
            ...savedState
        },
        preferences: {
            darkMode: window.localStorage.getItem('chakra-ui-color-mode') === 'dark',
            baseColor: savedPreferences.baseColor || '#2c6195',
            selectionColor: savedPreferences.selectionColor || '#ffffcc',
            modeMain: savedPreferences.modeMain || 'POPUPS',
            modeAttach: savedPreferences.modeAttach || 'COLUMNS',
            modePopup: savedPreferences.modePopup || 'POPUPS',
            layoutAlign: savedPreferences.layoutAlign || 'CENTER',

            colors: {
                theme1: savedPreferences.colors?.theme1 || { value: '#2c6195' },
                theme2: savedPreferences.colors?.theme2 || { value: '#2c6195' },
                menu: savedPreferences.colors?.menu || { value: '#2c6195' },
                tableHead: savedPreferences.colors?.tableHead || { value: '#2c6195' },
            }
        },
        unsavedforms: [],
        /*xshowPreferencesSet: (state, action: PayloadAction<boolean>) => {
            state.layout.showPreferences = action.payload;
        },*/
        showPreferencesSet: (v) => set(produce((state: IWSysCoreState) => {
            state.layout.showPreferences = v;
        })),
        showDebugSet: (v) => set(produce((state: IWSysCoreState) => {
            state.layout.showDebug = v;
        })),
        preferencesSet: (v) => set(produce((state: IWSysCoreState) => {
            state.preferences = { ...state.preferences, ...v };
            //nagyon fújos:
            window.localStorage.setItem('PREFERENCES', JSON.stringify(state.preferences));
            window.localStorage.setItem('PREFERENCES___', JSON.stringify(new Date().toTimeString()));
        })),
        colorSet: (v) => set(produce((state: IWSysCoreState) => {
            state.preferences.colors[v.colorKey] = v.colorValue;
            //nagyon fújos:
            window.localStorage.setItem('PREFERENCES', JSON.stringify(state.preferences));
        })),
        coreStateSet: (v) => set(produce((state: IWSysCoreState) => {
            state.layout = { ...state.layout, ...v };
            //nagyon fújos:
            window.localStorage.setItem('UX_STATE', JSON.stringify(state.layout));
        })),
        unsavedAdd: (v) => set(produce((state: IWSysCoreState) => {
            state.unsavedforms.push({ url: v });
        })),
        unsavedRemove: (v) => set(produce((state: IWSysCoreState) => {
            state.unsavedforms = []; //TODO
        })),

    })
);
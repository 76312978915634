import { theme, ToastProvider } from '@chakra-ui/react';
import { AppTheme, WSysPath } from '@coimbra-its/websys-ux-core';
import "@fontsource/montserrat";
import ReactDOM from 'react-dom/client';
import App from './App';
import { APPICONS } from './Icons';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import { mode } from '@chakra-ui/theme-tools'


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const preferencesCompanyDefault_PROD = `{"darkMode":false,"baseColor":"#E35B3D","selectionColor":"#ffffcc","modeMain":"LASTONLY","modeAttach":"COLUMNS","modePopup":"POPUPS","layoutAlign":"CENTER","colors":{"theme1":{"value":"#2c6195"},"theme2":{"value":"#E35B3D"},"menu":{"value":"#00000033","derived":{"method":"SHADE","color1":"theme1","p1":100,"transp":20}},"tableHead":{"value":"#2f679d26","derived":{"method":"SHADE","color1":"theme1","p1":60,"transp":15}}}}`;
const preferencesCompanyDefault_UAT = `{"darkMode":false,"baseColor":"#E35B3D","selectionColor":"#ffffcc","modeMain":"LASTONLY","modeAttach":"COLUMNS","modePopup":"POPUPS","layoutAlign":"CENTER","colors":{"theme1":{"value":"#2c6195"},"theme2":{"value":"#E35B3D"},"menu":{"value":"#00000033","derived":{"method":"SHADE","color1":"theme1","p1":100,"transp":20}},"tableHead":{"value":"#2f679d26","derived":{"method":"SHADE","color1":"theme1","p1":60,"transp":15}}}}`;
const preferencesCompanyDefault = process.env.REACT_APP_STAGE === 'uat' ? preferencesCompanyDefault_UAT : preferencesCompanyDefault_PROD;

const chakraTheme = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
	colors: {
		cardBg: '#09172f',
		wsysLine: 'transparent',
	},
	fonts: {
		body: `montserrat`,
	},	
	styles: {
		global: {
			'body': {
				backgroundImage: 'url(/bg1.png)',
				backgroundPosition: 'top',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundAttachment: 'fixed',
			},
			'.article h2' :{
				fontSize: '24px',
				fontWeight: 'bold'
			}
		}
	},
	components: {
		Button: {
			variants: {
				wsysHeadIconButton: (props: any) => ({
					...theme.components.Button.variants!.link(props),
					color: '#fff'
				})
			}
		}


	}
};



root.render(

	<WSysPath>
		<AppTheme icons={APPICONS} preferencesCompanyDefault={preferencesCompanyDefault} appCustomChakraTheme={chakraTheme} >
			<App />
			<ToastProvider />
		</AppTheme>
	</WSysPath>


);
//</React.StrictMode>

serviceWorkerRegistration.register();
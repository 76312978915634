import { useWebSysForm, useWSysPage, WSysField, WSysInput, WSysPage } from "@coimbra-its/websys-ux-core"
import { SunMainPage, SunPage } from "../../components/Sun"
import { useAdminSunmoneyNetApi } from "../../store/sunmoney.net.api"
import { IMLM_USER, MLM_USER_META } from "@coimbra-its/api.sunmoney.net";
import { useCallback } from "react";




export function User({ id }: { id: number }) {

    const api = useAdminSunmoneyNetApi();

    const [data, hub] = api.userIdGet.useData({ id }, { skip: id <= 0 });
    const userIdPost = api.userIdPost.useAction();



    // ---- form ----
    const formRef = useWebSysForm<{ USER: IMLM_USER }>({
        isCreate: false, data
    });

	const onSave = useCallback(() => {
		hub.tryCatch(async () => {
			await userIdPost({ id,  USER: fej.formRow$.formRow.data });
		});
	}, []);    

    const onCancel = useCallback(()=> {
        page.close();
    }, [])    
    const formTools = formRef.useTools(onSave, onCancel);

    const fej = formRef.useRow({
        extract: data => data.USER,
        meta: MLM_USER_META,
    })
    

    // ---- page ----
    const page = useWSysPage({ title: data?.USER.name || "User", maxCols: 2, ...formTools.forPage, variant:'popup' })



    return <SunPage
        hubs={[hub]}
        page={page}
        tools={formTools.tools(page)}
        p={2}
    >

        <WSysField formRow={fej} field="name">
            <WSysInput />
        </WSysField>
        <WSysField formRow={fej} field="id_number">
            <WSysInput readOnly />
        </WSysField>
        <WSysField formRow={fej} field="email">
            <WSysInput  />
        </WSysField>

    </SunPage>



}
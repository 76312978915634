import { WSysHubDisplay, WSysPage, WSysPageProps, useWSysPage } from "@coimbra-its/websys-ux-core";
import { Button, Box, VStack, ButtonProps, Icon, useColorModeValue, BoxProps, Text, TextProps, FormLabel, FormLabelProps, Heading, StackProps } from '@chakra-ui/react'
import { APPICONS } from "../Icons";
import { ReactNode } from "react";


const sunBorderColor = '#D6D6D6';
const sunBorder = {
    border: `2px solid ${sunBorderColor}`,
    borderRadius: '48px',
    paddding: '4px',
    minHeight: '48px',
}
const sunGray = '#646464'


// ===========================================================
// -----------------------  STACK   ---------------------------
// ===========================================================

export function SunStack({ ...rest }: StackProps) {
    return <VStack alignItems='stretch' justifyContent='center' h='100%' gap={9} px='4em' {...rest} />
}

// ===========================================================
// -----------------------  PAGE   ---------------------------
// ===========================================================

export interface SunMainPageProps extends WSysPageProps {
    opaque?: boolean;
    sp?: number;
}


export function SunMainPage({ children, page, tools, hubs, opaque=false,sp=5, ...rest }: SunMainPageProps) {
    //const bgColor = useColorModeValue('hsla(0,0%,100%,1)', 'var(--chakra-colors-chakra-body-bg)');
    
    
    const bgColorSolid = useColorModeValue('hsla(0,0%,100%,1)', '#0c061cc9');
    const bgColorOpaque = useColorModeValue('hsl(243.89deg 69.38% 13.32% / 20%)', '#0c061c24');
    const bgColor = opaque ? bgColorOpaque : bgColorSolid;
    
        

    return <WSysPage page={page} bg='transparent'
        vertMode="scroll"
        headerTitle={
            <Box flex='1' textAlign='center'>
                <Heading as='h1' color='hsla(0,0%,100%,.8)' fontWeight='600' fontSize='3xl'
                >{page.propsIn.title}</Heading>
            </Box>}
        tools={tools || <>&nbsp;</>}
        {...rest}
    >
        <Box bg={bgColor} p={sp} borderRadius='lg' minH='50vh' position='relative' >
            <WSysHubDisplay hubs={hubs}>
                {children}
            </WSysHubDisplay>
        </Box>
    </WSysPage>
}

export function SunPage({ ...rest }: WSysPageProps) {
    return <WSysPage {...rest} />
}


// ===========================================================
// ----------------------- LINK BUTTON -----------------------
// ===========================================================


export interface SunLinkButtonProps extends ButtonProps {

}

export function SunLinkButton({ children, ...rest }: SunLinkButtonProps) {

    const color = '#1b1b1b';

    return <Button
        variant='link' size='md'
        {...sunBorder}
        color={color}
        justifyContent='space-between'
        p='4px'

        leftIcon={
            <Box
                display='flex' alignItems='center' justifyContent='center'
                width='48px' height='48px'
                borderRadius='24px'
                bg='linear-gradient(96.55deg,#e92424 -49.33%,#fec261 158.4%)'
                color='#fff'
            >
                <Icon as={APPICONS.Edit} />
            </Box>}

        rightIcon={<Icon as={APPICONS.Right}
            color={sunGray}
            mr='10px'
        />}

        {...rest}
    ><Box flex='1' textAlign='left' pl={4}>
            {children}
        </Box>
    </Button>
}


// ================================================================
// -----------------------  ROUNDED BOX ---------------------------
// ================================================================

export interface SunBlockProps extends BoxProps {
    header?: ReactNode;
}

export function SunBlock({ children, header, ...rest }: SunBlockProps) {
    return <Box>
        {header && <Box
            color={sunGray} fontWeight='600'
        >{header}</Box>}
        <Box
            {...sunBorder}
            display='flex' flexDir='row' alignItems='center'
            justifyContent='center'
            px={2}
            {...rest}
        >

            {children}


        </Box>
    </Box>
}


export interface SunValueProps extends TextProps {
    dim?: ReactNode;
}

export function SunValue({ children, dim, ...rest }: SunValueProps) {
    return <Box whiteSpace='nowrap'>
        <Text as='span' flexDir='row'
            fontWeight='bold' fontSize='large'
            {...rest}>
            {children}
        </Text>
        {dim && <Text as='span' display='inline-block' px={2}  >{dim}</Text>}
    </Box>
}



// ===========================================================
// ----------------------- BUTTON -----------------------
// ===========================================================


export interface SunButtonProps extends ButtonProps {
    variant?: 'primary' | 'black' | 'outline'
}

export function SunButton({ children, variant = 'primary', ...rest }: SunButtonProps) {
    const sunBg = 'linear-gradient(96.55deg,#e92424 -49.33%,#fec261 158.4%)';

    return <Button {...rest}
        borderRadius='30px'
        {...variant === 'primary' && { variant: 'solid', colorScheme: "orange", bg: sunBg, _hover: { bg: sunBg, color: '#000' } }}
        {...variant === 'black' && { variant: 'solid', bg: "#555", colorScheme: 'black', _hover: { bg: '#333' } }}

    >{children}</Button>

}


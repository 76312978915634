



// ===================================== PACKAGE ==================================

import { IEnumList } from "@coimbra-its/websys-lib";

export type TSunPackageType = 'community-package' | 'solar-package' | 'SDBN-package' | 'voucher';
export const SunPackageTypeList: IEnumList<TSunPackageType> = [
    { KOD: 'community-package', MEGN: 'Community package' },
    { KOD: 'solar-package', MEGN: 'Solar package' },
    { KOD: 'SDBN-package', MEGN: 'SDBN package' },
    { KOD: 'voucher', MEGN: 'Voucher' },
];

// ===================================== TREE ==================================
export interface ITreeItem {
    //root_id : number;
    user_id: number;
    level: number;
    //roots_child_id : number;
    //roots_edge_index : number;
    id_number: number;
    name: string;
    edge_index: number;
    parent_user_id: number;
    left_w: number;
    right_w: number;
    left_child_count: number;
    right_child_count: number;
}



// ===================================== DOCUMENTS ==================================

export interface TSunUploadedFile {
    filename: string;
}

export interface ISunBalanceItem {
    name: string, 
    balance: number, 
    dim: string
}


// ===================================== CHART ==================================
export type TChartDataItem = {
    month: number;
    solar_royality_eur: number;
};


export type TChartData = Array<{
    year: number;
    monthly: Array<TChartDataItem>
}>;


export interface IArticleLead {
    id: number;
    title: string;
    slug: string;
    lead: string;
}

export interface IArticle extends IArticleLead {
    content: string;
}
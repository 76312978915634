import { Alert, AlertDescription, AlertIcon, AlertProps, AlertTitle, Box, BoxProps, Button, CircularProgress, ToastState, useColorModeValue, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export interface IWSysHubEventAction {
	caption: string;
	callback: () => void;
}


export interface IWSysHubEvent {
	severity: 'success' | 'error' | 'warning' | 'info' | 'loading';
	message: string;
	details?: string;
	duration?: number;
	actions?: IWSysHubEventAction[];
}

const WSYS_HUB_EMPTY: IWSysHubState = {
	isLoading: false, hasError: false, events: []
}

export interface IWSysHubState {
	isLoading: boolean;
	hasError: boolean;
	events: IWSysHubEvent[];
}

export type IWSysHubStateSubscriber = (state: IWSysHubState) => void;

export interface IWSysHub {
	isLoading: boolean;
	hasError: boolean;
	addEvent: (event: IWSysHubEvent) => void;
	removeEvent: (event: IWSysHubEvent) => void;
	reset: () => void;
	setLoading: () => { finished: () => void };
	useSubscribe: () => IWSysHubState;
	tryCatch: (cb: () => void) => void;
}

export function useWSysHub() {

	const toast = useToast();
	const subscribers = useMemo(() => (new Set<IWSysHubStateSubscriber>()), []);
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const state = useRef<IWSysHubState>({
		hasError: false,
		isLoading: false,
		events: []
	});

	const getHubStateSnapshot = (): IWSysHubState => {
		let isLoading = false;
		let hasError = false;
		state.current.events.forEach(msg => {
			if (msg.severity === 'error')
				hasError = true;
			if (msg.severity === 'loading')
				isLoading = true;
		});
		return {
			isLoading, hasError, events: [...state.current.events]
		}
	}

	const broadcast = () => {
		const currentState = getHubStateSnapshot();
		setIsLoading(currentState.isLoading);
		setHasError(currentState.hasError);

		let van = false;
		subscribers.forEach(mc => {
			van = true;
			mc(currentState);
		});
		return van;

	}

	const addEvent = useCallback((event: IWSysHubEvent) => {
		state.current.events.push(event);
		if (!broadcast()) {
			if (event.severity !== 'loading')
				toast({ description: event.details, title: event.message, status: event.severity });
		}
	}, []);

	const removeEvent = useCallback((msg: IWSysHubEvent) => {
		const ix = state.current.events.indexOf(msg);
		if (ix >= 0)
			state.current.events.splice(ix, 1);
		broadcast();
	}, []);

	const reset = useCallback(() => {
		state.current.events = [];
		broadcast();
	}, []);



	const ret: IWSysHub = useMemo(() => ({
		addEvent, removeEvent, reset,
		hasError,
		isLoading,
		setLoading: () => {
			const loadingEvent: IWSysHubEvent = { severity: 'loading', message: 'Loading...' };
			addEvent(loadingEvent);
			return {
				finished: () => { removeEvent(loadingEvent); }
			}
		},
		tryCatch: async (cb: () => void) => {
			const loadingEvent: IWSysHubEvent = { severity: 'loading', message: 'Loading...' };
			addEvent(loadingEvent);
			try {
				await cb();
			} catch (ex) {
				addEvent({
					message: ex + '', severity: 'error', actions: [{
						caption: 'Ok', callback: () => {
							reset();
						}
					}]
				});
			} finally {
				removeEvent(loadingEvent);
			}
		},
		/* na ez itt furán működik: 
			- useState és useEffect miatt nem tudom useCallback-be tenni, tehát a függvény mindig új
			- de! minthogy egy komponensben adott helyen lesz használva a useState és useEffect működni fog, nem iratkozik fel-le vagy ilyesmi */
		useSubscribe: () => {
			const [hubState, setHubState] = useState<IWSysHubState>(WSYS_HUB_EMPTY);

			useEffect(() => {
				subscribers.add(setHubState);
				return () => {
					subscribers.delete(setHubState);
				};
			}, [setHubState]);

			return hubState;
		}
	}), [addEvent, removeEvent, reset, subscribers, hasError, isLoading]);






	return ret;
}









// ====================================================== REMOTE ===============================================
export interface WSysHubDisplayProps extends BoxProps {
	hubs?: IWSysHub[];
}

export function WSysHubDisplay({ hubs = [], children }: WSysHubDisplayProps) {
	const bg = useColorModeValue('#fff6', '#0006');

	if (hubs.length <= 0)
		return <>{children}</>

	const hubStates = hubs.map(hub => hub.useSubscribe());



	const anyError = hubStates.find(hub => hub.hasError);
	const anyLoading = hubStates.find(hub => hub.isLoading);

	const showBackdrop = anyError || anyLoading;


	return <>
		{children}
		{showBackdrop && <Box className="ws-panel-remote-backdrop" position='absolute'
			left={0} right={0} top={0} bottom={0} zIndex={1} bg={bg} transition='background-color .4s ease-out'
			display='flex' flexDir='column' alignItems='center' justifyContent='center'>

			{!anyError && anyLoading && <CircularProgress isIndeterminate color='primary.main'  />}

			{hubStates.filter(hub => hub.hasError).map(hub =>
				<Alert status="error" maxW='60%' flexDir='column' boxShadow='md' p={2} >
					<Box display='flex'>
						<AlertTitle>Szerver válasz:</AlertTitle>
					</Box>
					<AlertDescription display='flex' flexDirection='column' alignItems='center' gap={3} pt={3}  >
						{hub.events.map((event, mix) => <Box key={mix}>
							<AlertIcon display='inline-block' />{event.message}
							{(event.actions || []).map(action => <Button variant='solid' colorScheme="red" size='xs' ml={4} onClick={action.callback}>{action.caption}</Button>)}
						</Box>)}
					</AlertDescription>
				</Alert>)}
		</Box>}
	</>
}


import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IWSysHub, useWSysHub } from "./api.hub";
import { WSysEndpoint } from "@coimbra-its/websys-lib";
import { WSysLog, WSysLogSeverity } from "./utils.log";
import { useWSysCoreSlice } from "../store";


const log = WSysLog.asSource({ name: 'API', dark: '#337', light: 'lightblue' }, (sev) => sev >= WSysLogSeverity.INFO
	|| useWSysCoreSlice.getState().layout.dumpApi
);

export interface IWSysEndpointClientProps {
	init?: RequestInit,
	hub?: IWSysHub,
	skip?: boolean;
}

export interface IWSysEndpointClientResponse<TResponse> {
	data?: TResponse;
	hub: IWSysHub;
}

export type IWSysUseAction<TRequest, TResponse> = (req: TRequest) => Promise<TResponse>;



interface IApiClient<TRequest, TResponse> {
	useData: (req: TRequest, props?: IWSysEndpointClientProps) => readonly [TResponse, IWSysHub];
	useAction: () => IWSysUseAction<TRequest, TResponse>;
}


type TApiClientList<TList> = { //<TRequest, TResponse>
	[EP in keyof TList]: IApiClient<TList[EP] extends WSysEndpoint<infer TRequest, infer TResponse> ? TRequest : never
		, TList[EP] extends WSysEndpoint<infer TRequest, infer TResponse> ? TResponse : never>;
};




export class WSysApi<TEndpointList extends object> {
	constructor(public API_BASE: string | undefined, private _apiEndpoints: TEndpointList, private prepare: (headers: HeadersInit) => void) {
		if (!this.API_BASE)
			throw new Error('API_BASE missing');
		if (this.API_BASE.endsWith('/'))
			this.API_BASE = this.API_BASE.substring(0, this.API_BASE.length - 1);


		// -------------------------------------------------------------- PROXY -------------------------------------
		const _thisApi = this;
		this._clientEndpoints = new Proxy(this._apiEndpoints, {
			get(target, prop, reciver) {
				return {
					useData: (req: any, fetchProps: any) => {
						const ep = (target as any)[prop as any] as WSysEndpoint<any, any>;
						return _thisApi.useData(ep, req, fetchProps);
					},
					useAction: () => {
						const ep = (target as any)[prop as any] as WSysEndpoint<any, any>;
						return (req: any, fetchProps: any) => {
							return _thisApi.useAction(ep, req, fetchProps);
						};
					}
				};
			}
		}) as unknown as typeof this._clientEndpoints;

	}


	private _clientEndpoints: TApiClientList<TEndpointList>;
	public get ep() { return this._clientEndpoints; }

	private invalidateSubscribers = new Set<(tags: string[]) => void>();
	private invalidate(tags: string[]) {
		log.debug('***** INVALIDATE', tags)();
		this.invalidateSubscribers.forEach(sub => {
			sub(tags);
		});
	}



	// ==========================================================================================================================
	// ----------------------------------------------------------- USE DATA- ----------------------------------------------------
	// ==========================================================================================================================
	private useData = <TRequest, TResponse, TEndpoint extends WSysEndpoint<TRequest, TResponse>>(ep: WSysEndpoint<TRequest, TResponse>, req: TRequest, callProps?: IWSysEndpointClientProps) => {

		const [retryTrigger, setRetryTrigger] = useState(0);
		const onInvalidate = useCallback((invalidTags: string[]) => {
			if (!ep.providesTags)
				return;
			log.debug('**', ep.path, invalidTags)();
			const providedTags = ep.providesTags(req);

			let match = false;
			for (let invalidTag of invalidTags) {
				const iarr = invalidTag.split('/').filter(f => f);
				for (let providedTag of providedTags) {
					const parr = providedTag.split('/').filter(f => f);
					log.debug('----- check', parr, ' by ', iarr)();
					let match0 = true;
					for (let px = 0; px < parr.length; px++) {
						if (px >= iarr.length) {
							log.debug(px, ' >= ', iarr.length)();
							match0 = false;
							break;
						}
						if (parr[px] !== iarr[px]) {
							log.debug(parr[px], ' !== ', iarr[px], ' at ', px)();
							match0 = false;
							break;
						}
					}
					if (match0) {
						log.info('INVALIDATED:', providedTag, ' BY ', invalidTag, ' IN EP: ', fullPath)();
						match = true;
						break;
					}
				}
				if (match)
					break;
			}

			if (match)
				setRetryTrigger(retryTrigger + 1);

		}, [retryTrigger, setRetryTrigger]);
		useEffect(() => {
			if (ep.providesTags) {
				this.invalidateSubscribers.add(onInvalidate);
				return () => {
					this.invalidateSubscribers.delete(onInvalidate);
				}
			}
		}, [onInvalidate])

		let fullPath = ep.path;
		const arr = fullPath.split('/').filter(x => !!x);
		ep.foreachReqParams(param => {
			const ix = arr.indexOf(':' + param.name);
			if (ix < 0)
				throw `Endpoint parameter not found in path. param: '${param.name}' in path '${ep.path}'`;
			const value = req[param.name as keyof TRequest];
			arr[ix] = encodeURIComponent('' + value);
		});
		fullPath = arr.join('/');
		fullPath = this.API_BASE + (fullPath.startsWith('/') ? '' : '/') + fullPath;

		let first = true;
		ep.foreachReqQuery(query => {
			const value = req[query.name as keyof TRequest];
			fullPath += (first ? '?' : '&') + encodeURIComponent(query.name + '') + '=' + encodeURIComponent(value + '');
			first = false;
		})

		const [data, setData] = useState<TResponse>();

		const ownHub = useWSysHub();
		const hub = callProps?.hub || ownHub;

		const lastRef = useRef({ fullPath: '', req: '', retryTrigger: 0 });


		useEffect(() => {


			log.info('USE DATA:', fullPath, 'skip:', !!callProps?.skip)();

			if (callProps?.skip)
				return;


			(async () => {
				hub.reset();
				const loading = hub.setLoading();

				try {
					const init = Object.assign({}, callProps?.init || {});
					init.headers ||= {};
					this.prepare(init.headers);
					if (!(init.headers as any)['Content-Type'])
						(init.headers as any)['Content-Type'] = `application/json`;
					init.method = ep.method;
					const payload = ep.getRequestPayload(req);
					if (payload)
						init.body = JSON.stringify(payload);


					const response = await fetch(fullPath, init);
					let resp: any = {};
					let jsonError: any;
					try {
						resp = await response.json();
					} catch (ex) {
						jsonError = ex;
					}
					if (response.ok) {
						if (jsonError)
							throw jsonError;
					} else {
						if (resp?.publicMessage)
							throw response.status + ' | ' + resp.publicMessage;
						if (resp?.error) {
							throw response.status + ' | ' + resp.error;
						} else {
							throw response.status + ' | ' + response.statusText;
						}
					}

					setData(resp);

					lastRef.current.fullPath = fullPath;
					lastRef.current.req = JSON.stringify(req);
					lastRef.current.retryTrigger = retryTrigger;
				} catch (ex) {

					hub.addEvent({
						message: ex + '', severity: 'error', actions: [{
							caption: 'Újra', callback: () => {
								setRetryTrigger(retryTrigger + 1);
							}
						}]
					});

				} finally {
					loading.finished();
				}
			})();




		}, [fullPath, JSON.stringify(req), retryTrigger])

		return [data, hub];
	}

	// ==========================================================================================================================
	// --------------------------------------------------------- USE ACTION ---------------------------------------------------
	// ==========================================================================================================================
	private useAction = async <TRequest, TResponse, TEndpoint extends WSysEndpoint<TRequest, TResponse>>(ep: WSysEndpoint<TRequest, TResponse>, req: TRequest, callProps?: IWSysEndpointClientProps) => {
		let fullPath = ep.path;
		try {
			const arr = fullPath.split('/').filter(x => !!x);
			ep.foreachReqParams(param => {
				const ix = arr.indexOf(':' + param.name);
				if (ix < 0)
					throw `Endpoint parameter not found in path. param: '${param.name}' in path '${ep.path}'`;
				const value = req[param.name as keyof TRequest];
				arr[ix] = encodeURIComponent('' + value);
			});
			fullPath = arr.join('/');
			fullPath = this.API_BASE + (fullPath.startsWith('/') ? '' : '/') + fullPath;

			let first = true;
			ep.foreachReqQuery(query => {
				const value = req[query.name as keyof TRequest];
				fullPath += (first ? '?' : '&') + encodeURIComponent(query.name + '') + '=' + encodeURIComponent(value + '');
				first = false;
			})

			log.info('USE ACTION:', fullPath)();
		} catch (ex) {
			console.error('USE ACTION HIBBBA:', ex, req);
			throw ex;
		}

		const init = Object.assign({}, callProps?.init || {});
		init.headers ||= {};
		this.prepare(init.headers);
		if (!(init.headers as any)['Content-Type'])
			(init.headers as any)['Content-Type'] = `application/json`;
		init.method = ep.method;

		const payload = ep.getRequestPayload(req);
		if (payload)
			init.body = JSON.stringify(payload);


		const response = await fetch(fullPath, init);
		let resp: any = {};
		let jsonError: any;
		try {
			resp = await response.json();
		} catch (ex) {
			jsonError = ex;
		}

		if (response.ok) {
			if (jsonError)
				throw jsonError;
		} else {
			if (resp?.publicMessage)
				throw response.status + ' | ' + resp.publicMessage;
			if (resp?.error) {
				throw response.status + ' | ' + resp.error;
			} else {
				throw response.status + ' | ' + response.statusText;
			}
		}

		if (ep.invalidatesTags) {
			const tags = ep.invalidatesTags(req);
			this.invalidate(tags);
		}
		return resp;

	}

}

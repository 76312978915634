import { useWSysPage, useWSysPath } from "@coimbra-its/websys-ux-core";
import { SunMainPage } from "../components/Sun";
import { useSunmoneyNetApi } from "../store/sunmoney.net.api";
import { Box } from "@chakra-ui/react";



export function Article() {

    const path = useWSysPath();
    const articlePath = path.useChild(':slug');

    const api = useSunmoneyNetApi();
    const [data, hub] = api.articleSlugGet.useData({slug: articlePath.slug});
    

    const page = useWSysPage({ title: data?.article.title || 'Article', maxCols: 3 });


    return <SunMainPage page={page}>
        <Box className='article' dangerouslySetInnerHTML={{__html: data?.article.content}} ></Box>
    </SunMainPage>
}
import { ButtonGroup } from "@chakra-ui/react";
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";

import { ClassValidator, IDetailToPost, ValidationResult2 } from "@coimbra-its/websys-lib";
import { useWSysCoreSlice } from "../../store/coreSlice";
import { WSysLayoutContext, WSysPageNode } from "../LayoutManager";
import { WSysHeaderButton } from "../Page";
import { ICONS } from "../theme";




export interface IFormTools {
	tools: (column: WSysPageNode) => false | ReactElement;
	toolTokens: string[];
}

let LAST_FORM_KEY = 0;


// -------------------------------------------------------------------------------------------------


// a WebSysForm egy ilyen listát használ (items[]) a useRow() és useSubRow() hívások regisztrálásához
export interface IWebSysFormPart<TResponse, TEntity> {
	appendLoaded: (resp: TResponse) => void;
	appendNew: () => void;
}

// -------  a useRow ilyennel tér vissza, a useSubRow ebből egy tömböt csinál -------
export interface IWebSysFormRow$<TEntity> {
	meta: ClassValidator<TEntity>;
	setData: (changes: Partial<TEntity>) => void;
	formRow: IWebSysFormRow<TEntity>;
}

/*export interface IWebSysFormSubRow$<TEntity> extends IWebSysFormRow$<TEntity> {
	formRow : IWebSysFormRow<TEntity>;
}*/

export interface IWebSysFormRow<TEntity> {
	formRow$: IWebSysFormRow$<TEntity>;
	data: TEntity;
	validationResult: ValidationResult2<TEntity>;
	isEdited: boolean;
}

// a useSubRow() minden sorhoz egy ilyen elemet rendel:
export interface IWebSysFormSubRow<TEntity> extends IWebSysFormRow<TEntity> {
	formRow$: WebSysFormSubRow$<TEntity>;
}


export class WebSysFormSubRow$<TEntity> implements IWebSysFormRow$<TEntity> {
	public formRow: IWebSysFormSubRow<TEntity>;
	public isRemoved: boolean = false;
	constructor(
		private appendRowChanges: (formRow$: WebSysFormSubRow$<TEntity>) => void,
		public readonly KEY: string,
		public meta: ClassValidator<TEntity>,
		data: TEntity,
		validationResult: ValidationResult2<TEntity>,
		isEdited: boolean,
	) {
		this.formRow = { formRow$: this, data, validationResult, isEdited }
	}

	public setData(changes: Partial<TEntity>) {
		let newData = { ...this.formRow.data, ...changes };
		this.formRow = { ... this.formRow, data: newData, validationResult: this.meta.Validate(newData) }
		this.appendRowChanges(this);
	}

	public remove() {
		this.isRemoved = true;
		this.appendRowChanges(this);
	}

	public setIsEdited(isEdited: boolean) {
		this.formRow = { ...this.formRow, isEdited }
	}
}

export interface useWebSysFormProps<TResponse> {
	isCreate: boolean,
	data?: any,
	onEdit?: () => void,

}


// --------- return type of useSubRows() --------------
export interface SysFormRowsRef<TEntity> {
	meta: ClassValidator<TEntity>;
	rows: IWebSysFormSubRow<TEntity>[];
	selectedRow: IWebSysFormSubRow<TEntity> | null;
	setSelectedRow$: (selectedRow$: WebSysFormSubRow$<TEntity> | null) => void;
	createRow: () => void;
	next: () => void;
	prev: () => void;
	first: () => void;
	forSave: () => (TEntity & IDetailToPost)[];
	recalc: () => void;
}


// ===================================================================================== FORM ======================================
export function useWebSysForm<TResponse>({ isCreate, data, onEdit }: useWebSysFormProps<TResponse>) {

	const dump = useWSysCoreSlice(state => state.layout.dumpFormTokens); //TODO


	// nem kell setParts() mert egyszer a useRow és useSubRow feltölti és többé nem változhat.
	const [parts] = useState<Array<IWebSysFormPart<TResponse, any>>>([]);
	const [isEdited, _setIsEdited] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	// az utolsó szerverről betöltött feladat (lehet a load utáni original, vagy a post után visszakapott)
	const [remoteData, setRemoteData] = useState<TResponse | undefined>();

	useEffect(() => {
		if (isCreate) {
			setIsEdited(true);
			for (let part of parts) {
				part.appendNew();
			}
			setRemoteData(undefined);
			setIsLoaded(true);
		}
	}, [isCreate]);

	useEffect(() => {
		if (data) {
			appendLoaded(data);
			/*if (unsaved.length>0)
				console.log('-----------> betöltünk...', , JSON.stringify(loadResult?.currentData))*/
		}
	}, [data]);

	// oldalbetöltődés vagy post után:
	const appendLoaded = (serverResp: TResponse) => {
		for (let part of parts) {
			part.appendLoaded(serverResp)
		}
		setRemoteData(serverResp)
		setIsLoaded(true);
	}

	const cancelEdit = () => {
		setIsEdited(false);
		if (remoteData) {
			window.requestAnimationFrame(() => { // wait autoselect to become readonly and accept WAY2 change
				appendLoaded(remoteData);
			});
		}
	}

	const afterSave = (serverResp: TResponse) => {
		appendLoaded(serverResp);
		setIsEdited(false);
	}

	// ------------------ saving dirty -----------------
	//const dispatch = useDispatch();
	//const loc = useLocation();
	useEffect(() => {
		if (isEdited) {
			window.onbeforeunload = () => "Biztosan??";
			//dispatch(unsavedAdd(window.location.href));
			return () => {
				window.onbeforeunload = () => undefined;
			}
		} else {
			window.onbeforeunload = () => undefined;
			//dispatch(unsavedRemove(window.location.href));
		}
	}, [isEdited]);
	//const unsaved = useSelector((state:IRootStateCore) => state.system.unsavedforms);

	const setIsEdited = (v: boolean) => {
		if (v && onEdit)
			onEdit();
		_setIsEdited(v);
	}



	// ------------------------------------------------------------------------------ useRow ----------------------------------------------
	function useRow<TEntity>(props: {
		extract: (resp: TResponse) => TEntity,
		meta: ClassValidator<TEntity>,
		onCreate?: () => TEntity,
		onChange?: (row: TEntity) => void
	}) /*: IWebSysFormRow$<TEntity>*/ {
		//const [data, __setData] = useState<TEntity>(meta.EmptyObject());
		//const [validationResult, setValidationResult] = useState<ValidationResult2<TEntity>>(meta.EmptyResult());
		const [formRow$] = useState<IWebSysFormRow$<TEntity>>({
			formRow: {
				data: props.meta.EmptyObject(),
				validationResult: props.meta.EmptyResult(),
				formRow$: undefined as any as IWebSysFormRow$<TEntity>,
				isEdited
			} as IWebSysFormRow<TEntity>,
			meta: props.meta,
			setData: (changes: Partial<TEntity>) => {
				//console.log('form.setData');
				let newData = { ...formRow$.formRow.data, ...changes };
				if (props.onChange)
					props.onChange(newData);
				let validationResult = props.meta.Validate(newData);
				if (dump) console.log('VALIDATION', newData, validationResult);
				formRow$.formRow = {
					data: newData,
					validationResult,
					formRow$,
					isEdited: formRow$.formRow.isEdited
				};
				__setFormRow(formRow$.formRow);
			}
		});
		formRow$.formRow.formRow$ = formRow$;//hát...
		const [formRow, __setFormRow] = useState<IWebSysFormRow<TEntity>>(formRow$.formRow);

		useEffect(() => {
			formRow$.formRow = { ...formRow$.formRow, isEdited };
			__setFormRow(formRow$.formRow);
		}, [isEdited]);

		const appendLoaded = (serverResp: TResponse) => {
			const thisRow = props.extract(serverResp);
			if (thisRow) {
				let _validatorResult = props.meta.Validate(thisRow);
				let _data = _validatorResult.normalized;
				formRow$.setData(_data);
			} else {
				formRow$.setData(props.onCreate ? props.onCreate() : null as any);
			}
		}

		const appendNew = () => {
			if (!props.onChange)
				throw new Error(`Form shoud have 'onCreate()'`);
			formRow$.setData(props.onCreate!());
		}

		const [part] = useState<IWebSysFormPart<TResponse, any>>({
			appendLoaded, appendNew
		});
		useEffect(() => {
			//console.log('---ue--->', part);
			if (parts.indexOf(part) === -1)
				parts.push(part);
		}, []);
		return formRow;
	}

	// ---------------------------------------------------------------------------------- useSubRow ----------------------------------------------
	function useSubRow<TEntity>(props: {
		extract: (resp: TResponse) => TEntity[],
		meta: ClassValidator<TEntity>,
		onCreate?: () => TEntity,
		onChange?: (row: TEntity, rows: IWebSysFormSubRow<TEntity>[]) => void
		onSummary?: (rows: IWebSysFormSubRow<TEntity>[]) => void
	}): SysFormRowsRef<TEntity> {
		const [rows, __setRows] = useState<Array<IWebSysFormSubRow<TEntity>>>([]);
		const [selectedRow, __setSelectedRow] = useState<IWebSysFormSubRow<TEntity> | null>(null);
		const [formPart$] = useState<{ rows$: Array<WebSysFormSubRow$<TEntity>>, CLIENT_ID: number, selectedRow$: WebSysFormSubRow$<TEntity> | null }>({
			rows$: [],
			CLIENT_ID: 0,
			selectedRow$: null
		});

		const appendRowChanges = (changedRow$?: WebSysFormSubRow$<TEntity>) => {
			const newRows = formPart$.rows$.filter(row$ => !row$.isRemoved).map(row$ => row$.formRow);
			if (props.onChange && changedRow$)
				props.onChange(changedRow$.formRow.data, newRows);
			if (props.onSummary)
				props.onSummary(newRows);
			__setRows(newRows);

			if (formPart$.selectedRow$) {
				if (formPart$.selectedRow$.isRemoved) {
					next();
				} else {
					if (formPart$.rows$.indexOf(formPart$.selectedRow$) < 0)
						formPart$.selectedRow$ = formPart$.rows$.filter(r => !r.isRemoved)[0] || null;
					setSelectedRow$(formPart$.selectedRow$);
				}
			}
		}

		const setSelectedRow$ = (selectedRow$: WebSysFormSubRow$<TEntity> | null) => {
			formPart$.selectedRow$ = selectedRow$;
			__setSelectedRow(selectedRow$?.formRow || null);
		}

		const createNewRow$ = () => {
			if (!props.onCreate) throw 'No onCreate declared on form.subRow()';
			let data = props.onCreate();
			let validationResult = props.meta.Validate(data);
			return new WebSysFormSubRow$(appendRowChanges, 'CLI_' + (++formPart$.CLIENT_ID), props.meta, data, validationResult, isEdited);
		}

		// ---- register formPart in parent form ----
		const [part] = useState<IWebSysFormPart<TResponse, any>>({
			appendLoaded: (serverResp: TResponse) => {
				const loadedRows = props.extract(serverResp);
				formPart$.rows$ = [];
				for (let loadedRow of loadedRows) {
					let validationResult = props.meta.Validate(loadedRow);
					let formRow$ = new WebSysFormSubRow$(appendRowChanges, props.meta.GetRowServerKey(loadedRow), props.meta, validationResult.normalized, validationResult, isEdited);
					formPart$.rows$.push(formRow$);
				}
				appendRowChanges();
			},
			appendNew: () => {
				let newRow$ = createNewRow$();
				formPart$.rows$ = [newRow$]
				appendRowChanges();
				setSelectedRow$(newRow$);
			}
		});
		useEffect(() => {
			if (parts.indexOf(part) === -1) //ez csak amiatt kell, mert dev-módban többször meghívódik a useEffect, amúgy egyszer hozzáadná és kész.
				parts.push(part);
		}, []);

		useEffect(() => {
			//console.log('isEdited effect', rows.length);
			for (let row$ of formPart$.rows$)
				row$.setIsEdited(isEdited);
			appendRowChanges();
		}, [isEdited]);

		const createRow = () => {
			let newRow$ = createNewRow$();
			formPart$.rows$.push(newRow$);
			appendRowChanges();
			setSelectedRow$(newRow$);
		}

		const next = () => {
			console.log('NEXT')
			let newSelectedRow$ = formPart$.selectedRow$;
			let oldIx = formPart$.rows$.findIndex(row$ => row$ === formPart$.selectedRow$);
			if (oldIx >= 0)
				newSelectedRow$ = formPart$.rows$.slice(oldIx + 1).filter(row$ => !row$.isRemoved)[0] || null;
			if (!newSelectedRow$)
				newSelectedRow$ = formPart$.rows$.filter(row$ => !row$.isRemoved)[0] || null;
			setSelectedRow$(newSelectedRow$);
		}

		const prev = () => {
			let newSelectedRow$ = formPart$.selectedRow$;
			let oldIx = formPart$.rows$.findIndex(row$ => row$ === formPart$.selectedRow$);
			if (oldIx >= 0)
				newSelectedRow$ = formPart$.rows$.slice(oldIx + 1).filter(row$ => !row$.isRemoved)[0] || null;
			if (!newSelectedRow$)
				newSelectedRow$ = formPart$.rows$.filter(row$ => !row$.isRemoved)[0] || null;
			setSelectedRow$(newSelectedRow$);
		}

		const first = () => {
			let rows$ = formPart$.rows$.filter(row$ => !row$.isRemoved);
			if (rows$.length > 0)
				setSelectedRow$(rows$[0]);
		}

		const forSave = () => { // TODO: nem kell, ami isRemoved és most csináltuk kliens oldalon
			return formPart$.rows$
				.filter(row$ => row$.KEY.startsWith('SRV__') || !row$.isRemoved)
				.map(row$ => ({ ...row$.formRow.data, __KEY: row$.KEY, __DELETE: row$.isRemoved }));
		}

		const recalc = () => {
			if (props.onChange) {
				//console.log('... recalc');
				let rows$ = formPart$.rows$.filter(row$ => !row$.isRemoved);
				for (let row$ of rows$) {
					let newData = { ...row$.formRow.data };
					props.onChange(newData, rows);
					row$.formRow = {
						...row$.formRow,
						data: newData,
						validationResult: row$.meta.Validate(newData)
					}
				}
			}
			appendRowChanges(); // calls onSummary
		}

		return { rows, selectedRow, setSelectedRow$, createRow, next, prev, first, forSave, recalc, meta: props.meta };
	}



	// ------------------------------------------------------------------------------ useTools ----------------------------------------------
	function useTools(onSave?: () => void, onCancel?: ()=> void) {
		const layoutCtx = React.useContext(WSysLayoutContext);
		const [formKey] = useState<string>(() => 'FORM#' + (++LAST_FORM_KEY));
		const [toolTokens] = useState<string[]>(() => [formKey]);

		const tools = React.useCallback((page: WSysPageNode) =>
			isEdited
				?
				((page.propsOut.showToolTokens || '').indexOf(`${formKey}_MAIN`) >= 0
					? (<ButtonGroup>
						<WSysHeaderButton onClick={() => {cancelEdit(); if (onCancel) onCancel(); }} icon={<ICONS.Cancel />} >Mégsem</WSysHeaderButton>
						<WSysHeaderButton onClick={() => onSave && onSave()} colorScheme='orange' icon={<ICONS.Save />} >Mentés</WSysHeaderButton>
					</ButtonGroup>)
					: ((page.propsOut.showToolTokens || '').indexOf(`${formKey}_ATTACH`) >= 0 && <WSysHeaderButton onClick={() => layoutCtx.page?.close()} colorScheme='orange' icon={<ICONS.Back />}  >Kész</WSysHeaderButton>)
				)
				:
				(
					(page.propsOut.showToolTokens || '').indexOf(formKey) >= 0 && <WSysHeaderButton onClick={() => setIsEdited(true)} colorScheme='primary' icon={<ICONS.Edit />} >Módosít</WSysHeaderButton>
				)
			, [isEdited, formKey, onSave]);

		/*const [formTools, setFormTools] = useState({
			tools, toolTokens: [formKey]
		});

		useEffect(() => {
			setFormTools({
				tools, toolTokens: [formKey]
			})
		}, [tools, formKey])*/

		return {
			isEdited, setIsEdited, tools, toolTokens, forPage : { isEdited, toolTokens }
			//formTools, tools, toolToken: formKey
		}
	}









	return {
		/*error: loadResult?.error,
		isLoading: loadResult?.isFetching || false,*/
		isLoaded,
		remoteData: remoteData,
		cancelEdit,
		useRow,
		useSubRow,
		useTools,
		isEdited, setIsEdited,
		afterSave
	};
}











// ============================================================ MOCK ===========================================
export function useWSysMockForm(onClose?: () => void, initialIsEdited: boolean = false) {
	const [formKey] = useState<string>(() => 'FORM#' + (++LAST_FORM_KEY));
	const [toolTokens] = useState<string[]>(() => [formKey]);
	const [isEdited, setIsEdited] = useState(initialIsEdited);
	//const [showToolTokens, setShowToolTokens] = useState<string>('');
	/*if (page)
		page.propsIn.toolTokens = [formKey];*/

	const tools = useCallback((page: WSysPageNode): ReactNode =>
		isEdited
			?
			((page.propsOut.showToolTokens || '').indexOf(`${formKey}_MAIN`) >= 0
				? (<ButtonGroup>
					<WSysHeaderButton onClick={() => setIsEdited(false)} icon={<ICONS.Cancel />} >Mégsem</WSysHeaderButton>
					<WSysHeaderButton onClick={() => onClose && onClose()} colorScheme='orange' icon={<ICONS.Save />} >Mentés</WSysHeaderButton>
				</ButtonGroup>)
				: ((page.propsOut.showToolTokens || '').indexOf(`${formKey}_ATTACH`) >= 0 && <WSysHeaderButton onClick={onClose} colorScheme='orange' icon={<ICONS.Back />}  >Kész</WSysHeaderButton>)
			)
			:
			(
				(page.propsOut.showToolTokens || '').indexOf(formKey) >= 0 && <WSysHeaderButton onClick={() => setIsEdited(true)} colorScheme='blue' icon={<ICONS.Edit />} >Módosít</WSysHeaderButton>
			)
		, [isEdited, formKey]);

	/*const formTools = useMemo(() => ({
		tools, isEdited, //toolTokens: [formKey]
	}), [tools, toolTokens: [formKey], isEdited]);*/


	return {
		isEdited, setIsEdited, tools, toolTokens, forPage : { isEdited, toolTokens }
	}
}

import { PublicError } from "./common";


export function WSysDateToWire(f: any): string {
	if (typeof f == 'string') {
		var d = Date.parse(f);
		if (!isNaN(d)) {
			f = new Date(f);
		}
	}

	if (f instanceof Date) {
		if (f.getFullYear() < 1960)
			return '-';
		let mm = f.getMonth() + 1; // getMonth() is zero-based
		let dd = f.getDate();

		return [f.getFullYear(), '-',
		(mm > 9 ? '' : '0') + mm, '-',
		(dd > 9 ? '' : '0') + dd,
		].join('');
	} else {
		return JSON.stringify(f);
	}
}


export function WSysDateFromWire(d?: string): Date | undefined {
	if (!d)
		return;
	return new Date(d);
}

export function WSysDateFromWireRequired(d?: string, errorName?: string): Date | undefined {
	if (!d)
		throw new PublicError(`Hiányzó dátum${errorName ? ' : ' + errorName : ''}!`);
	return new Date(d);
}

export function WSysDateFromWire_SYS_STYLE(d?: string): Date {
	if (!d)
		return new Date(1950, 0, 1);
	return new Date(d);
}


// ------------------------------------------------ DEEP MERGE -------------------------------------------
function _isObject(item : any) {
	return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target : any, ...sources : any[]) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (_isObject(target) && _isObject(source)) {
		for (const key in source) {
			if (_isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return mergeDeep(target, ...sources);
}
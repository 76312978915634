import { Box, Text } from "@chakra-ui/react";
import { useSunmoneyNetApi } from "../../store/sunmoney.net.api";
import { CellProps, formatNumber, WSysTable } from "@coimbra-its/websys-ux-core";

import { ISunBalanceItem } from "@coimbra-its/api.sunmoney.net";


export const CELLS : CellProps<ISunBalanceItem>[] = [
	{title : 'Account', elem : (row) =>  row.name , charWidth:25},
	{title : 'Balance', elem : (row) =>  <><Text as='span' fontWeight='bold'>{formatNumber(row.balance)}</Text> {row.dim}</> , charWidth:20, align:"right"},
];


export function ProfileBalances() {


    const api = useSunmoneyNetApi();
    const [data, hub] = api.balancesGet.useData({});
    const balances = data?.BALANCES || [];

    return <Box>
        <WSysTable rows={balances} cells={CELLS} ></WSysTable>
    </Box>
}
import { Box, Button, Card, CardBody, Heading, Icon, LightMode, Link, Stack, Text } from "@chakra-ui/react";
import { WSysGrid, WSysHeaderButton, useWSysLayoutContext, useWSysPage, useWSysPath } from "@coimbra-its/websys-ux-core";
import { SunMainPage } from "../components/Sun";
import { APPICONS } from "../Icons";
import { useWSysAuthSlice } from "../store/authSlice";
import QRCode from 'react-qr-code';
import { Profile } from "./profile/Profile";
import { useSunmoneyNetApi } from "../store/sunmoney.net.api";
import { IArticleLead, IMLM_ARTICLE } from "@coimbra-its/api.sunmoney.net";


export function Dashboard() {

	const api = useSunmoneyNetApi();
	const [data, hub] = api.dashboardGet.useData({});
	const articles = data?.articles || [{
		id: 0, lead: '', slug:'', title: 'loading...'
	}];


	const authSlice = useWSysAuthSlice();
	const user = useWSysAuthSlice(s => s.user);

	const path = useWSysPath();
	const page = useWSysPage({ title: 'Sunmoney.net', maxCols: 3, onClosed: false });
	const layoutCtx = useWSysLayoutContext();

	const profilePath = path.useChild('profile');
	const chartPath = path.useChild('monthly-solar-royalty');
	const shopPath = path.useChild('shop');
	const articlePath = path.useChild('article/:slug');
	const treePath = path.useChild('community/tree');

	const cardStyle = {
		_hover: {
			outline: '3px solid #fff5',
		},
		cursor: 'pointer',
		h: '100%',
		//maxW:'sm'

	}

	const articleClick = (article : IArticleLead) => {
		if (article && article.slug)
			articlePath.open('article/' + article.slug)
	}


	return <SunMainPage page={page} opaque hubs={[hub]}
		tools={layoutCtx.isMobileFull && <WSysHeaderButton icon={<APPICONS.SignOut />}
			onClick={() => { authSlice.setShowSignOut(true) }} >Kilépés</WSysHeaderButton>}
	>
		<WSysGrid cols={[1, 1]} minCols={3} justifyContent='center' gap={5} gridAutoRows='1fr' >

			{articles.map(article =>
				<Card key={article.id} variant='outline' {...cardStyle} onClick={() => articleClick(article)} cursor='pointer'>
					<CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center' gap={3}  >
						<Icon as={APPICONS.News} boxSize={10} />
						<Heading size='md'>{article.title}</Heading>
						{<Box dangerouslySetInnerHTML={{ __html: article.lead }}></Box>}
					</CardBody>
				</Card>)}



			<Card bg='#312546' color='#fff' {...cardStyle} onClick={() => profilePath.open()} cursor='pointer'>
				<CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center' gap={3}  >
					<Icon as={APPICONS.User} boxSize={10} />
					<Heading size='md'>PERSONAL</Heading>
					<Text>Info</Text>
					<Box display='flex' flexDir='column' alignItems='center'>
						<Box>
							<Text as='span'>Name:&nbsp;</Text><Text as='span' fontWeight='bold'>{user?.name}</Text>
						</Box><Box>
							<Text as='span'>ID Number:&nbsp;</Text><Text as='span' fontWeight='bold'>{user?.id_number}</Text>
						</Box>
					</Box>
				</CardBody>
			</Card>

			<Card bg='#a7452a' color='#fff' {...cardStyle} onClick={() => shopPath.open()} cursor='pointer'>
				<CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center' gap={3}  >
					<LightMode>
						<Icon as={APPICONS.Shop} boxSize={10} />
						<Heading size='md'>SHOP</Heading>
					</LightMode>
				</CardBody>
			</Card>

			{data?.sponsorLink && <Card variant='outline' {...cardStyle}>
				<Link href={data.sponsorLink} target='_blank'>
					<CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center'>

						<QRCode value={data?.sponsorLink} />
						your sponsre link
					</CardBody>
				</Link>

			</Card>}


			<Card variant='outline' {...cardStyle} onClick={() => chartPath.open()} cursor='pointer'>
				<CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center' gap={3}  >
					<Icon as={APPICONS.Chart} boxSize={10} />
					<Heading size='md'>Monthly Solar Royalty</Heading>
				</CardBody>
			</Card>

			<Card bg='#312546' color='#fff' {...cardStyle} onClick={() => treePath.open()} cursor='pointer'>
				<CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center' gap={3}  >
					<Icon as={APPICONS.Tree} boxSize={10} />
					<Heading size='md'>Your community</Heading>
					<Box display='flex' flexDir='column' alignItems='center'>
						<Box>
							<Text as='span' fontWeight='bold'>134</Text><Text as='span'>&nbsp;People</Text>
						</Box><Box>
							<Text as='span' fontWeight='bold'>243.2</Text><Text as='span'>&nbsp;KiloWatts</Text>
						</Box><Box>
							<Text as='span' fontWeight='bold'>125 457</Text><Text as='span'>&nbsp;CV</Text>
						</Box>
					</Box>
				</CardBody>
			</Card>

		</WSysGrid>
		{profilePath.on(<Profile />)}
	</SunMainPage>
}

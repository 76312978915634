import { IPageTabItem, useWSysHub, useWSysPage, useWSysPath, WSysTabHead } from "@coimbra-its/websys-ux-core";
import { SunMainPage } from "../../components/Sun";
import { ProfileDocuments } from "./ProfileDocuments";
import { ProfileForm, useProfileForm } from "./ProfileForm";
import { ProfilePassword } from "./ProfilePassword";
import { DarkMode } from "@chakra-ui/react";
import { ProfileBalances } from "./ProfileBalances";



export function Profile() {


	const path = useWSysPath();
	const pathSub = path.useChild(':tab');
	const hub = useWSysHub();

	if (!pathSub.slug)
		pathSub.open('form');

	// ---- page ----
	const isForm = pathSub.slug==='form' || !pathSub.slug;
	const {formRef, formTools, fej} = useProfileForm(isForm, hub);


	const page = useWSysPage({ title: 'Profile', maxCols: 3, ...formTools.forPage })
	const tabs: IPageTabItem[] = [
		{ panelKey: 'form', title: 'Profile' },
		{ panelKey: 'password', title: 'Password' },
		{ panelKey: 'balances', title: 'Accounts' },
		{ panelKey: 'documents', title: 'Documents' },
	]

	const tabClick = (key: string) => {
		hub.reset();
		pathSub.open(key);
	}


	return <SunMainPage
		page={page}
		hubs={[hub]}
		headerTitle={<DarkMode><WSysTabHead tabs={tabs} onTabClick={tabClick} selectedKey={pathSub.slug} /></DarkMode>}
		tools={isForm && formTools.tools(page)}
	>
		{pathSub.on(<>
			{pathSub.slug === 'form' && <ProfileForm fej={fej} isEdited={formRef.isEdited} />}
			{pathSub.slug === 'password' && <ProfilePassword />}
			{pathSub.slug === 'balances' && <ProfileBalances />}
			{pathSub.slug === 'documents' && <ProfileDocuments />}
		</>)}




	</SunMainPage>
}


import { Box, BoxProps, FormControl, FormControlProps, FormLabel, Icon, Input, InputGroup, InputLeftAddon, InputLeftElement, InputProps, Switch, SwitchProps, Textarea, TextareaProps } from "@chakra-ui/react";
import { Children, cloneElement, createContext, isValidElement, useContext, useEffect, useState } from "react";
import { IconType } from "react-icons";
import { formatDateForInput, formatDateTimeForInput, useDebounce } from "../utils";
import { IWebSysFormRow } from "./Form";


const INPUT_DEBOUNCE_MS = 500;




// ========================================================== form context ==========================================================
export interface WSysFormFieldProps<TEntity> {
	formRow?: IWebSysFormRow<TEntity>;
	field: keyof TEntity & string;
}

export const SysFormRowContext = createContext<IWebSysFormRow<any> | null>(null);



// ========================================================== box ==========================================================

export interface WSysFieldProps<TEntity> extends FormControlProps, WSysFormFieldProps<TEntity> {
	noTitle?: boolean;
	icon?: IconType;
}

export function WSysField<TEntity>({ formRow: pFormRow, field, children: pChildren, noTitle, icon, ...rest }: WSysFieldProps<TEntity>) {
	const formRowCtx = useContext(SysFormRowContext) as IWebSysFormRow<TEntity>;
	const formRow = pFormRow || formRowCtx;
	if (!formRow || !field) throw `SysField : no formRow or field ${field}`;

	const metaColumn = formRow.formRow$ ? formRow.formRow$.meta.Column(field) : undefined;
	const caption = (metaColumn?.caption || field || '?');

	const errors = formRow.validationResult ? formRow.validationResult.f(field) || [] : [];
	const isError = errors.length > 0;
	const helperText = isError ? errors.join("; ") : '';

	let children = pChildren;

	const childrenArr = Children.toArray(pChildren);
	if (childrenArr.length === 1 && isValidElement(childrenArr[0])) {
		let child = childrenArr[0];
		if ((!child.props.field || !child.props.formRow)
			//&& (child.type === SysInput || child.type === SysAutocomplete || child.type === SysInputDate || child.type === SysTextarea
			&& (child.type as any).__sysInput
		) {
			children = cloneElement(child, {
				...child.props
				, formRow: child.props.formRow || formRow
				, field: child.props.field || field
				, isInvalid: isError
			});
		}
	}

	return <FormControl {...rest}>
		{noTitle !== false && <FormLabel>{caption}</FormLabel>}
		<InputGroup>
			{icon && <InputLeftAddon><Icon as={icon} /></InputLeftAddon>}
			{children}
		</InputGroup>
		{isError && <Box color='#f00d' fontSize='13px'>{helperText}</Box>}
	</FormControl>
}



// ========================================================== INPUT (string) ==========================================================
export interface WSysInputBaseProps<TEntity, TValue> extends Partial<WSysFormFieldProps<TEntity>> {
	readOnly?: boolean;
	onDebouncedChange?: (value: TValue) => void;
}


export interface WSysInputProps<TEntity, TValue> extends Omit<InputProps, 'value'>, WSysInputBaseProps<TEntity, TValue> {
}


export function WSysInput<TEntity>({ formRow, field, readOnly: pReadOnly = false, onChange: pOnChange, onDebouncedChange, ...rest }: WSysInputProps<TEntity, string>) {
	if (!formRow || !field) throw `formRow or field missing from Input`;
	const readOnly = pReadOnly || !formRow.isEdited;
	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<string | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, INPUT_DEBOUNCE_MS);

	useEffect(() => {
		if (readOnly || changedValue === undefined)
			setInputValue(formRow.data[field] as any || '');
		else
			setInputValue(changedValue);
		if (readOnly)
			setChangedValue(undefined);
	}, [readOnly, changedValue, formRow.data[field]]);

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (pOnChange) pOnChange(e);
		const val = e.currentTarget.value;
		setChangedValue(val);
		setInputValue(val);
	}
	useEffect(() => {
		if (!readOnly && debouncedChangedValue !== undefined) {
			if (onDebouncedChange)
				onDebouncedChange(debouncedChangedValue);
			formRow.formRow$.setData({ [field]: debouncedChangedValue } as any);
			setChangedValue(undefined);
		}
	}, [readOnly, debouncedChangedValue]);

	return <Input readOnly={readOnly} value={inputValue} onChange={onInputChange}
		onFocus={e => e.currentTarget.select()}
		{...rest} />
}
WSysInput.__sysInput = true;



// ========================================================== INPUT (Date / DateTime) ==========================================================
export interface WSysInputDateProps<TEntity> extends WSysInputProps<TEntity, Date> {
	isDateTime?: boolean;
}


export function WSysInputDate<TEntity>({ isDateTime = false, formRow, field, readOnly: pReadOnly = false, onChange: pOnChange, onDebouncedChange, ...rest }: WSysInputDateProps<TEntity>) {
	if (!formRow || !field) throw `formRow or field missing from Input`;
	const readOnly = pReadOnly || !formRow.isEdited;
	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<Date | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, INPUT_DEBOUNCE_MS);

	useEffect(() => {
		if (readOnly || changedValue === undefined)
			setInputValue(isDateTime
				? formatDateTimeForInput(formRow.data[field] as Date)
				: formatDateForInput(formRow.data[field] as Date)
			);
		if (readOnly)
			setChangedValue(undefined);
	}, [readOnly, changedValue, formRow.data[field]]);

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (pOnChange) pOnChange(e);
		const val = e.currentTarget.value;
		setInputValue(val);
		if (!isNaN(Date.parse(val))) {
			let dVal = new Date(val) as Date;
			setChangedValue(dVal);
		}
	}

	useEffect(() => {
		if (!readOnly && debouncedChangedValue !== undefined) {
			if (onDebouncedChange)
				onDebouncedChange(debouncedChangedValue);
			formRow.formRow$.setData({ [field]: debouncedChangedValue } as any);
			setChangedValue(undefined);
		}
	}, [readOnly, debouncedChangedValue]);

	return <Input
		type={isDateTime ? 'datetime-local' : 'date'}
		readOnly={readOnly} value={inputValue} onChange={onInputChange}
		onFocus={e => e.currentTarget.select()}
		{...rest} />
}
WSysInputDate.__sysInput = true;


// ========================================================== INPUT (textarea) ==========================================================
export interface WSysTextareaProps<TEntity> extends TextareaProps, WSysInputBaseProps<TEntity, string> { }



export function SysTextarea<TEntity>({ formRow, field, readOnly: pReadOnly = false, onChange: pOnChange, onDebouncedChange, ...rest }: WSysTextareaProps<TEntity>) {
	if (!formRow || !field) throw `formRow or field missing from Input`;
	const readOnly = pReadOnly || !formRow.isEdited;
	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<string | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, INPUT_DEBOUNCE_MS);

	useEffect(() => {
		if (readOnly || changedValue === undefined)
			setInputValue(formRow.data[field] as any || '');
		else
			setInputValue(changedValue);
		if (readOnly)
			setChangedValue(undefined);
	}, [readOnly, changedValue, formRow.data[field]]);

	const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (pOnChange) pOnChange(e);
		const val = e.currentTarget.value;
		setChangedValue(val);
		setInputValue(val);
	}
	useEffect(() => {
		if (!readOnly && debouncedChangedValue !== undefined) {
			if (onDebouncedChange)
				onDebouncedChange(debouncedChangedValue);
			formRow.formRow$.setData({ [field]: debouncedChangedValue } as any);
			setChangedValue(undefined);
		}
	}, [readOnly, debouncedChangedValue]);

	return <Textarea readOnly={readOnly} value={inputValue} onChange={onInputChange}

		{...rest} />
}
SysTextarea.__sysInput = true;

// ======================================================== SWITCH ====================================================
export interface WSysSwitchProps<TEntity> extends SwitchProps, WSysInputBaseProps<TEntity, string> {
	trueValue?: string;
	falseValue?: string;
	trueCaption?: string;
	falseCaption?: string;
}

export function WSysSwitch<TEntity>({
	trueValue = 'I', falseValue = 'N', trueCaption = 'Igen', falseCaption = 'Nem'
	, formRow, field, readOnly: pReadOnly, onChange
	, ...rest // pass to input
}: WSysSwitchProps<TEntity>) {
	if (!formRow || !field) throw `formRow or field missing from Switch`;
	const readOnly = pReadOnly || !formRow.isEdited;
	const inputValue = formRow.data[field] === trueValue;
	const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.currentTarget.checked ? trueValue : falseValue;
		formRow.formRow$.setData({ [field]: val } as any);
	}

	return <Box><Switch
		readOnly={readOnly}
		isChecked={inputValue}
		onChange={inputValueChanged}
		{...formRow.isEdited && !readOnly && { bg: '#ffffe5' }}
		{...rest}
	/>
		<Box as='span' ml={2} fontSize='sm' >{inputValue ? trueCaption : falseCaption}</Box>
	</Box>
}
WSysSwitch.__sysInput = true;


// ======================================================== AMOUNT ====================================================
export interface WSysNumberProps<TEntity> extends InputProps, WSysInputBaseProps<TEntity, string> {
}

export function SysNumber<TEntity>({ formRow, field, readOnly: pReadOnly = false, onChange: pOnChange, onDebouncedChange
	, ...rest
}: WSysNumberProps<TEntity>) {
	if (!formRow || !field) throw `formRow or field missing from Input`;
	const readOnly = pReadOnly || !formRow.isEdited;
	const metaColumn = field ? formRow.formRow$.meta.Column(field) : undefined;

	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<number | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, INPUT_DEBOUNCE_MS);

	const [isFocused, setIsFocused] = useState(false);

	const addThousandSeparator = (num: any) => (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

	const _setInputValue = () => {
		if (readOnly || changedValue === undefined) {
			setInputValue('' + (isFocused ? formRow.data[field] : addThousandSeparator(formRow.data[field])));
		}
		if (readOnly)
			setChangedValue(undefined);
	}

	useEffect(() => {
		_setInputValue();
	}, [readOnly, changedValue, field ? formRow.data[field] : null, isFocused]);

	const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		let val = e.currentTarget.value;
		console.log('setChanged', val);
		setChangedValue(parseFloat(val));
		setInputValue(val);
	}

	const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(true);
		_setInputValue();
		let t = e.currentTarget;
		setTimeout(() => {
			t.select();
		}, 100);
	}

	useEffect(() => {
		if (!field) return;
		if (!readOnly && debouncedChangedValue !== undefined) {
			if (isNaN(debouncedChangedValue)) {
				formRow.formRow$.setData({ [field]: null } as any);
			} else {
				formRow.formRow$.setData({ [field]: debouncedChangedValue } as any);
			}
			setChangedValue(undefined);
		}
	}, [field, readOnly, debouncedChangedValue]);

	return <Input
		type={isFocused && !readOnly ? "number" : "text"} textAlign='right'

		readOnly={readOnly} value={inputValue} onChange={inputValueChanged}
		onFocus={e => onFocus(e)} onBlur={() => setIsFocused(false)}
		{...readOnly && { tabIndex: -1 }}
		{...rest}
	></Input>
}

SysNumber.__sysInput = true;
import { useEffect, useState } from "react";


import { Box, Button, Divider, FormControl, FormLabel, Img, Input, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useWSysHub, WSysHubDisplay } from "@coimbra-its/websys-ux-core";
import { useWSysAuthSlice } from "../store/authSlice";
import { useSunmoneyNetApi } from "../store/sunmoney.net.api";

export default function SignInForm() {

	const storeSessionId = useWSysAuthSlice(state => state.sessionId);

	const api = useSunmoneyNetApi();
	const hub = useWSysHub();

	const [idNumber, setIdNumber] = useState('');
	const [password, setPassword] = useState('');

	const startupPost = api.startupPost.useAction();
	const startupReady = useWSysAuthSlice(s => s.startupReady);

	useEffect(() => {
		console.log('--------> IN USE EFFECT <--- ', storeSessionId);
		hub.tryCatch(async () => {
			const res = await startupPost({});
			startupReady(res.user);
		});
	}, [storeSessionId])


	const signInPost = api.signInPost.useAction();
	const signedIn = useWSysAuthSlice(state => state.signedIn);

	const doSignIn = () => {
		hub.tryCatch(async () => {
			const res = await signInPost({
				id_number: idNumber,
				password
			});
			signedIn(res.SESSION_ID, res.user);
		});

	}

	const doRegister = () => {
		hub.addEvent({ message: 'Soon...', severity:'error', actions: [{caption:'Ok', callback:() => hub.reset()}] });	
	}

	return <Box sx={{
		display: 'flex', width: '100vw', height: '100vh',
		alignItems: 'center', justifyContent: 'center',
	}}>

		<Box p={5} bg={useColorModeValue('#fff', 'var(--chakra-colors-chakra-body-bg)')} boxShadow='md' borderRadius='md' position='relative' >
			<WSysHubDisplay hubs={[hub]}  >


				<Stack spacing={2}>
					<Box display='flex' justifyContent='space-evenly' alignItems='center' gap={2}>
						<Img src="/sunmoney-logo.png" w='180px' />
						<Box pl={2}>
							<Text sx={{ my: '0px' }} fontWeight='bold' fontSize='1.4em'>Sunmoney.net</Text>
							<Text sx={{ fontSize: '1em' }}>Sunmoney Account System</Text>
							<Text color={process.env.REACT_APP_STAGE === 'production' ? '#888' : 'red'}
								>{process.env.REACT_APP_STAGE}</Text>							
						</Box>
					</Box>
					<Box my='1em'>
						<form>
							<FormControl>
								<FormLabel>ID Number</FormLabel>
								<Input type='text' value={idNumber} onChange={e => setIdNumber(e.target.value)} autoComplete="username" />
							</FormControl>
							<FormControl>
								<FormLabel>Password</FormLabel>
								<Input type='password' value={password} onChange={e => setPassword(e.target.value)} autoComplete="current-password" />
							</FormControl>
						</form>
					</Box>
					<Divider />
					<Box my='1em' display='flex' flexDir='row' alignItems='center' justifyContent='space-evenly'>
						<Button type='submit' value='Register' onClick={doRegister} colorScheme="primary" >Register</Button>
						<Button type='submit' value='Sign in' onClick={doSignIn} colorScheme="primary" >Sign in</Button>
					</Box>
					<Divider />
					<Box fontSize='.75em' color='#888' display='flex' alignItems='center' flexDir='column'>
						<Link href="https://coimbraits.hu" target="_blank" >WebSys - © 2024 - Coimbra ITS Kft.</Link>
					</Box>
				</Stack>
			</WSysHubDisplay>
		</Box>
	</Box>

}


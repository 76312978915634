import { WSysLayout } from '@coimbra-its/websys-ux-core';
import { AppHub, WebSysNav } from './sys/NavBar';

import { useWSysAuthSlice } from './store/authSlice';
import SignInForm from './sys/SignInForm';
import { SignOut } from './sys/SignOut';
import { Box, Img } from '@chakra-ui/react';

console.log('STARTED WITH API:', process.env.REACT_APP_API_BASE_URL);

function App() {

	//const api = useMemo(() => new WSysApi(process.env.REACT_APP_API_BASE_URL+''||''), []);

	//console.log('API:', process.env.REACT_APP_API_BASE_URL);
	/*TODO: const dispatch: AppDispatch = useDispatch();
	const systemState = useSelector((state: RootState) => state.system);
	
	useEventListener<KeyboardEvent>('keydown', (e) => {
		if (e.key === 'L' && e.shiftKey && e.ctrlKey) {
			dispatch(showDebugSet(!systemState.layout.showDebug));
		}
	})*/

	const authSlice = useWSysAuthSlice();
	const user = authSlice.user;

	return (<>
		<Box position='fixed' right='50px' top='54px' color='#fff'>
			<Img src='/sunmoney-logo-white.png' w='200px' /><br />
		</Box>
		{!user
			? <SignInForm />
			: <WSysLayout nav={<WebSysNav />}>
				<AppHub />
				<SignOut />
			</WSysLayout>
		}
	</>);
}

export default App;

import { Box, Button, Card, CardBody, CardHeader, DarkMode, FormControl, FormLabel, Heading, Icon, Img, Input } from "@chakra-ui/react";
import { IMLM_PACKAGE_DISP, SunPackageTypeList } from "@coimbra-its/api.sunmoney.net";
import { useWSysPage, useWSysPath, WSysGrid, WSysTabHead } from "@coimbra-its/websys-ux-core";
import { SunMainPage } from "../../components/Sun";
import { useSunmoneyNetApi } from "../../store/sunmoney.net.api";
import { APPICONS } from "../../Icons";



export function Shop() {

    const path = useWSysPath();
    const pathSub = path.useChild(':tab');

    const api = useSunmoneyNetApi();

    const [data, hub] = api.packageGet.useData({});
    const packages = data?.PACKAGES || [];

    const page = useWSysPage({ title: 'Shop', maxCols: 3 })
    const tabs = SunPackageTypeList.map(t => ({ panelKey: t.KOD, title: t.MEGN }));

    const selectedPacks = packages.filter(p =>
        (pathSub.slug === 'SDBN-package' && p.is_sdbn)
        || (pathSub.slug === 'community-package' && p.is_reseller)
        || (pathSub.slug === 'solar-package' && !p.is_reseller && !p.is_sdbn && !p.is_education)
    )

    if (!pathSub.slug)
        pathSub.open('solar-package');


    return <SunMainPage page={page} hubs={[hub]} 
        headerTitle={<DarkMode><WSysTabHead tabs={tabs} onTabClick={key => pathSub.open(key)} selectedKey={pathSub.slug} /></DarkMode>}
        opaque={pathSub.slug !== 'voucher'}
    >
        {pathSub.on(<>
            {pathSub.slug === 'voucher' ? <Box minH='50vh' display='flex' alignItems='stretch' justifyContent='center' flexDir='column' p={5}>

                <FormControl >
                    <FormLabel >Enter voucher number:</FormLabel>
                    <Input />
                </FormControl>
                <Box mt={3} display='flex' flexDir='row' justifyContent='flex-end' >
                    <Button variant='solid' colorScheme='primary' >Submit</Button>
                </Box>

            </Box>

                : <ShopList items={selectedPacks} />
            }
        </>)}


    </SunMainPage>
}

export function ShopList({ items }: { items: IMLM_PACKAGE_DISP[] }) {

    const path = useWSysPath();
    const pathItem = path.useChildRow('', items, (item) => item.id);



    return <>
        <WSysGrid cols={[1, 1, 1]} minCols={3} justifyContent='space-evenly' gap={5} gridAutoRows='1fr'>
            {items.map(pack => <Card key={pack.id}
                _hover={{ outline: '3px solid #fff5' }} cursor='pointer'
                onClick={() => pathItem.open('' + pack.id)}
            >
                <CardHeader>{pack.name}</CardHeader>
                <CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center'>
                    {pack.is_sdbn 
                        ? <Img src={'/sdbn_package_tile.png'} />
                        : <Img src={'/solar_package_tile_transparent.png'} />
                    }
                </CardBody>
            </Card>)}

        </WSysGrid>
        {pathItem.on(<ShopItem pack={pathItem.forTable.selectedRow!} />)}
    </>


}



export function ShopItem({ pack }: { pack: IMLM_PACKAGE_DISP }) {
    const page = useWSysPage({ title: pack?.name || '', maxCols: 3 });

    const cardStyle = {
        _hover: { outline: '3px solid #fff5' },
        cursor: 'pointer',
        h: '100%',
        minH: '200px'

    };



    return <SunMainPage page={page} opaque>
        <WSysGrid cols={[1, 1, 1]} minCols={3} justifyContent='space-evenly' gap={5} gridAutoRows='1fr'>
            <Card {...cardStyle}>
                <CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center'>
                    <Icon as={APPICONS.Wallet} boxSize={10} />
                    <Heading size='md'>Buy with wallet</Heading>
                </CardBody>
            </Card>
            <Card {...cardStyle}>
            <CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center'>
                    <Icon as={APPICONS.CreditCard} boxSize={10} />
                    <Heading size='md'>Buy with credit card</Heading>
                </CardBody>
            </Card>
            <Card {...cardStyle}>
            <CardBody display='flex' flexDir='column' alignItems='center' justifyContent='center'>
                    <Icon as={APPICONS.Transfer} boxSize={10} />
                    <Heading size='md'>Buy via transer</Heading>
                </CardBody>
            </Card>
        </WSysGrid>
    </SunMainPage>
}
import { WSysEndpoint } from "@coimbra-its/websys-lib";
import { IMLM_PACKAGE_DISP } from "./auto.interface/IMLM_PACKAGE_DISP.auto.interface";
import { IMLM_USER_PUB } from "./auto.interface/IMLM_USER_PUB.auto.interface";
import { IArticle, IArticleLead, ISunBalanceItem, ITreeItem, TChartData, TSunUploadedFile } from "./types";






export const SUNMONEY_NET_ROUTES = {

	// ===================================================================== SIGN IN - OUT ===============
	startupPost: new WSysEndpoint('/startup', 'POST',
		req => ({}),
		res => ({
			user: res.payload().value<IMLM_USER_PUB>().default(),
		})
	),
	signInPost: new WSysEndpoint('/signin', 'POST',
		req => ({
			id_number: req.payload().str(),
			password: req.payload().str(),
		}),
		res => ({
			SESSION_ID: res.payload().str(),
			user: res.payload().value<IMLM_USER_PUB>(),
		})
	),
	signOutPost: new WSysEndpoint('/signout', 'POST',
		req => ({}),
		res => ({})
	),



	dashboardGet: new WSysEndpoint('/dashboard', 'GET',
		req => ({}),
		res => ({
			articles: res.payload().list<IArticleLead>(),
			sponsorLink : res.payload().str(),
		}),
	).provides(req => ['article']),

	articleSlugGet: new WSysEndpoint('/article/:slug', 'GET',
		req => ({
			slug: req.param().str(),
		}),
		res => ({
			article: res.payload().value<IArticle>(),
		}),
	).provides(req => ['article']),


	treeItemGet: new WSysEndpoint('/tree', 'GET',
		req => ({
			roots : req.query().str(),
			max_depth : req.query().idNum(),
		}),
		res => ({
			branches: res.payload().list<{root_id: number, items: ITreeItem[]}>(),
		})
	),

	chartGet: new WSysEndpoint('/chart', 'GET',
		req => ({}),
		res => ({
			CHART: res.payload().value<TChartData>()
		})
	).provides(req => ['chart']),

	packageGet: new WSysEndpoint('/package', 'GET',
		req => ({}),
		res => ({
			PACKAGES: res.payload().list<IMLM_PACKAGE_DISP>()
		})
	).provides(req => ['package']),


	accountDocumentsGet : new WSysEndpoint('/account/documents', 'GET', 
		req => ({}),
		res => ({
			PAYOUTS: res.payload().list<TSunUploadedFile>(),
		}),
	).provides(req => ['documents']),


	balancesGet : new WSysEndpoint('/balances', 'GET',
		req => ({}),
		res => ({
			BALANCES: res.payload().list<ISunBalanceItem>(),
		}),
	).provides(req => ['accounts']),		

};



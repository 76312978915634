import { Alert, AlertDescription, AlertTitle, Box, DarkMode } from '@chakra-ui/react';
import { ReactNode, useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useLocation } from 'react-router-dom';
import { useWSysCoreSlice } from '../store/coreSlice';


export function useWSysPreferences() {
	return useWSysCoreSlice(store => store.preferences);
}

// -------------------------------------------------- SCROLL -----------------------------------------------

export function WSysScroll({ children }: { children: ReactNode }) {
	return <Scrollbars renderTrackHorizontal={() => <div></div>} renderThumbHorizontal={() => <div></div>}>
		{children}
	</Scrollbars>
}




// -------------------------------------------------- COMMON ERROR HANDLING -----------------------------------------------
export function exctractErrorMessage(error: object | string | undefined): string {
	if (error && typeof error === 'string') {
		return error;
	} else {
		if (error && (error as any)['publicMessage'])
			return (error as any)['publicMessage'];
		return "Error..."
	}
}


// -------------------------------------------------- Javascript errors -----------------------------------------------
export function SysJsError({ error, resetErrorBoundary }: { error: any, resetErrorBoundary: () => void }) {
	return (
		<Alert status="error" display='flex' flexDir="column">
			<AlertTitle>Javascript error</AlertTitle>
			<AlertDescription>
				<pre>{JSON.stringify(error?.message || error || "???")}</pre>
				{false && <button onClick={resetErrorBoundary}>Try again</button>}
			</AlertDescription>
		</Alert>
	)
}
// -------------------------------------------------- DEBOUNCE -----------------------------------------------
export function debounce(fn: () => void, ms: number) {
	let timer: NodeJS.Timeout | null;
	return () => {
		if (timer)
			clearTimeout(timer);
		timer = setTimeout(() => {
			timer = null
			fn();
		}, ms)
	};
}


export function useDebounce<T>(value: T, delay?: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value)

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

		return () => {
			clearTimeout(timer)
		}
	}, [value, delay])

	return debouncedValue
}


// -------------------------------------------------- MODE -----------------------------------------------
export function ModeSelector({ isDark, children }: { isDark: boolean, children: ReactNode }) {
	if (isDark)
		return <DarkMode>{children}</DarkMode>
	else
		return <>{children}</>
}

// -------------------------------------------------- USE URL ID -----------------------------------------------

export function useWebSysUrlId() {

	const [itemId, setItemId] = useState<null | number | 'create'>(-1);
	const [isItemSelected, setIsItemSelected] = useState(false);
	const { search: queryParams, pathname } = useLocation();

	useEffect(() => {
		const parts = pathname.split('/');
		const idStr = parts.length > 2 ? parts[2] : null;
		if (idStr === 'create') {
			setItemId('create');
			setIsItemSelected(true);
		} else {
			const id = parseInt(idStr || "-1") || null;
			setItemId(id);
			setIsItemSelected(typeof id === 'number' && id > 0);
		}
	}, [pathname]);

	return {
		isItemSelected, itemId, queryParams
	}
}


// -------------------------------------------------- FORMATTERS -----------------------------------------------
export function formatNumber(f: any): string {
	if (f && f.replace) {
		f = f.replace(',', '.');
	}

	f = parseFloat(f);

	if (isNaN(f)) {
		return '';
	}

	var isNegative = f < 0;

	f = Math.abs(f);
	var nStr = '' + Math.floor(f);
	var x = nStr.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;

	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ' ' + '$2');
	}

	var frac = '' + (Math.round((f - Math.floor(f)) * 100) / 100)
	var fraca = frac.split('.');
	frac = fraca[fraca.length - 1];

	if (frac.length == 1) {
		frac += '0';
	}

	return ((isNegative) ? '-' : '') + (x1 + x2) + '.' + frac;


}



export function formatDate(f: any): string {
	if (typeof f == 'string') {
		var d = Date.parse(f);
		if (!isNaN(d)) {
			f = new Date(f);
		}
	}

	if (f instanceof Date) {
		return f.toLocaleDateString();
		/*
		if (f.getFullYear() < 1960)
			return '-';
		let mm = f.getMonth() + 1; // getMonth() is zero-based
		let dd = f.getDate();

		return [f.getFullYear(), '-',
		(mm > 9 ? '' : '0') + mm, '-',
		(dd > 9 ? '' : '0') + dd,
		].join('');*/
	} else {
		return JSON.stringify(f);
	}
}

export function formatDateTime(f: any): string {
	if (typeof f == 'string') {
		var d = Date.parse(f);
		if (!isNaN(d)) {
			f = new Date(f);
		}
	}

	if (f instanceof Date) {
		if (f.getFullYear() < 1960)
			return '-';
		let mm = f.getMonth() + 1; // getMonth() is zero-based
		let dd = f.getDate();
		let t_h = f.getHours();
		let t_m = f.getMinutes();

		return [f.getFullYear(), '-',
		(mm > 9 ? '' : '0') + mm, '-',
		(dd > 9 ? '' : '0') + dd, ' ',
		(t_h > 9 ? '' : '0') + t_h, ':',
		(t_m > 9 ? '' : '0') + t_m, '',
		].join('');
	} else {
		return JSON.stringify(f);
	}
}

export function formatDateForInput(value: Date): string {
	const offset = value.getTimezoneOffset() * 1000 * 60;
	const offsetDate = new Date(value).valueOf() - offset;// + (1000*60*60);
	const date = new Date(offsetDate).toISOString();
	return date.substring(0, 10);
}

export function formatDateTimeForInput(value: Date): string {
	// https://stackoverflow.com/questions/43329252/how-to-convert-javascript-date-object-to-string-that-is-compatible-with-datetime
	const offset = value.getTimezoneOffset() * 1000 * 60;
	const offsetDate = new Date(value).valueOf() - offset;// + (1000*60*60);
	const date = new Date(offsetDate).toISOString();
	return date.substring(0, 16);
}

export function formatElapsedSecs(s: number = 0) {
	let orig = s;
	let days = Math.floor(s / (60 * 60 * 24));
	s -= days * 60 * 60 * 24;
	let hours = Math.floor(s / (60 * 60));
	s -= hours * 60 * 60;
	let minutes = Math.floor(s / (60));
	s -= minutes * 60;
	return `${days > 0 ? days + ' nap' : ''} ${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + s).slice(-2)}`;
}


export function formatLongText(txt: string = '', max: number = 50) {
	let s = txt;
	if (s && typeof s == 'string' && s.length > max) {
		s = s.substring(0, max) + '...';
		return s;
		//return 	<Tooltip title={txt}><Typography variant='body2'  >{s}</Typography></Tooltip>;
	}
	//return 	<Typography variant='body2'  >{s}</Typography>;
	return s;

}

export function formatMoney(amount: number, dev: string) {
	return formatNumber(amount) + ' ' + formatDev(dev);
}

export function formatDev(dev: string) {
	switch (dev) {
		case 'HUF': return 'Ft.';
		case 'USD': return '$';
		case 'GBP': return '£';
		case 'EUR': return '€';

		default: return dev;
	}
}


// -------------------------------------------------- EVENTLISTENER -----------------------------------------------
export function useEventListener<E extends Event>(eventName: string, handler: (e: E) => void, element = window) {
	// Create a ref that stores handler
	const savedHandler = useRef<((e: E) => void)>();

	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(
		() => {
			// Make sure element supports addEventListener
			// On 
			const isSupported = element && element.addEventListener;
			if (!isSupported) return;

			// Create event listener that calls handler function stored in ref
			const eventListener = (event: any) => savedHandler.current!(event);

			// Add event listener
			element.addEventListener(eventName, eventListener);

			// Remove event listener on cleanup
			return () => {
				element.removeEventListener(eventName, eventListener);
			};
		},
		[eventName, element] // Re-run if eventName or element changes
	);
};



import { Box, Icon, Text } from "@chakra-ui/react";
import { ITreeItem } from "@coimbra-its/api.sunmoney.net";
import { HEADER_HEIGHT_PX, useWSysHub, useWSysPage, useWSysPath, WSysHeaderButton, WSysLog } from "@coimbra-its/websys-ux-core";
import { MouseEvent, PointerEvent, UIEvent, useCallback, useEffect, useRef, useState } from "react";
import { SunMainPage } from "../../components/Sun";
import { APPICONS } from "../../Icons";
import { useWSysAuthSlice } from "../../store/authSlice";
import { useSunmoneyNetApi } from "../../store/sunmoney.net.api";


interface IDrawItem {
    x: number;
    y: number;
    item: ITreeItem;
    children: IDrawItem[];
    weight: number;
    weightTop: number;
    loadable: boolean;
}

const logTree = WSysLog.asSource({ name: 'LAYOUT', dark: '#f63', light: '#f63' }, (sev) => true	);


export function Tree() {


    const auth = useWSysAuthSlice();
    //const path = useWSysPath();
    //const [pathRootId, setPathRootId] = path.useNumber('root', auth.user?.id || 0)
    //const [rootItem, setRootItem] = useState<ITreeItem>()


    const page = useWSysPage({ title: 'Your community', maxCols: 6 });




    // ----------------------------------------------------------------------------------------- CONSTS ----------------

    const [gridTop, setGridTop] = useState(5000);
    const gridLeft = 80;
    const gridWidth = 180;
    const gridHeight = 65;

    //const [scrollWidth, setScrollWidth] = useState(page.propsOut.widthCols * LAYOUT_COLUMN_WIDTH_PX);
    const scrollWidth = page.propsOut.widthPx;

    const scrollHeight = page.propsOut.maxHeightPx - HEADER_HEIGHT_PX - 4;
    const canvasWidth = 10000;
    const canvasHeight = 10000;
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const miniMapRef = useRef<HTMLCanvasElement | null>(null);

    const scrollRef = useRef<HTMLDivElement>(null);

    const projX = (x: number) => gridLeft + x * gridWidth;
    const projY = (y: number) => gridTop + y * gridHeight;

    const [centerUserId, setCenterUserId] = useState(0);



    // ----------------------------------------------------------------------------------------- PREPARE ----------------

    const [drawItems, setDrawItems] = useState<Map<number, IDrawItem>>(new Map<number, IDrawItem>());
    const [drawItemsByLevel, setDrawItemsByLevel] = useState<Array<Array<IDrawItem>>>(new Array<Array<IDrawItem>>());

    const api = useSunmoneyNetApi();
    //const [data, hub] = api.treeItemGet.useData({ root_id: pathRootId, max_level: 4 });
    const hub = useWSysHub();
    const treeItemGet = api.treeItemGet.useAction(); //useData({ root_id: pathRootId, max_level: 4 });

    const MAX_DEPTH = 6;

    const loadRootIds = (rootIds: number[]) => {
        hub.tryCatch(async () => {
            const data = await treeItemGet({ roots: JSON.stringify(rootIds), max_depth: MAX_DEPTH });

            const byId = new Map<number, IDrawItem>(drawItems);

            for (let branch of data.branches) {
                const root = byId.get(branch.root_id);
                console.log('=== BETÖLTVE ===', branch.root_id, branch.items.length, root);
                if (!root && byId.size > 0)
                    continue;
                branch.items.forEach(item => {
                    if (root)
                        item.level += root.item.level;
                    const di: IDrawItem = {
                        x: item.level,
                        y: 0,
                        item,
                        children: [], weight: 0, weightTop: 0,
                        loadable: false,
                    };
                    byId.set(item.user_id, di);
                });
            }
            calcNewDrawItems(`loadRootIds ${rootIds}`,  byId);
        });
    }

    useEffect(() => {
        loadRootIds([auth.user?.id || 0]);
    }, [auth.user?.id]);




    const calcNewDrawItems = useCallback((reason: string, byId: Map<number, IDrawItem>) => {
        logTree.info(`CALC`, reason)();

        const centerUser = byId.get(centerUserId);
        const oldCenterY = (centerUser?.y || false);
        


        const items = Array.from(byId.values());
        const byLevel = new Array<Array<IDrawItem>>();

        let maxLevel = items.map(i => i.item.level).reduce((p, c) => Math.max(p, c), 0);
        for (let i = 0; i <= maxLevel; i++)
            byLevel.push(new Array<IDrawItem>());
        logTree.debug('maxLevel:', maxLevel)();

        // ------------------ init byId & byLevel[0] ---------------
        items.forEach(di => {
            if (di.item.level === 0)
                byLevel[0].push(di);
        });

        if (!byLevel[0][0]) {
            reset();
            return;
        }

        // ------------------ collect children ------------------
        byId.forEach(di => {
            di.children = [];
            di.loadable = false;
        });
        byId.forEach(di => {
            const parent = byId.get(di.item.parent_user_id);
            if (parent)
                parent.children.push(di);
        });

        // --- set loadable ---
        byId.forEach(di => {
            const cc = (di.item.left_child_count > 0 ? 1 : 0) + (di.item.right_child_count > 0 ? 1 : 0);
            if (cc > di.children.length) {
                let i = MAX_DEPTH;
                let dix: IDrawItem | undefined = di;
                while (i > 0 && dix) {
                    dix.loadable = true;
                    dix = byId.get(dix.item.parent_user_id);
                    i--;
                }
            }
        });

        // ------------------------------------ place items on ByLevel in draw order ----------------------------
        for (let level = 0; level < maxLevel; level++) {
            byLevel[level].forEach(di => {
                di.children.sort((a,b)=> a.item.edge_index - b.item.edge_index);
                di.children.forEach(child => {
                    byLevel[child.item.level].push(child);
                });
            });
        }

        // ------------------ calc weights ------------------
        for (let level = maxLevel; level >= 0; level--) {
            byLevel[level].forEach(di => {
                let w = di.children.map(c => c.weight).reduce((p, c) => p + c, 0); // szum gyerekei súlya
                di.weight = Math.max(w, 1);
            })
        }

        // ------------------- calc y ------------------------
        const calc = (di: IDrawItem, top: number) => {
            di.y = di.weight / 2 + top;
            let t = top;
            for (let child of di.children) {
                calc(child, t);
                t += child.weight;
            }
        }
        calc(byLevel[0][0], 0);
        for (let level = maxLevel - 1; level >= 0; level--) {
            byLevel[level].forEach(di => {
                if (di.children.length === 2)
                    di.y = (di.children[0].y + di.children[1].y) / 2
                else if (di.children.length === 1)
                    di.y = di.children[0].y
            });
        }

        setDrawItems(byId);
        setDrawItemsByLevel(byLevel);


        const newCenterY = (centerUser?.y || false);
        if (oldCenterY !== false  && newCenterY !== false) {
            logTree.debug('*************** SET GRID TOP ***', gridTop, oldCenterY, newCenterY, ' DIFF: ', (- newCenterY + oldCenterY) * gridHeight, ' NEW: ', gridTop + (oldCenterY - newCenterY) * gridHeight)();
            setGridTop(gridTop + (oldCenterY - newCenterY) * gridHeight);
        }


    }, [centerUserId, gridTop, gridHeight]);
    //calc



    // ----------------------------------------------------------------------------------------- OTHER TREE MANIPULATE ----------------

    const drawItemClick = (e: MouseEvent<HTMLDivElement>, di: IDrawItem) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.button == 0) {
            loadRootIds([di.item.user_id]);
        }
        if (e.button == 2) {
            removeBranch(di);
        }
    }

    const removeBranch = (di: IDrawItem) => {
        const byId = new Map<number, IDrawItem>(drawItems);
        const rem = (di: IDrawItem) => {
            byId.delete(di.item.user_id);
            for (let child of di.children)
                rem(child);
        }
        rem(di);
        calcNewDrawItems(`removeBranch ${di.item.user_id}`, byId);
    }


    // ----------------------------------------------------------------------------------------- CANVAS ----------------


    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current!.scrollTop = gridTop;
            console.log(scrollRef.current!.scrollTop);
        }
    }, [scrollRef.current]);

    useEffect(() => {
        if (!canvasRef.current || !miniMapRef.current)
            return;

        const ctx = canvasRef.current.getContext('2d')!;
        const miniMap = miniMapRef.current.getContext('2d')!;
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        miniMap.clearRect(0, 0, 100, 100);
        ctx.fillStyle = '#fff';
        ctx.font = "24px serif";
        //ctx.fillText('maxItem '+maxItem, 10, 30);

        drawItems.forEach(di => {

            const parent = drawItems.get(di.item.parent_user_id);
            if (!parent)
                return;
            ctx.beginPath();
            ctx.moveTo(projX(parent.x), projY(parent.y));
            ctx.lineTo(projX(di.x), projY(di.y));
            ctx.strokeStyle = '#f684';
            ctx.lineWidth = Math.log2(Math.max(di.item.left_w + di.item.right_w, 1)) * 3 + 3;
            ctx.stroke();

            const zx = 3;
            const zy = 2;
            miniMap.beginPath();
            miniMap.arc(di.x * zx, di.y * zy, 1.1, 0, 2 * Math.PI);
            miniMap.fillStyle = '#ff8';
            //miniMap.fill();
        });


    }, [canvasRef.current, canvasWidth, canvasHeight, drawItems])


    // ----------------------------------------------------------------------------------------- MOUSE ----------------
    const [mouseDownPos, setMouseDownPos] = useState<{ x: number, y: number }>();
    const [mouseDownOffset, setMouseDownOffset] = useState<{ x: number, y: number }>();

    const onMouseDown = (e: PointerEvent<HTMLDivElement>) => {
        setMouseDownPos({ x: e.clientX, y: e.clientY });
        setMouseDownOffset({ x: scrollRef.current!.scrollLeft, y: scrollRef.current!.scrollTop });
    }
    const onMouseUp = (e: MouseEvent<HTMLDivElement>) => {
        if (!mouseDownPos)
            return;
        setMouseDownPos(undefined)
        setMouseDownOffset(undefined)
        onScroll(e);
    }
    const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
        if (!mouseDownPos || !mouseDownOffset || !scrollRef.current)
            return;
        e.preventDefault();
        scrollRef.current.scrollTo({
            left: mouseDownOffset.x - e.clientX + mouseDownPos.x,
            top: mouseDownOffset.y - e.clientY + mouseDownPos.y,
            //behavior:'smooth'
        })
    }


    const reset = () => {
        setDrawItems(new Map<number, IDrawItem>());
        loadRootIds([auth.user?.id || 0]);
        if (scrollRef.current) {
            scrollRef.current.scrollTo({
                left: 0,
                top: 0,
                behavior: 'smooth'
            })
        }
    }

    const [leftLevel, setLeftLevel] = useState(0);
    const [toLoadUserIds, setToLoadUserIds] = useState<number[]>([]);
    const onScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
        if (!scrollRef.current)
            return;
        //console.log(scrollRef.current?.scrollLeft);
        const ll = Math.min(drawItemsByLevel.length, Math.floor((scrollRef.current?.scrollLeft - gridLeft) / gridWidth) + Math.floor(scrollWidth / gridWidth / 2));
        setLeftLevel(ll);

        if (drawItemsByLevel[ll]) {
            const top = Math.floor((scrollRef.current.scrollTop - gridTop) / gridHeight);
            const height = scrollHeight / gridHeight;
            //const arr = new Array<number>();
            let center = drawItemsByLevel[ll][0];
            drawItemsByLevel[ll].forEach(di => {
                if (di.y < top + height / 2)
                    center = di;

            });
            setToLoadUserIds([center.item.user_id]);
            setCenterUserId(center.item.user_id);

            const byId = new Map<number, IDrawItem>(drawItems);
            drawItems.forEach(di => {
                if (di.x > 0 && (di.x > ll + 6 /*|| di.y < top-1*/)) {
                    byId.delete(di.item.user_id)
                }

            });

            calcNewDrawItems('afterScroll...', byId);
        }
    }, [calcNewDrawItems, drawItemsByLevel, gridLeft, gridWidth, , gridTop, gridHeight, scrollWidth, scrollHeight]);


    // ----------------------------------------------------------------------------------------- RENDER ----------------
    return <SunMainPage page={page} opaque sp={0} hubs={[]}
        tools={<Box display='flex' flexDir='row' alignItems='center' gap={1}>
            {/*hub.isLoading && "loading"}
            <Box>gridTop:{gridTop}</Box>
            <WSysHeaderButton icon={<APPICONS.Delete />} onClick={reset} >Reset</WSysHeaderButton>
*/}
        </Box>}
    >
        {/*<Box bg="#ff99">
            widthCols: {page.propsOut.widthCols};  LAYOUT_COLUMN_WIDTH_PX : {LAYOUT_COLUMN_WIDTH_PX}
            most: {page.propsOut.widthPx}px;
        </Box>*/}
        <Box width={scrollWidth + 'px'} height={scrollHeight + 'px'} 
            position='relative' overflow='hidden' border='0px solid red'
            //onScroll={onScroll} 
            ref={scrollRef}
            onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove} onMouseLeave={onMouseUp}
			onPointerDown={onMouseDown} onPointerUp={onMouseUp} onPointerMove={onMouseMove}
			style={{touchAction:'none'}}
        >

            <Box width={canvasWidth + 'px'} height={canvasHeight + 'px'} 
                position='relative' overflow='hidden' border='0px solid green' cursor='grab'
            >
                <canvas width={canvasWidth + 'px'} height={canvasHeight + 'px'} ref={canvasRef} ></canvas>


                {Array.from(drawItems.values()).map(di => <Box key={di.item.user_id}
                    position='absolute'
                    left={`${projX(di.x) - gridWidth / 2}px`}
                    top={`${projY(di.y) - gridHeight / 2}px`}
                    width={`${gridWidth}px`} height={`${gridHeight}px`}
                    bg='#fff0'
                    display='flex' flexDir='column' alignItems='center' justifyContent='center'
                    color='#fff' cursor='pointer'
                    _hover={{ bg: '#fff3' }}
                    onClick={e => drawItemClick(e, di)} onContextMenu={e => drawItemClick(e, di)}
                    transition='left .3s ease-out, top .1s ease-out'
                    //{...di.loadable && { color: 'green' }}
                    {...toLoadUserIds.find(i => i === di.item.user_id) && { color: 'yellow' }}
                >
                    <Icon boxSize='8' as={APPICONS.User} textShadow='2px 2px 2px #000' />
                    <Text fontSize='xs'>{di.item.name || '#'+di.item.id_number} {di.item.edge_index}</Text>
                    {/*<Text fontSize='xs' color='#ffadad'>LVL {di.item.level}&nbsp;&nbsp;{di.weight}&nbsp;&nbsp;{projY(di.y)}</Text>*/}
                </Box>)}


            </Box>
        </Box>
        <Box position='absolute' right={0} top={0}>
            <canvas width={'100px'} height={'100px'} ref={miniMapRef} ></canvas>
        </Box>

    </SunMainPage >
}
import { LAYOUT_COLUMN_WIDTH_PX, useWSysPage, useWSysPath } from "@coimbra-its/websys-ux-core";
import { useWSysAuthSlice } from "../store/authSlice";
import { SunMainPage } from "../components/Sun";
import { BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Tooltip, Rectangle } from 'recharts';
import { Box, Heading, IconButton, Text } from "@chakra-ui/react";
import { useSunmoneyNetApi } from "../store/sunmoney.net.api";
import { APPICONS } from "../Icons";
import { useEffect } from "react";



export function SunChart() {
    const authSlice = useWSysAuthSlice();
    const user = useWSysAuthSlice(s => s.user);

    const api = useSunmoneyNetApi();

    const path = useWSysPath();
    const page = useWSysPage({ title: 'Monthly Solar Royalty', maxCols: 3});

    const [data, hub] = api.chartGet.useData({});
    const years = data?.CHART ? data.CHART.map(item => item.year) : [];
    const minYear = years.reduce((p, y) => Math.min(p, y), 3000);
    const maxYear = years.reduce((p, y) => Math.max(p, y), 0);
    const [selectedYear, setSelectedYear] = path.useNumber('year', 0);

    useEffect(() => {
        if (maxYear > 0 && (selectedYear < minYear || selectedYear > maxYear))
            setSelectedYear(maxYear);
    }, [minYear, maxYear, selectedYear]);



    const yearly = data?.CHART ? data.CHART.find(item => item.year === selectedYear) : undefined;

    

    //<ResponsiveContainer width="100%" height="400px" style={{border:'2px solid red'}}>

    return <SunMainPage page={page}
        
    >
        <Box display='flex' flexDir='row' alignItems='center' justifyContent='space-between' alignSelf='stretch'>
            <IconButton icon={<APPICONS.Left />} aria-label="left" onClick={() => setSelectedYear(selectedYear - 1)} isDisabled={selectedYear == minYear} />
                <Heading my={4}>1kW SOLAR PACKAGE<br/>
                <Text fontWeight='200'>
                    MONTHLY SOLAR ROYALTY {yearly?.year}
                </Text>
                </Heading>
            <IconButton icon={<APPICONS.Right />} aria-label="right" onClick={() => setSelectedYear(selectedYear + 1)} isDisabled={selectedYear == maxYear} />
        </Box>

        {yearly && <Box border='0px solid red' display='flex' flexDir='column' alignItems='center'>
        <BarChart
            width={page.propsOut.widthPx}
            height={400}
            data={yearly.monthly}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >

            <XAxis dataKey="month" />
            <YAxis />
            {/*<Tooltip />*/}
            <Legend />
            <Bar dataKey="solar_royality_eur" fill="#fdab5b" activeBar={<Rectangle fill="gold" stroke="purple" />}
			label={{ position: "insideTop", fill: "black", offset:10 }}			
			/>
        </BarChart></Box>}
    </SunMainPage>



}
import { useEffect, useRef, useState } from "react";

import { Box, Button } from "@chakra-ui/react";
import { HEADER_HEIGHT_PX, WSYS_HIDE_SCROLLBARS_CSS } from "./Layout.TODO";



export interface IPageTabItem {
	panelKey: string;
	title: string;
}

export interface WSysTabHeadProps {
	tabs : IPageTabItem[];
	onTabClick : (key : string) => void;
	selectedKey : string;
}

export function WSysTabHead({ tabs, onTabClick, selectedKey } : WSysTabHeadProps ) {
	const [tabLeftPx, setTabLeftPx] = useState(0);
	//const [selectedTabKey, setSelectedTabKey] = useState(tabs[0].panelKey);

	useEffect(() => {
		if (tabListOuterRef.current?.clientWidth && tabListRef.current?.scrollWidth && tabSelectedRef.current?.clientWidth) {
			//if (systemState.layout.dumpPanelsScroll) console.log('TAB.BUTTON', tabSelectedRef.current?.offsetLeft, tabSelectedRef.current?.clientWidth)
			const tabOverflow = (tabListOuterRef.current?.clientWidth) < (tabListRef.current?.scrollWidth) - 10;
			if (tabOverflow) {
				const _tabLeftPx = Math.min(0, Math.max(-tabListRef.current.scrollWidth + tabListOuterRef.current.clientWidth,
					(tabListOuterRef.current?.clientWidth / 2) - (tabSelectedRef.current?.offsetLeft + tabSelectedRef.current?.clientWidth / 2)
				));
				if (Math.abs(tabLeftPx - _tabLeftPx) > 3)
					setTabLeftPx(_tabLeftPx);
				//console.log('SCROLLLEFT', tabListOuterRef.current.scrollLeft, - _tabLeftPx)
				tabListOuterRef.current.scrollTo({left: - _tabLeftPx, behavior:'smooth'});
			} else {
				tabListOuterRef.current.scrollTo({left: 0, behavior:'smooth'});
			}

		}
	});




	const tabListRef = useRef<HTMLDivElement>(null);
	const tabListOuterRef = useRef<HTMLDivElement>(null);
	const tabSelectedRef = useRef<HTMLButtonElement | null>(null);

	let tabSelectedIx = -1;
	let tabCenter = tabs;
	if (tabs) {
		tabSelectedIx = tabs.findIndex(t => t.panelKey === selectedKey)
		tabCenter = [];
		for (let i = Math.max(tabSelectedIx - 1, 0); i <= Math.min(tabSelectedIx + 1, tabs.length - 1); i++)
			tabCenter.push(tabs[i]);
	}

	const tabHeightPx = HEADER_HEIGHT_PX+1; // TODO: néha 4

	return <Box className='wsys-tablist-outer' width='100%' height={tabHeightPx + 'px'} overflowX='scroll' ref={tabListOuterRef}
				css={WSYS_HIDE_SCROLLBARS_CSS as any} 
				>
				<Box className='wsys-tablist-scroll' width='fit-content' ref={tabListRef}
					height={(tabHeightPx-2) + 'px'} display='flex' alignItems='flex-end'
				>
					{/* ---------------- */}
					{tabs!.map((tab, ix) => <Button key={tab.panelKey} ref={(r) => { if (r && (tab.panelKey === selectedKey)) tabSelectedRef.current = r }}
						borderBottomRadius='none' m={0}
						{...selectedKey === tab.panelKey
							? (tab.panelKey === tabs[0].panelKey
								? { colorScheme: 'primary', variant: 'solid', size: 'md', fontSize: 'lg' }
								: { colorScheme: 'primary', variant: 'solid', size: 'md' }
							)
							: (tab.panelKey === tabs[0].panelKey
								? { variant: 'ghost', size: 'md', fontSize: 'lg' } //, colorScheme:'primary'
								: { variant: 'ghost', size: 'sm' }
							)
						}
						onClick={() => onTabClick(tab.panelKey)}
					>
						{tab.title}
					</Button>)}
					
				</Box>
		</Box >


}
import { Box, Heading, Icon, Link, Stack } from "@chakra-ui/react";
import { useSunmoneyNetApi } from "../../store/sunmoney.net.api";
import { WSysHubDisplay } from "@coimbra-its/websys-ux-core";
import { APPICONS } from "../../Icons";




export function ProfileDocuments() {

    const api = useSunmoneyNetApi();
    const [data, hub] = api.accountDocumentsGet.useData({});
    const payouts = data?.PAYOUTS || [];

    return <WSysHubDisplay hubs={[hub]}>
        <Heading size='xl' mb={4} borderBottom='1px solid #ccc'>Payouts</Heading>
        <Stack>
            {payouts.map(file =>
                <Link href="https://telex.hu" target="_blank"  _hover={{textDecor:'none', bg:'#0001'}} >
                    <Box display='flex' flexDir='row' alignItems='center' gap={2}>
                        <Icon as={APPICONS.Pfd} boxSize={7} color='#850249' />
                        {file.filename}
                    </Box>
                </Link>
            )}
        </Stack>
        <Heading size='xl' my={4} borderBottom='1px solid #ccc'>Invoices</Heading>
        <Stack>
            {payouts.map(file =>
                <Link href="https://telex.hu" target="_blank" _hover={{textDecor:'none', bg:'#0001'}} >
                    <Box display='flex' flexDir='row' alignItems='center' gap={2}>
                        <Icon as={APPICONS.Pfd} boxSize={7} color='#850249' />
                        {file.filename}
                    </Box>
                </Link>
            )}
        </Stack>
    </WSysHubDisplay>

}
import { IDetailToPost, ValidationError } from "./common";
//import { TRANS_VALID__BAD_DATETIME, TRANS_VALID__BAD_ENUM, TRANS_VALID__REQUIRED_IS_EMPTY, TRANS_VALID__TYPES_MISSMATCH } from "../trans";
// TODO
export const TRANS_VALID__REQUIRED_IS_EMPTY = 'Kötelező mező';
export const TRANS_VALID__TYPES_MISSMATCH = 'Érvénytelen típus';
export const TRANS_VALID__BAD_DATETIME = 'Hibás dátum';
export const TRANS_VALID__BAD_ENUM = 'Hibás érték';
export const TRANS_VALID__DELETE_SURE = 'Biztosan törlöd?';

export interface IClassValidator<T> {
	new() : IClassValidator<T>;
}



// ============================================== VALIDATION RESULT ==============================================
export class ValidationResult2<TEntity> {
	fields = new Map<string, Array<string>>();
	general: string = '';
	normalized: TEntity = {} as TEntity;

	appendError(name: keyof TEntity & string, error: string) {
		let li = this.fields.get(name);
		if (!li) {
			li = new Array<string>();
			this.fields.set(name, li);
		}
		li.push(error);
		return li;
	}

	public f(name: keyof TEntity & string): Array<string> {
		return this.fields.get(name) || [];
	}

	public firstMessage(): string | undefined {
		if (this.general)
			return this.general;
		if (this.fields.size === 0)
			return;
		let first = [...this.fields][0];
		return `'${first[0]}' : ${first[1]}`;
	}

	public get ok(): boolean {
		return !this.general && (this.fields.size === 0);
	}

	public NormalizedOrThrow(prefix?: string): TEntity {
		let msg = this.firstMessage();
		if (msg)
			throw new ValidationError((prefix || '') + msg);
		return this.normalized;
	}

	public NormalizedOrThrowRow(prefix?: string): Omit<TEntity, keyof IDetailToPost> {
		let msg = this.firstMessage();
		if (msg)
			throw new ValidationError((prefix || '') + msg);
		return this.normalized;
	}

}

type CustomValidator<TEntity> = (obj: TEntity,  result : ValidationResult2<TEntity>, meta: ClassValidator<TEntity>) => void;

// ============================================== META ==============================================
export class ClassValidator<TEntity> {
	protected columns: Map<keyof TEntity & string, Column<TEntity, any>>;
	protected validators : CustomValidator<TEntity>[] = [];
	keyColumns: Array<Column<TEntity, any>> = [];
	public String(name: keyof TEntity & string): StringColumn<TEntity> {
		let col = new StringColumn<TEntity>(this, name);
		this.columns.set(name, col);
		return col;
	}
	public Int(name: keyof TEntity & string): IntColumn<TEntity> {
		let col = new IntColumn<TEntity>(this, name);
		this.columns.set(name, col);
		return col;
	}
	public Number(name: keyof TEntity & string): NumberColumn<TEntity> {
		let col = new NumberColumn<TEntity>(this, name);
		this.columns.set(name, col);
		return col;
	}
	public DateTime(name: keyof TEntity & string): DateTimeColumn<TEntity> {
		let col = new DateTimeColumn<TEntity>(this, name);
		this.columns.set(name, col);
		return col;
	}
	public Reference<TReferenced>(name: keyof TEntity & string, refType: string
		, refName: keyof TEntity & string, referencedColumnName: keyof TReferenced & string): ReferenceColumn<TEntity, TReferenced> {
		let col = new ReferenceColumn<TEntity, TReferenced>(this, name, refType, refName, referencedColumnName);
		this.columns.set(name, col);
		return col;
	}
	public Enum(name: keyof TEntity & string, options: Array<string>, defaultValue?: string): EnumColumn<TEntity> {
		let col = new EnumColumn<TEntity>(this, name, options, defaultValue);
		this.columns.set(name, col);
		return col;
	}


	// ------------

	public CustomValidator(validator: CustomValidator<TEntity>) {
		this.validators.push(validator);
	}

	public Validate(obj: TEntity): ValidationResult2<TEntity> {
		let result = new ValidationResult2<TEntity>();
		this.columns.forEach((column, name) => {
			column.Validate(obj, result);
		});
		for (let validator of this.validators) {
			validator(obj, result, this);
		}		
		return result;
	}

	public EmptyObject(): TEntity {
		let emptyObj = {} as TEntity;
		this.columns.forEach((column, name) => {
			column.Empty(emptyObj);
		});
		return emptyObj;
	}

	public EmptyResult(): ValidationResult2<TEntity> {
		return new ValidationResult2<TEntity>();
	}

	constructor(from?: ClassValidator<TEntity>) {
		this.columns = new Map<keyof TEntity & string, Column<TEntity, any>>();
		// TODO: should deepcopy!
		if (from) {
			from.columns.forEach((column, cn) => {
				this.columns.set(cn, column);
			})
			this.keyColumns = [...from.keyColumns];
		}
	}

	public Column(column: keyof TEntity & string): Column<TEntity, any> {
		return this.columns.get(column)!;
	}

	public AllColumnNames(prefix: string = '') : Array<keyof TEntity> {
		return Array.from(this.columns.entries())
			.filter(kv => !!kv[1].sqlType)
			.map(kv => prefix + kv[0]) as Array<keyof TEntity>;
	}
	
	public GetRowServerKey(row: TEntity) {
		return 'SRV__' + this.keyColumns.map(kc => ('' + row[kc.name])).join('__');
	}

}


// ============================================== PROPERTIES ==============================================


export abstract class Column<TEntity, TColumn> implements Column<TEntity, TColumn> {
	protected isPrimaryKey = false;
	protected required = true;
	public caption: string | undefined;
	public description : string | undefined;
	public charWidth: number | undefined;
	public sqlType: string | undefined;
	constructor(private readonly _meta: ClassValidator<TEntity>, private readonly _name: keyof TEntity & string) {

	}
	public get name(): keyof TEntity & string { return this._name };

	public Key(): Column<TEntity, TColumn> {
		this.isPrimaryKey = true;
		this._meta.keyColumns.push(this);
		return this;
	}

	public Required(): Column<TEntity, TColumn> {
		this.required = true;
		return this;
	}

	public Caption(caption: string): Column<TEntity, TColumn> {
		this.caption = caption;
		return this;
	}

	public CharWidth(charWidth: number): Column<TEntity, TColumn> {
		this.charWidth = charWidth;
		return this;
	}

	public Optional(): Column<TEntity, TColumn> {
		this.required = false;
		return this;
	}

	public Description(description: string): Column<TEntity, TColumn> {
		this.description = description;
		return this;
	}

	public SqlType(sqlType : string): Column<TEntity, TColumn> {
		this.sqlType = sqlType;
		return this;
	} 



	public abstract ValidateRequired(obj: any, result: ValidationResult2<TEntity>) : void;
	public abstract ValidateOptional(obj: any, result: ValidationResult2<TEntity>) : void;
	public Validate(obj: any, result: ValidationResult2<TEntity>) {
		if (this.required)
			this.ValidateRequired(obj, result);
		else
			this.ValidateOptional(obj, result);
	}

	public abstract EmptyRequired(empty: TEntity) : void;
	public abstract EmptyOptional(empty: TEntity) : void;
	public Empty(empty: TEntity) {
		if (this.required)
			return this.EmptyRequired(empty);
		else
			return this.EmptyOptional(empty);
	}


}

// -------------------------------------------------------------------------------- STRING --------------------------------------
export class StringColumn<TEntity> extends Column<TEntity, string> {
	public ValidateRequired(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		result.normalized[this.name] = value;
		if (typeof value !== 'string' || !value) {
			result.appendError(this.name, TRANS_VALID__REQUIRED_IS_EMPTY);
			result.normalized[this.name] = '' as any;
		}
	}

	public ValidateOptional(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		result.normalized[this.name] = value;
		if (typeof value !== 'string' || !value) {
			result.normalized[this.name] = '' as any;
		}
		if (value && typeof value !== 'string') {
			result.appendError(this.name, TRANS_VALID__TYPES_MISSMATCH);
		}
	}



	public EmptyRequired(obj: TEntity) {
		obj[this.name] = '' as any;
	}
	public EmptyOptional(obj: TEntity) {
		obj[this.name] = null as any;
	}

}
// -------------------------------------------------------------------------------- INT --------------------------------------
export class IntColumn<TEntity> extends Column<TEntity, number> {
	public ValidateRequired(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		result.normalized[this.name] = value;
		if (typeof value !== 'number' || value !== Math.floor(value)) {
			result.appendError(this.name, TRANS_VALID__REQUIRED_IS_EMPTY + `(req.int=${typeof value})`);
			result.normalized[this.name] = 0 as any;
		}
	}

	public ValidateOptional(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		result.normalized[this.name] = value;
		if (typeof value !== 'number') {
			result.normalized[this.name] = null as any;
		}
		if (value && (typeof value !== 'number' || value !== Math.floor(value))) {
			result.appendError(this.name, TRANS_VALID__TYPES_MISSMATCH+"uuu");
		}
	}



	public EmptyRequired(obj: TEntity) {
		obj[this.name] = 0 as any;
	}
	public EmptyOptional(obj: TEntity) {
		obj[this.name] = null as any;
	}

}

// -------------------------------------------------------------------------------- NUMBER --------------------------------------
export function isNumberColumn<TEntity>(c : Column<TEntity, any>) : c is NumberColumn<TEntity> {
	return c.constructor.name === 'NumberColumn';
}

export class NumberColumn<TEntity> extends Column<TEntity, number> {
	public ValidateRequired(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		result.normalized[this.name] = value;
		if (typeof value !== 'number') {
			result.appendError(this.name, TRANS_VALID__REQUIRED_IS_EMPTY+'xxx'+ (typeof value));
			result.normalized[this.name] = 0 as any;
		}
	}

	public ValidateOptional(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		result.normalized[this.name] = value;
		if (typeof value !== 'number') {
			result.normalized[this.name] = null as any;
		}
		if (value && (typeof value !== 'number')) {
			result.appendError(this.name, TRANS_VALID__TYPES_MISSMATCH+'yyy');
		}
	}



	public EmptyRequired(obj: TEntity) {
		obj[this.name] = 0 as any;
	}
	public EmptyOptional(obj: TEntity) {
		obj[this.name] = null as any;
	}

}

// -------------------------------------------------------------------------------- DATETIME --------------------------------------
export function isDateTimeColumn<TEntity>(c : Column<TEntity, any>) : c is DateTimeColumn<TEntity> {
	return c.constructor.name === 'DateTimeColumn';
}
export class DateTimeColumn<TEntity> extends Column<TEntity, Date> {
	public subKind: 'DateTime' | 'DateOnly' = 'DateOnly';
	public ValidateRequired(obj: any, result: ValidationResult2<TEntity>) {
		let value = obj[this.name];
		if (typeof value === 'string')
			value = new Date(value);
		result.normalized[this.name] = value;

		if (value instanceof Date) {
			// it is a date
			if (isNaN(value as any)) { // d.getTime() or d.valueOf() will also work
				// date object is not valid
				result.appendError(this.name, TRANS_VALID__BAD_DATETIME);
				result.normalized[this.name] = new Date() as any;
			} else {
				// date object is valid
			}
		} else {
			// not a date object
			result.appendError(this.name, TRANS_VALID__BAD_DATETIME);
			result.normalized[this.name] = new Date() as any;
		}
	}

	public ValidateOptional(obj: any, result: ValidationResult2<TEntity>) {
		let value = obj[this.name] || null;
		if (typeof value === 'string')
			value = new Date(value);
		result.normalized[this.name] = value;

		if (value) {
			if (value instanceof Date) {
				// it is a date
				if (isNaN(value as any)) { // d.getTime() or d.valueOf() will also work
					// date object is not valid
					result.appendError(this.name, TRANS_VALID__BAD_DATETIME);
					result.normalized[this.name] = null as any;
				} else {
					// date object is valid
				}
			} else {
				// not a date object
				result.appendError(this.name, TRANS_VALID__BAD_DATETIME);
				result.normalized[this.name] = null as any;
			}
		}

	}



	public EmptyRequired(obj: TEntity) {
		obj[this.name] = new Date() as any;
	}
	public EmptyOptional(obj: TEntity) {
		obj[this.name] = null as any;
	}

}

// -------------------------------------------------------------------------------- REFERENCE --------------------------------------
export function isReferenceColumn<TEntity, TReferenced>(c : Column<TEntity, TReferenced>) : c is ReferenceColumn<TEntity, TReferenced> {
	return c.constructor.name === 'ReferenceColumn';
} 
export class ReferenceColumn<TEntity, TReferenced> extends Column<TEntity, string> {
	public readonly refName: keyof TEntity & string;
	public readonly refType: string;
	public readonly referencedColumnName: keyof TReferenced & string;
	constructor(meta: ClassValidator<TEntity>, name: keyof TEntity & string, refType: string, refName: keyof TEntity & string, referencedColumnName: keyof TReferenced & string) {
		super(meta, name);
		this.refType = refType;
		this.refName = refName;
		this.referencedColumnName = referencedColumnName;
	}

	public ValidateRequired(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		const refValue = obj[this.refName];
		result.normalized[this.name] = value;
		result.normalized[this.refName] = refValue;
		if ((typeof value !== 'string' && typeof value !== 'number') || !value || typeof refValue !== 'object' || !refValue) {
			//console.log(this.name, this.refName, value, refValue);
			result.appendError(this.name, TRANS_VALID__REQUIRED_IS_EMPTY);
			result.normalized[this.name] = '' as any;
			result.normalized[this.refName] = null as any;
		}
	}

	public ValidateOptional(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		const refValue = obj[this.refName];
		result.normalized[this.name] = value;
		result.normalized[this.refName] = refValue;
		if ((typeof value !== 'string' && typeof value !== 'number') || !value || typeof refValue !== 'object' || !refValue) {
			result.normalized[this.name] = null as any;
			result.normalized[this.refName] = null as any;
		}
		if (value && typeof value !== 'string' && typeof value !== 'number') {
			result.appendError(this.name, TRANS_VALID__TYPES_MISSMATCH);
		}
		if (refValue && typeof refValue !== 'object') {
			result.appendError(this.name, TRANS_VALID__TYPES_MISSMATCH);
		}
	}



	public EmptyRequired(obj: TEntity) {
		obj[this.name] = '' as any;
		obj[this.refName] = '' as any;
	}
	public EmptyOptional(obj: TEntity) {
		obj[this.name] = null as any;
		obj[this.refName] = null as any;
	}

}


// -------------------------------------------------------------------------------- ENUM --------------------------------------
export class EnumColumn<TEntity> extends Column<TEntity, string> {
	protected options: Array<string>;
	protected defaultValue: string;
	constructor(meta: ClassValidator<TEntity>, name: keyof TEntity & string, options: Array<string>, defaultValue?: string) {
		super(meta, name);
		this.options = options;
		this.defaultValue = defaultValue === undefined ? this.options[0] : defaultValue;
	}

	public ValidateRequired(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		result.normalized[this.name] = value;
		if (typeof value !== 'string' || !value || this.options.indexOf(value) === -1) {
			result.appendError(this.name, TRANS_VALID__REQUIRED_IS_EMPTY);
			result.normalized[this.name] = this.defaultValue as any;
		}
	}

	public ValidateOptional(obj: any, result: ValidationResult2<TEntity>) {
		const value = obj[this.name];
		result.normalized[this.name] = value;
		if (typeof value !== 'string' || !value) {
			result.normalized[this.name] = null as any;
		}
		if (value && typeof value !== 'string') {
			result.appendError(this.name, TRANS_VALID__TYPES_MISSMATCH);
		}
		if (value && this.options.indexOf(value) === -1) {
			result.appendError(this.name, TRANS_VALID__BAD_ENUM);
		}
	}



	public EmptyRequired(obj: TEntity) {
		obj[this.name] = this.defaultValue as any;
	}
	public EmptyOptional(obj: TEntity) {
		obj[this.name] = null as any;
	}

}

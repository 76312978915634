import { ClassValidator } from '@coimbra-its/websys-lib';

export interface IMLM_USER_PUB {

	name : string;

	email : string;

	id : number;

	id_number : string;
}

export class MLM_USER_PUB_META_CLASS extends ClassValidator<IMLM_USER_PUB> {
	constructor() {
		super() 
		this.String('name').CharWidth(50).SqlType('varchar');
		this.String('email').CharWidth(50).SqlType('varchar');
		this.Number('id').Key().CharWidth(10).SqlType('bigint');
		this.String('id_number').CharWidth(4).SqlType('varchar');
	}
}

export const MLM_USER_PUB_META = new MLM_USER_PUB_META_CLASS();

import { ClassValidator } from '@coimbra-its/websys-lib';

export interface IMLM_PACKAGE_DISP {

	id : number;

	is_active : number;

	is_reseller : number;

	is_special : number;

	is_education : number;

	is_sdbn : number;

	education_valid_days : number;

	power : number;

	education : number;

	registration_fee : number;

	yearly_fee : number;

	direct_commission : number;

	direct_first_business_commission : number;

	indirect_commission : number;

	energy_commission : number;

	commission_limit : number;

	cash_payment : number;

	mandatory_purchase : number;

	charity : number;

	commission_base : number;

	paypal_fee : number;

	has_check_child_business_package : number;

	ipayment_fee : number;

	shop_id : number;

	name : string;
}

export class MLM_PACKAGE_DISP_META_CLASS extends ClassValidator<IMLM_PACKAGE_DISP> {
	constructor() {
		super() 
		this.Number('id').Key().CharWidth(10).SqlType('bigint');
		this.Number('is_active').CharWidth(10).SqlType('tinyint');
		this.Number('is_reseller').CharWidth(10).SqlType('tinyint');
		this.Number('is_special').CharWidth(10).SqlType('tinyint');
		this.Number('is_education').CharWidth(10).SqlType('tinyint');
		this.Number('is_sdbn').CharWidth(10).SqlType('tinyint');
		this.Int('education_valid_days').CharWidth(10).SqlType('int');
		this.Number('power').CharWidth(10).SqlType('bigint');
		this.Number('education').CharWidth(10).SqlType('bigint');
		this.Number('registration_fee').CharWidth(10).SqlType('decimal');
		this.Number('yearly_fee').CharWidth(10).SqlType('decimal');
		this.Number('direct_commission').CharWidth(10).SqlType('decimal');
		this.Number('direct_first_business_commission').CharWidth(10).SqlType('decimal');
		this.Number('indirect_commission').CharWidth(10).SqlType('decimal');
		this.Number('energy_commission').CharWidth(10).SqlType('decimal');
		this.Number('commission_limit').CharWidth(10).SqlType('decimal');
		this.Int('cash_payment').CharWidth(10).SqlType('int');
		this.Int('mandatory_purchase').CharWidth(10).SqlType('int');
		this.Int('charity').CharWidth(10).SqlType('int');
		this.Number('commission_base').CharWidth(10).SqlType('decimal');
		this.Number('paypal_fee').CharWidth(10).SqlType('decimal');
		this.Number('has_check_child_business_package').CharWidth(10).SqlType('tinyint');
		this.Number('ipayment_fee').CharWidth(10).SqlType('decimal');
		this.Number('shop_id').CharWidth(10).SqlType('bigint');
		this.String('name');
	}
}

export const MLM_PACKAGE_DISP_META = new MLM_PACKAGE_DISP_META_CLASS();

export interface IDetailToPost { //:type
	__KEY: string;
	__DELETED?: boolean;
}

export class PublicError extends Error {
	public isPublic : true; 
	public status : number;
	constructor(message: string, options?: { status?: number, args?: string[] } | number | Array<any>) {
		let args : string[] = [];
		let status = 400;
		if (typeof options == 'number') {
			status = options;
		} else if (options && Array.isArray(options) ) {
			args = options;
		} else if (options) {
			status = (options as any).status || status;
			args = (options as any).args || args;
		}
		super(wsPrintf(message, args));
		this.status = status;
		this.isPublic = true;
	}
}

export function wsPrintf(message : string, args : any[]) {
	return message.replace(/{(\d+)}/g, function (match, number) {
		return typeof args[number] != 'undefined'
			? args[number]
			: match
			;
	});
}


export interface IEnumItem<T extends string = string> {
	KOD : T;
	MEGN : string;
}
export type IEnumList<T extends string = string> = IEnumItem<T>[];

export type IgenNem = 'I'|'N'; //:type
export const IgenNemList : IEnumList = [
	{KOD:'I', MEGN:'Igen'},
	{KOD:'N', MEGN:'Nem'},
]

export type TQueryParamBool = '0'|'1';
export type TQueryParams = {[key:string]:string|undefined};
export interface WebSysListQueryParams extends TQueryParams {
	page? : string,
	pageSize? : string,
}

export interface WebSysListResp {
	rowCount : number;
	pageSize : number;
	pageCount : number;
	page: number;
}

export class ValidationError extends PublicError {
	constructor(msg: string) {
		super(msg);
	}

}


export interface IPostResp {
	result : 'OK' | 'CONFIRM'
	confirm_text?: string;
	confirm_key?: string;
}

// ------------------------------------------------- FILTERs -----------------------------------------------------------


export interface WsFilterGroup {
	title: string;
	default?: boolean;
	value: any;
}

export interface WsFilter {
	title: string;
	name: string;
	filters: WsFilter[];
}


// ------------------------------------------------- TYPES -----------------------------------------------------------
export type TKeszletmozgasTipus = 'B'|'E'|'S'; //:type


// ------------------------------------------------- KAPCS -----------------------------------------------------------

export type KAPCS_TYPE = 'FELADAT' | 'FORG_FEJ' | 'SZREND_FEJ' | 'KESZ_MOZG' | 'CIKK' | 'GYKESZ_FEJ'; //:type

export interface IKapcsSzamla {
	KAPCSOLO : number;
	BIZONYLAT : string;
	SZALLEV : string;
	SZLASZ : string;
	HIVSZ : string;
	TELJDAT : Date;
	P_KOD : string;
	P_NEV : string;
}

export interface IKapcsAjanlat {
	KAPCSOLO: number;
	RENDELES : string;
	P_KOD: string;
	P_NEV: string;
	STATUS: string;
}

export interface IKapcsCikk {
	ID: number;
	CIKK_KOD : string;
	NEV : string;
	NEV2 : string;
	MEE : string;
	LISTA : string;
	LISTABR : string;
	ELSZ : string;
	CSOMEGYS : string;
	SZIN : string;
}

export interface IKapcsKeszaruBevet {
	KAPCSOLO: number;
	BIZONYLAT : string;
	CIKK_NEV : string;
	BIZDAT : Date;
	MEGJ : string;
}

export interface IKapcsKeszletMozgas {
	KAPCSOLO: number;
	BIZONYLAT : string;
	MOZ_KOD : string;
	TELJDAT : Date;
	HIVSZ : string;
	P_KOD : string;
	P_NEV : string;
	NETTO : number;
	MEGJ : string;
	MEGJ2 : string;
}

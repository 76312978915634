import { ClassValidator, Column, isDateTimeColumn, isNumberColumn, isReferenceColumn } from "@coimbra-its/websys-lib";
import { ReactNode } from "react";

import { formatDate, formatDateTime } from "../utils";
import { IWebSysFormRow } from "./Form";

export type IEntity = {[name : string] : any};
export type WsAlign = 'left'|'right'|'center';

export interface CellProps<T extends IEntity> {
	elem: (row: T ) => ReactNode;
	field?: keyof T & string;
	title?: string;
	width?: string;
	charWidth?: number;
	align?: WsAlign;
	cardNoCaption?: boolean;
}

export interface FormCellProps<T extends IEntity> extends Omit<CellProps<T>, 'elem'> {
	elem: (row: IWebSysFormRow<T> ) => ReactNode;
}



export class TableMeta<TEntity extends IEntity> extends ClassValidator<TEntity> {
	protected cells = new Map<keyof TEntity & string, CellMetaProp<TEntity, any>>();
	constructor(from? : ClassValidator<TEntity>) {
		super(from);
		this.columns.forEach((column, cn) => {
			let cliProp = new CellMetaProp(column);
			if (isDateTimeColumn(column)) {
				if (column.subKind === 'DateOnly')
					cliProp.value = (value) => formatDate(value)
				else
					cliProp.value = (value) => formatDateTime(value)
			} 
			if (isReferenceColumn(column)) {
				switch(column.refType) {
					case 'MN' :
						cliProp.showRef/*<IMN>*/((ref:any) => ref.MEGN); //TODO
						break;

				}
			} 
			if (isNumberColumn(column))
				cliProp.props.align = "right";
				
			this.cells.set(cn, cliProp);
		});
	}

	private _forTable : any; //cache
	public forTable() : CellProps<TEntity>[] {
		//return Array.from(this.cells.values()).map(col => col.asCellBase());
		return this._forTable || (this._forTable = Array.from(this.cells.values()).map(col => col.asCellBase()));
	}

	public Only(names : (keyof TEntity)[]) : TableMeta<TEntity> {
		this.cells.forEach((cell, name) => {
			if (names.indexOf(name)===-1)
				this.cells.delete(name);
		});
		return this;
	}
	public Delete(name : keyof TEntity & string) : TableMeta<TEntity> {
		this.cells.delete(name);
		return this;
	}
	public GetCell(name : keyof TEntity & string) : CellMetaProp<TEntity, any> {
		let cell = this.cells.get(name);
		if (!cell) throw `cell ${name} not found`;
		return cell;

	}
	public Map(name : keyof TEntity & string, mapFn : (val : any)=>ReactNode) : TableMeta<TEntity> {
		this.GetCell(name).value = mapFn;
		return this;
	}
	public Map2(name : keyof TEntity & string, mapFn : (row : TEntity)=>ReactNode) : TableMeta<TEntity> {
		this.GetCell(name).cell = mapFn;
		return this;
	}
	public Align(name : keyof TEntity & string, align : WsAlign) : TableMeta<TEntity> {
		this.GetCell(name).props.align = align;
		return this;
	}


	public Set(name: keyof TEntity & string, props : Partial<CellProps<TEntity>>) : TableMeta<TEntity> {
		this.GetCell(name).setProps(props);
		return this;
	}

}



// ============================================== PROPERTIES ==============================================

export class CellMetaProp<TEntity extends IEntity, TColumn>  {
	public value : (value: any) => ReactNode = (value)=>value;
	public cell : (row: TEntity) => ReactNode = (row)=>this.value(row[this.column.name]);
	public props : Partial<CellProps<TEntity>> = {
		align : 'left',
		//wsImportant : false,
		//hideOnCard : false
	}
	public asCellBase() : CellProps<TEntity> {
		return {
			...this.props,
			field: this.column.name,
			title : (this.column.caption || this.column.name),
			elem : this.cell,
			charWidth : this.column.charWidth
		}
	}
	public showRef<TRef>(fn : (row : TRef) => ReactNode)  {
		this.cell = (row) => (fn(row as any as TRef) || row[this.column.name]);
	}
	public setProps(props : Partial<CellProps<TEntity>>) {
		this.props = {...this.props, ...props};
	}
	constructor(protected column : Column<TEntity, TColumn>) {
		
	}
}


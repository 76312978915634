
import { 
	BsCheck as BsCheck2Square, //TODO
	BsSquare 
} from 'react-icons/bs';
import { FaArrowLeft, FaArrowRight, FaBox, FaCircle, FaExclamationCircle, FaTasks, FaTruck, FaUser, FaWarehouse, FaFilter } from 'react-icons/fa';
import {
	MdAccountTree,
	MdAddCircleOutline,
	MdArrowBack,
	MdArrowForward,
	MdAttachFile,
	MdCancel,
	MdClose,
	MdCloudUpload,
	MdCreditCard,
	MdEdit,
	MdEmail,
	MdFileDownload,
	MdFileOpen,
	MdFilter as MdFilterAlt, MdFilter as MdFilterAltOff, //TODO
	MdHome,
	MdInsertDriveFile,
	MdLogout,
	MdMenu,
	MdNewspaper,
	MdPhone,
	MdSave,
	MdScheduleSend,
	MdSearch,
	MdSettings,
	MdShoppingCart,
	MdTrendingUp,
	MdWallet
} from 'react-icons/md';
import { RiCloseCircleFill } from 'react-icons/ri';

export const APPICONS = {
	SignOut : MdLogout,
	Close : MdClose,
	Back : MdArrowBack,
	Cancel : MdCancel,
	Prev : MdArrowBack,
	Next : MdArrowForward,
	Menu : MdMenu,
	Save : MdSave,
	Edit : MdEdit,
	Search : MdSearch,
	Filter : FaFilter,
	FilterOff : MdCancel,
	Create : MdAddCircleOutline,
	Delete : RiCloseCircleFill,
	DeleteRow : MdClose,
	Home : MdHome,
	Preferences : MdSettings,
	Alert : FaExclamationCircle,
	Left : FaArrowLeft,
	Right : FaArrowRight,
	User : FaUser,
	Attach : MdAttachFile,
	Upload : MdCloudUpload,
	Settings : MdSettings,
	Check : BsCheck2Square,
	CheckOff : BsSquare,

	Phone: MdPhone,
	Email: MdEmail,


	Chart: MdTrendingUp,
	Shop: MdShoppingCart,
	Wallet: MdWallet,
	CreditCard: MdCreditCard,
	Transfer: MdScheduleSend,
	News: MdNewspaper,
	Tree: MdAccountTree,
	Pfd: MdFileOpen,


	/*Partner : FaUser,
	Paper : MdInsertDriveFile,
	Item : FaCircle,
	Raktar : FaWarehouse,
	Task : FaTasks,
	KeszletMozgas : FaTruck,
	Article : FaBox,
	Tasks : FaTasks,*/
}
import { ClassValidator } from '@coimbra-its/websys-lib';

export interface IMLM_USER {

	id : number;

	email : string;

	password : string;

	name : string;

	mother_name : string;

	country : string;

	zipcode : string;

	city : string;

	street : string;

	street_number : string;

	id_number : string;

	photo_id_number : string;

	account_number : string;

	parent_user_id : number;

	sponsor_user_id : number;

	edge_index : number;

	sponsor_edge_index : number;

	preferred_edge_index : number;

	total_purchased_power : number;

	created_date : Date;

	secret_question : string;

	secret_answer : string;

	user_type : string;

	security_level : number;

	business_package_id : number;

	education_package_id : number;

	education_purchase_date : Date;

	token : string;

	status : string;

	lang : string;

	bank_name : string;

	swift_bic : string;

	iban : string;

	subdomain : string;

	subdomain_created_date : Date;

	subdomain_transmission_date : Date;

	temp_parent_user_id : number;

	temp_sponsor_user_id : number;

	temp_edge_index : number;

	left_bv : number;

	left_w : number;

	left_child_count : number;

	left_modification_date : Date;

	right_bv : number;

	right_w : number;

	right_child_count : number;

	right_modification_date : Date;

	left_unclosed_bv : number;

	right_unclosed_bv : number;

	payout_token : string;

	payout_date : Date;

	payout_comment : string;

	payout_target : string;

	tac_accept_date : Date;

	person : string;

	company_name : string;

	tax_number : string;

	repr_sex : string;

	repr_first_name : string;

	repr_last_name : string;

	repr_date_of_birth : Date;

	repr_place_of_birth : string;

	repr_photo_id_number : string;

	repr_tax_number : string;

	repr_email : string;

	repr_phone : string;

	repr_country : string;

	repr_city : string;

	repr_zipcode : string;

	repr_street : string;

	repr_street_number : string;

	repr_secret_question : string;

	repr_secret_answer : string;

	repr_nationality : string;

	repr_newsletter_lang : string;

	highest_education_level : string;

	language_skill_1 : string;

	language_skill_2 : string;

	job : string;

	sector : string;

	investment_experience : string;

	interests : string;

	comment : string;

	sex : string;

	first_name : string;

	last_name : string;

	date_of_birth : Date;

	place_of_birth : string;

	phone : string;

	nationality : string;

	confirmed_date : Date;

	is_profile_editable : number;

	main_position_id : number;

	has_executive_level_publication : number;

	is_updated : number;

	rc_accept_date : Date;

	da_accept_date : Date;

	temp_email : string;

	newsletter_lang : string;

	left_unclosed_24_bv : number;

	left_24_w : number;

	right_unclosed_24_bv : number;

	right_24_w : number;

	iwallet_account_number : string;

	dcma_country : string;

	dcma_city : string;

	dcma_zipcode : string;

	dcma_street : string;

	dcma_street_number : string;

	dcma_status : number;

	dcma_order_date : Date;

	peak_card_id : number;

	peak_cardholder_id : string;

	left_smm : number;

	right_smm : number;

	crypto_account_number : string;
}

export class MLM_USER_META_CLASS extends ClassValidator<IMLM_USER> {
	constructor() {
		super() 
		this.Number('id').Key().CharWidth(10).SqlType('bigint');
		this.String('email').CharWidth(50).SqlType('varchar');
		this.String('password').CharWidth(50).SqlType('varchar');
		this.String('name').CharWidth(50).SqlType('varchar');
		this.String('mother_name').CharWidth(50).SqlType('varchar');
		this.String('country').CharWidth(20).SqlType('varchar');
		this.String('zipcode').CharWidth(20).SqlType('varchar');
		this.String('city').CharWidth(50).SqlType('varchar');
		this.String('street').CharWidth(50).SqlType('varchar');
		this.String('street_number').CharWidth(50).SqlType('varchar');
		this.String('id_number').CharWidth(4).SqlType('varchar');
		this.String('photo_id_number').CharWidth(50).SqlType('varchar');
		this.String('account_number').CharWidth(50).SqlType('varchar');
		this.Number('parent_user_id').CharWidth(10).SqlType('bigint');
		this.Number('sponsor_user_id').CharWidth(10).SqlType('bigint');
		this.Int('edge_index').CharWidth(10).SqlType('int');
		this.Int('sponsor_edge_index').CharWidth(10).SqlType('int');
		this.Int('preferred_edge_index').CharWidth(10).SqlType('int');
		this.Int('total_purchased_power').CharWidth(10).SqlType('int');
		this.DateTime('created_date').CharWidth(10).SqlType('datetime');
		this.String('secret_question').CharWidth(50).SqlType('varchar');
		this.String('secret_answer').CharWidth(50).SqlType('varchar');
		this.String('user_type').CharWidth(7).SqlType('enum');
		this.Int('security_level').CharWidth(10).SqlType('int');
		this.Number('business_package_id').CharWidth(10).SqlType('bigint');
		this.Number('education_package_id').CharWidth(10).SqlType('bigint');
		this.DateTime('education_purchase_date').CharWidth(10).SqlType('datetime');
		this.String('token').CharWidth(10).SqlType('varchar');
		this.String('status').CharWidth(5).SqlType('enum');
		this.String('lang').CharWidth(1).SqlType('varchar');
		this.String('bank_name').CharWidth(50).SqlType('varchar');
		this.String('swift_bic').CharWidth(50).SqlType('varchar');
		this.String('iban').CharWidth(50).SqlType('varchar');
		this.String('subdomain').CharWidth(50).SqlType('varchar');
		this.DateTime('subdomain_created_date').CharWidth(10).SqlType('datetime');
		this.DateTime('subdomain_transmission_date').CharWidth(10).SqlType('datetime');
		this.Number('temp_parent_user_id').CharWidth(10).SqlType('bigint');
		this.Number('temp_sponsor_user_id').CharWidth(10).SqlType('bigint');
		this.Int('temp_edge_index').CharWidth(10).SqlType('int');
		this.Number('left_bv').CharWidth(10).SqlType('decimal');
		this.Int('left_w').CharWidth(10).SqlType('int');
		this.Int('left_child_count').CharWidth(10).SqlType('int');
		this.DateTime('left_modification_date').CharWidth(10).SqlType('datetime');
		this.Number('right_bv').CharWidth(10).SqlType('decimal');
		this.Int('right_w').CharWidth(10).SqlType('int');
		this.Int('right_child_count').CharWidth(10).SqlType('int');
		this.DateTime('right_modification_date').CharWidth(10).SqlType('datetime');
		this.Number('left_unclosed_bv').CharWidth(10).SqlType('decimal');
		this.Number('right_unclosed_bv').CharWidth(10).SqlType('decimal');
		this.String('payout_token').CharWidth(10).SqlType('varchar');
		this.DateTime('payout_date').CharWidth(10).SqlType('datetime');
		this.String('payout_comment').CharWidth(50).SqlType('varchar');
		this.String('payout_target').CharWidth(4).SqlType('enum');
		this.DateTime('tac_accept_date').CharWidth(10).SqlType('datetime');
		this.String('person').CharWidth(4).SqlType('enum');
		this.String('company_name').CharWidth(50).SqlType('varchar');
		this.String('tax_number').CharWidth(50).SqlType('varchar');
		this.String('repr_sex').CharWidth(3).SqlType('enum');
		this.String('repr_first_name').CharWidth(20).SqlType('varchar');
		this.String('repr_last_name').CharWidth(20).SqlType('varchar');
		this.DateTime('repr_date_of_birth').CharWidth(10).SqlType('datetime');
		this.String('repr_place_of_birth').CharWidth(50).SqlType('varchar');
		this.String('repr_photo_id_number').CharWidth(20).SqlType('varchar');
		this.String('repr_tax_number').CharWidth(50).SqlType('varchar');
		this.String('repr_email').CharWidth(50).SqlType('varchar');
		this.String('repr_phone').CharWidth(10).SqlType('varchar');
		this.String('repr_country').CharWidth(20).SqlType('varchar');
		this.String('repr_city').CharWidth(50).SqlType('varchar');
		this.String('repr_zipcode').CharWidth(20).SqlType('varchar');
		this.String('repr_street').CharWidth(20).SqlType('varchar');
		this.String('repr_street_number').CharWidth(5).SqlType('varchar');
		this.String('repr_secret_question').CharWidth(50).SqlType('varchar');
		this.String('repr_secret_answer').CharWidth(50).SqlType('varchar');
		this.String('repr_nationality').CharWidth(50).SqlType('varchar');
		this.String('repr_newsletter_lang').CharWidth(1).SqlType('varchar');
		this.String('highest_education_level').CharWidth(6).SqlType('enum');
		this.String('language_skill_1').CharWidth(20).SqlType('varchar');
		this.String('language_skill_2').CharWidth(20).SqlType('varchar');
		this.String('job').CharWidth(7).SqlType('enum');
		this.String('sector').CharWidth(11).SqlType('enum');
		this.String('investment_experience').CharWidth(6).SqlType('enum');
		this.String('interests').CharWidth(10).SqlType('enum');
		this.String('comment').CharWidth(50).SqlType('varchar');
		this.String('sex').CharWidth(3).SqlType('enum');
		this.String('first_name').CharWidth(20).SqlType('varchar');
		this.String('last_name').CharWidth(20).SqlType('varchar');
		this.DateTime('date_of_birth').CharWidth(10).SqlType('datetime');
		this.String('place_of_birth').CharWidth(50).SqlType('varchar');
		this.String('phone').CharWidth(10).SqlType('varchar');
		this.String('nationality').CharWidth(50).SqlType('varchar');
		this.DateTime('confirmed_date').CharWidth(10).SqlType('datetime');
		this.Number('is_profile_editable').CharWidth(10).SqlType('tinyint');
		this.Number('main_position_id').CharWidth(10).SqlType('bigint');
		this.Number('has_executive_level_publication').CharWidth(10).SqlType('tinyint');
		this.Number('is_updated').CharWidth(10).SqlType('tinyint');
		this.DateTime('rc_accept_date').CharWidth(10).SqlType('datetime');
		this.DateTime('da_accept_date').CharWidth(10).SqlType('datetime');
		this.String('temp_email').CharWidth(50).SqlType('varchar');
		this.String('newsletter_lang').CharWidth(1).SqlType('varchar');
		this.Number('left_unclosed_24_bv').CharWidth(10).SqlType('decimal');
		this.Int('left_24_w').CharWidth(10).SqlType('int');
		this.Number('right_unclosed_24_bv').CharWidth(10).SqlType('decimal');
		this.Int('right_24_w').CharWidth(10).SqlType('int');
		this.String('iwallet_account_number').CharWidth(50).SqlType('varchar');
		this.String('dcma_country').CharWidth(20).SqlType('varchar');
		this.String('dcma_city').CharWidth(50).SqlType('varchar');
		this.String('dcma_zipcode').CharWidth(20).SqlType('varchar');
		this.String('dcma_street').CharWidth(50).SqlType('varchar');
		this.String('dcma_street_number').CharWidth(50).SqlType('varchar');
		this.Int('dcma_status').CharWidth(10).SqlType('int');
		this.DateTime('dcma_order_date').CharWidth(10).SqlType('datetime');
		this.Int('peak_card_id').CharWidth(10).SqlType('int');
		this.String('peak_cardholder_id').CharWidth(20).SqlType('varchar');
		this.Number('left_smm').CharWidth(10).SqlType('decimal');
		this.Number('right_smm').CharWidth(10).SqlType('decimal');
		this.String('crypto_account_number').CharWidth(50).SqlType('varchar');
	}
}

export const MLM_USER_META = new MLM_USER_META_CLASS();



// TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const PRIMARY_MAIN = '#2c6195';
export const PRIMARY_MAIN_TRANSP = '#2c619591';
export const HEADER_BG = '#9caaba66'
export const ACCENT_HEADER_BG = 'yellow.50';


export const LAYOUT_TRANSITION_MS = 300
export const LAYOUT_TRANSITION = `${LAYOUT_TRANSITION_MS}ms ease-out`;
export const LAYOUT_PAD_TRANSITION = '.1s linear';

export const LAYOUT_ISMOBILE_MIN_WIDTH_PX = 800;
export const LAYOUT_LEFT_NAV_WIDTH_PX = 64;
export const LAYOUT_COLUMN_WIDTH_PX = 240;
export const LAYER_PAD = 40;
export const HEADER_HEIGHT_PX = 52;


export const WSYS_HIDE_SCROLLBARS_CSS = {
	'msOverflowStyle': 'none',  // IE and Edge 
	'scrollbarWidth': 'none',  // Firefox 
	'&::-webkit-scrollbar': {
		display: 'none',
	},
};

export const WSYS_TABLE_HOVER_STYLE = { bg: 'blackAlpha.50'  }
export const WSYS_TABLE_SELECTION_STYLE = {	bg: 'var(--chakra-colors-blue-600)', color: 'var(--chakra-colors-white)', _hover:{ bg: 'var(--chakra-colors-blue-500)' }}


export interface IPopupStyle {
	layer: any;
	page: any;
	panel: any;
	header: any;
}


export const WS_PAGE_STYLES: { [key: string]: IPopupStyle } = {
	default: {
		layer: {
			border: `3px solid ${PRIMARY_MAIN}`,
			//outline: `4px solid ${PRIMARY_MAIN_TRANSP}`,
		},
		page: {
		},
		panel: {
			bg: '#fff'
		},
		header: {
		}
	},
	accent: {
		layer: {
			outline: '4px solid #ffffc4b8',
			border: '1px solid #000',
			//			border: `2px solid ${PRIMARY_MAIN}`,
			//			outline: `4px solid ${PRIMARY_MAIN_TRANSP}`,			
		},
		page: {
			//bg: '#fff'
		},
		panel: {
			bg: '#ffffe1'
		},
		header: {
			borderBottom: '2px solid #0000007d',
			bg: ACCENT_HEADER_BG
		}
	}
}

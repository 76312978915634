import { Box, Radio, RadioGroup, Select } from "@chakra-ui/react";
import { IMLM_USER, MLM_USER_META } from "@coimbra-its/api.sunmoney.net";
import { useWSysAuthSlice } from "../../store/authSlice";
import { useAdminSunmoneyNetApi } from "../../store/sunmoney.net.api";
import { IWebSysFormRow, IWSysHub, useWebSysForm, WSysField, WSysGrid, WSysInput, WSysInputDate, WSysPageNode } from "@coimbra-its/websys-ux-core";
import { useCallback } from "react";
import { APPICONS } from "../../Icons";
import { skip } from "node:test";


type formRefType = ReturnType<typeof useWebSysForm<{USER:IMLM_USER}>>;

export function useProfileForm(isActive: boolean, hub: IWSysHub) {
	const user = useWSysAuthSlice(s => s.user);
	const id = user?.id || 0;
	const api = useAdminSunmoneyNetApi();
	const [data] = api.userIdGet.useData({ id }, { skip: (id <= 0) || !isActive, hub }, );
	const userIdPost = api.userIdPost.useAction();

	// ---- form ----
	const formRef = useWebSysForm<{ USER: IMLM_USER }>({
		isCreate: false, data
	});

	const fej = formRef.useRow({
		extract: data => data.USER,
		meta: MLM_USER_META,
	})	

	const onSave = useCallback(() => {
		hub.tryCatch(async () => {
			await userIdPost({ id, USER: fej.formRow$.formRow.data });
		});
	}, []);

	const onCancel = useCallback(() => {
		//page.close();
	}, [])
	const formTools = formRef.useTools(onSave, onCancel);

	return {formRef, formTools, fej};
}



export function ProfileForm({ fej, isEdited }: { fej: IWebSysFormRow<IMLM_USER>, isEdited: boolean}) {

	const user = useWSysAuthSlice(s => s.user);
	const id = user?.id || 0;


	return <Box>
		<WSysGrid>
			<WSysField formRow={fej} field="first_name"><WSysInput /></WSysField>
			<WSysField formRow={fej} field="last_name"><WSysInput /></WSysField>
		</WSysGrid>

		<WSysField formRow={fej} field="sex">
			<Select isDisabled={!isEdited}>
				<option>Male</option>
				<option>Female</option>
			</Select>
		</WSysField>

		<WSysGrid>
			<WSysField formRow={fej} field="date_of_birth"><WSysInputDate /></WSysField>
			<WSysField formRow={fej} field="place_of_birth"><WSysInput /></WSysField>
		</WSysGrid>

		<WSysField formRow={fej} field="email" icon={APPICONS.Email}><WSysInput /></WSysField>
		<WSysField formRow={fej} field="phone" icon={APPICONS.Phone}><WSysInput /></WSysField>
		<WSysGrid>
			<WSysField formRow={fej} field="country"><WSysInput /></WSysField>
			<WSysField formRow={fej} field="city"><WSysInput /></WSysField>
		</WSysGrid>
		<WSysGrid cols={[1, 4, 1]} minCols={2}>
			<WSysField formRow={fej} field="zipcode"><WSysInput /></WSysField>
			<WSysField formRow={fej} field="street"><WSysInput /></WSysField>
			<WSysField formRow={fej} field="street_number"><WSysInput /></WSysField>
		</WSysGrid>

	</Box>
}
/* https://www.syncfusion.com/forums/158248/sticky-scrollbar */

import { Box, BoxProps } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { WSYS_HIDE_SCROLLBARS_CSS } from "./Layout.TODO";


export interface WSysSticykBoxProps extends Omit<BoxProps, 'title'> {
    title?: React.ReactNode;
    footer?: React.ReactNode;
    head?: React.ReactNode;
//    mode?: WSysVertGroupMode;
    inLine?: boolean;
    childMinHeight?: string;
}


export function WSysStickyBox({ head, title, footer, children, inLine=false, childMinHeight, ...rest }: WSysSticykBoxProps) {

    // ---- for synchronized horizontal scrolling -----
    const tableRef = useRef<any>();
    const tableHeadRef = useRef<any>();
    const scrollerRef = useRef<any>();

    // ---- for setting height ----
    const titleRef = useRef<any>();
    const footerRef = useRef<any>();



    const onTableBotScroll = (e: React.UIEvent<HTMLElement>) => {
        //console.log(e.currentTarget.scrollLeft);
        if (tableRef.current) tableRef.current.scrollLeft = e.currentTarget.scrollLeft;
        if (tableHeadRef.current) tableHeadRef.current.scrollLeft = e.currentTarget.scrollLeft;
        if (scrollerRef.current) scrollerRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }

    const [scrollWidthPx, setScrollWidthPx] = useState(0);
    const scrollBarHeight = 18; //TODO
    //console.log('scrollBarHeight', scrollBarHeight)

    useEffect(() => {
        if (tableRef.current)
            setScrollWidthPx(tableRef.current.scrollWidth);
    }, [tableRef.current?.scrollWidth]);





    return <Box className='wsys-sticky'

        {...!inLine && {flex:'1' , minHeight:'100%' }}
        display='flex' flexDir='column' 
        {...rest}
    >
        {title && <Box className='wsys-sticky-title'
            flex='0 0 auto'
            position='sticky' 
            ref={titleRef}
            top='0px'
        >
            {title}
        </Box>}
        {head && <Box className='wsys-sticky-head'
            flex='0 0 auto'
            ref={tableHeadRef} onScroll={(e) => onTableBotScroll(e)}
            overflowX='scroll'
            position='sticky' 
            zIndex={1}
            style = {{
                top: (title && titleRef.current) ? titleRef.current.clientHeight+'px':0
            }}            
            css={{ ...WSYS_HIDE_SCROLLBARS_CSS } as any}
        >
            {head}
        </Box>}
        <Box className='wsys-sticky-body'
            flex='1'
            ref={tableRef} onScroll={(e) => onTableBotScroll(e)}
            overflowX='scroll'
            minHeight={childMinHeight}
            css={{ ...WSYS_HIDE_SCROLLBARS_CSS } as any}
        >
            {children}
        </Box>

        <Box className='wsys-sticky-scroller-container' ref={scrollerRef} onScroll={(e) => onTableBotScroll(e)}
            flex='0 0 auto'
            style={{ 
                height: scrollBarHeight + 'px', overflowX: 'scroll', overflowY: 'hidden', 
                position: 'sticky', 
                bottom: (footer && footerRef.current) ? footerRef.current.clientHeight+'px':0 
            }}
        >
            <Box className='wsys-sticky-scroller' style={{ width: scrollWidthPx + 'px' }}></Box>
        </Box>

        {footer && <Box className='wsys-sticky-footer'
            flex='0 0 auto'
            position='sticky' bottom={0} 
            ref={footerRef}
        >
            {footer}
        </Box>}        
    </Box>

}


import { Box, BoxProps, Fade, FadeProps, useBoolean, useToken } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HEADER_HEIGHT_PX, LAYER_PAD, LAYOUT_COLUMN_WIDTH_PX, LAYOUT_ISMOBILE_MIN_WIDTH_PX, LAYOUT_LEFT_NAV_WIDTH_PX, LAYOUT_PAD_TRANSITION, LAYOUT_TRANSITION } from "./Layout.TODO";
import { LayoutLog, WSysLayoutContext, WSysLayoutManager, WSysPageNode } from "./LayoutManager";
import { debounce, useWSysPreferences } from "./utils";

import { IWSysCorePreferences, useWSysCoreSlice } from "../store/coreSlice";
import { WS_PAGE_STYLES } from "./Layout.TODO";
import { WSysLog, WSysLogSeverity } from "./utils.log";

const logCalc = WSysLog.asSource({ name: 'LAYOUT', dark: '#7ee', light: '#7ee' }, (sev) => sev >= WSysLogSeverity.INFO 	|| useWSysCoreSlice.getState().layout.dumpLayoutCalc	);
const logToken = WSysLog.asSource({ name: 'TOKEN', dark: '#faa', light: '#faa' }, (sev) => sev >= WSysLogSeverity.INFO 	|| useWSysCoreSlice.getState().layout.dumpFormTokens	);


// ====================================================== LAYER =======================================================
class WSysLayer {

	pages = new Array<WSysPageNode>();

	visible: boolean = true;
	visibleCols: number = 0;
	scrolledOutCols: number = 0;
	leftCols = 0; // pozició alapból (csak popup, full = 0)
	pushLeftCols = 0; // pozició, ha el kell tolnom hogy a fölötte megjelenő popup mögött látszon az <input.autocomplete> 
	toCenterLeftCols = 0; // eltolás középretoláshoz

	maxLayerCount = 0; // mennyi layerrel létezett együtt valaha.. ez határozza meg a padPx vékonyságát, hogy ne "pumpáljon" vissza, ha becsuknak fölötte dolgokat
	padPx = 0;
	maxHeightPx = 0;
	pageWidthPx = 0;

	isClosing = false; // záródik, ha minden page-e záródik
	isBehindEdited: 'full' | 'no' | number = 'no'; // teljesen, nem, vagy részben: a takart page-ek száma. TODO!! kellez??
	firstVisiblePage?: WSysPageNode; // TODO: kell ez?


	constructor(public layerKey: string, public kind: 'full' | 'popup') { }
}


// ====================================================================== WEBSYS LAYOUT ======================================================================
export interface WSysLaypropsOut extends BoxProps {
	nav?: React.ReactNode;
}

export function WSysLayout({ children, nav, ...rest }: WSysLaypropsOut) {
	LayoutLog.debug(`===  render LAYOUT ===`)();



	// ---------------- handle window width / resize --------------------------
	const getDimensions = () => {
		const isMobileFull = window.innerWidth <= LAYOUT_ISMOBILE_MIN_WIDTH_PX;
		const windowWidthPx = window.innerWidth - (isMobileFull ? 0 : LAYOUT_LEFT_NAV_WIDTH_PX);
		const windowHeightPx = window.innerHeight;
		const windowWidthCols = Math.max(Math.floor(windowWidthPx / LAYOUT_COLUMN_WIDTH_PX), 1);
		return { isMobileFull, windowWidthPx: windowWidthPx, windowHeightPx, windowWidthCols }
	};

	const [isMobileFull, setIsMobileFull] = useState(getDimensions().isMobileFull);
	const [windowWidthPx, setWindowWidthPx] = useState(getDimensions().windowWidthPx);
	const [windowHeightPx, setWindowHeightPx] = useState(getDimensions().windowHeightPx);
	const [windowWidthCols, setWindowWidthCols] = useState(getDimensions().windowWidthCols);
	useEffect(() => {

		const handleResize = debounce(() => {
			let dims = getDimensions();
			setIsMobileFull(dims.isMobileFull);
			setWindowWidthPx(dims.windowWidthPx);
			setWindowHeightPx(dims.windowHeightPx);
			setWindowWidthCols(dims.windowWidthCols);

		}, 500);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, []);

	const _preferences = useWSysPreferences();
	const [prefResp, setRespPref] = useState<IWSysCorePreferences>({ ..._preferences });
	useEffect(() => {
		if (windowWidthCols >= 3) {
			setRespPref({ ..._preferences });
		} else {
			setRespPref({
				..._preferences,
				modeMain: 'COLUMNS',
				modeAttach: 'COLUMNS',
				modePopup: 'COLUMNS'
			});
		}
	}, [_preferences, windowWidthCols]);




	// ===========================================
	const layersRef = useRef<WSysLayer[]>([]);
	const [pagesArr, setPagesArr] = useState(new Array<WSysPageNode>());
	const [shrink, setShrink] = useBoolean(false);


	// ======================================================================================== CALC =====================================	
	const calc = useCallback((reason: string, roots: Array<WSysPageNode>, nodesByKey: Map<string, WSysPageNode>) => {
		LayoutLog.info('Layout calc because: ', reason)();

		const pagesArrForward = new Array<WSysPageNode>();
		const addAll = (nodes: Array<WSysPageNode>) => {
			if (!nodes || !Array.isArray(nodes))
				debugger;
			let arr = [...nodes];
			arr.sort((a, b) => a.propsIn.order - b.propsIn.order)
			for (let n of arr) {
				pagesArrForward.push(n);
				addAll(n.propsOut.childPages);
			}
		}
		addAll(roots);



		if (pagesArrForward.length === 0)
			return;

		let pagesArrBackward = [...pagesArrForward].reverse();

		// ----------------------------------------------- ROUND #1 -----------------------------------------
		// ---------------- ha több gyereke van nyitva valakinek csak az utolsó jelenik meg (?? TODO) ----------------
		{
			let hasOpenChildAttach = new Set<string>();
			let hasOpenChildPopup = new Set<string>();
			let isBehindEdited = false;
			for (let page of pagesArrBackward) {

				page.propsOut.isBehindEdited = isBehindEdited;
				page.propsOut.visibleOnLayer = 'visible';
				page.propsOut.isParentVisible = true;

				if (page.propsIn.variant === 'attach' /*&& !page.isClosing*/) {
					if (hasOpenChildAttach.has(page.propsIn.parentKey || 'root')) {
						page.propsOut.visibleOnLayer = 'hidden-child';
					} else {
						hasOpenChildAttach.add(page.propsIn.parentKey || 'root');
					}
				}
				if (page.propsIn.variant === 'popup' /*&& !page.isClosing*/) {
					if (hasOpenChildPopup.has(page.propsIn.parentKey || 'root')) {
						page.propsOut.visibleOnLayer = 'hidden-child';
					} else {
						hasOpenChildPopup.add(page.propsIn.parentKey || 'root');
					}
				}
				if (page.propsIn.isEdited)
					isBehindEdited = true;
			}
		}


		// ----------- hátulról előre -> beállíom a page-eket milyen mode-ban jelenjenek meg (lastonly|columns|popups) ----
		// - Kitalálom a layereket 
		// - Kitalálom a layer isBehindEdited-jét

		let newLayers = new Array<WSysLayer>();

		{
			// az első page (leghátsó) megy az első layerre, biztos full layerKind-ban:
			let currLayer = new WSysLayer(pagesArrForward[0].propsOut.key, 'full');
			pagesArrForward[0].propsOut.mode = 'LASTONLY';
			pagesArrForward[0].propsOut.layerKey = currLayer.layerKey;

			let prevPage = pagesArrForward[0];
			currLayer.pages.push(prevPage);

			logCalc.debug(' ***** ', prevPage.propsIn.title)();


			prevPage.propsOut.layerKind = 'full';


			//  a továbbiak a beállítások szerint váltanak mode-ot és az alapján kerülnek új layer-re: 
			for (let page of pagesArrForward.slice(1)) {
				let pageMode = prefResp.modeMain;
				const isChildOfPrev = prevPage && prevPage.propsOut.childPages.indexOf(page) >= 0
				if (isChildOfPrev) {
					if (page.propsIn.variant === 'attach') pageMode = prefResp.modeAttach;
					else if (page.propsIn.variant === 'popup') pageMode = prefResp.modePopup;
				}
				logCalc.debug(' ** ', page.propsIn.title, ' isChildOfPrev ', isChildOfPrev)();

				page.propsOut.mode = pageMode;

				if (pageMode === 'POPUPS') {
					newLayers.push(currLayer);
					currLayer = new WSysLayer(page.propsOut.key, 'popup');
					currLayer.pages.push(page); page.propsOut.layerKey = currLayer.layerKey;
				} else if (pageMode === 'COLUMNS') {
					currLayer.pages.push(page); page.propsOut.layerKey = currLayer.layerKey;
				} else if (pageMode === 'LASTONLY') {
					newLayers.push(currLayer);
					currLayer = new WSysLayer(page.propsOut.key, 'full');
					currLayer.pages.push(page); page.propsOut.layerKey = currLayer.layerKey;
				}

				page.propsOut.layerKind = currLayer.kind;
				prevPage = page;
			}
			newLayers.push(currLayer);
		}

		// elrejtjük az utolsó full mögötti layereket
		{
			let isVisible = true;
			[...newLayers].reverse().forEach(layer => {
				layer.visible = isVisible;
				if (layer.kind === 'full')
					isVisible = false;
			});
		}

		// - set layer closing 
		newLayers.forEach(layer => {
			layer.isClosing = true;
			for (let page of layer.pages) {
				if (!page.propsOut.isClosing)
					layer.isClosing = false;
			}
		});


		// itt:
		// - meg vannak a layer-ek és hogy az full vagy popup és minden page, ami rajta van.
		// - nincs meg semminek a szélessége, eltolása ilyesmi, csak egy array of arrays.

		// --------------------------------------- belövöm a layer-ek és page-ek szélességét (col-ban) --------------------- 
		for (let layer of newLayers) {

			// beállítom a layerekre is a behindEdited-et
			let fullBehind = false;
			let fullInFront = false;
			let behindCount = 0;
			for (let page of layer.pages) {
				if (page.propsOut.isBehindEdited) {
					behindCount++;
					fullInFront = false;
				} else {
					fullBehind = false;
				}
			}
			if (fullBehind)
				layer.isBehindEdited = 'full';
			else if (fullInFront)
				layer.isBehindEdited = 'no'
			else
				layer.isBehindEdited = behindCount;



			let remainingCols = windowWidthCols;
			let currLayerBehindEdited = false; // figyelem! több isEdited is lehet egymás után megnyitva, ez az akt layerre vonatkozik.

			for (let page of [...layer.pages].reverse()) { // indulok visszafelé (jobbról-balra) a page-eken
				if (page.propsOut.visibleOnLayer === 'hidden-child' || (page.propsOut.isClosing && !layer.isClosing))
					continue;


				// "isEdited" utániakat kitoljuk balra
				if (currLayerBehindEdited) {
					page.propsOut.visibleOnLayer = 'scrolled-out';
					layer.scrolledOutCols += page.propsOut.widthCols;
				}
				// ha még belefér a látómezőbe
				else if (remainingCols >= page.propsIn.minCols || remainingCols == windowWidthCols) {

					let widthCols = Math.min(remainingCols, page.propsIn.maxCols);
					page.propsOut.widthCols = widthCols;
					page.propsOut.visibleOnLayer = 'visible';
					remainingCols -= widthCols;
					layer.visibleCols += widthCols;
				}
				// ha már nem
				else {
					page.propsOut.visibleOnLayer = 'scrolled-out';
					remainingCols = 0;
					layer.scrolledOutCols += page.propsOut.widthCols;
				}

				if (page.propsIn.isEdited)
					currLayerBehindEdited = true;
			}
		}




		// ----------------------------------------------- ROUND #2 -----------------------------------------
		// ha a layer egy popup, akkor megpróbálom úgy mutatni, hogy látszon az alatta levő triggerelő oszlop és a popup is egymás mellett

		let dir: 'left' | 'right' = 'right';
		for (let layerIx in newLayers) {
			let layer = newLayers[layerIx];
			layer.pushLeftCols = 0;
			layer.toCenterLeftCols = 0;
			layer.leftCols = 0;

			if (layer.kind === 'full') //|| layer.isClosing
				continue;

			let pushToKeepTriggerVisible = !layer.isClosing && layer.pages[0].propsIn.variant === 'popup'; // lehet main is, ami popup-ban jelenik meg a preferences miatt
			let decision = '?';
			let prevDir = dir;

			const prevLayer = newLayers[parseInt(layerIx) - 1];
			let prevLeft = prevLayer.leftCols;
			let prevWidth = prevLayer.visibleCols;
			let leftFreeCols = prevLeft;
			let rightFreeCols = windowWidthCols - prevLeft - prevWidth;
			const popupWidth = layer.pages[0].propsOut.widthCols;

			const triggerColKey = layer.pages[0].propsIn.parentKey;
			logCalc.debug('TRIGGERED BY:', triggerColKey)();
			let triggerPage: WSysPageNode | undefined;
			let triggerLeftCols = 0;
			let triggerRightCols = 0;
			for (let col of prevLayer.pages.filter(c => c.propsOut.visibleOnLayer === 'visible')) {
				if (col.propsOut.key == triggerColKey) {
					triggerPage = col;
					continue;
				}
				if (triggerPage)
					triggerRightCols += col.propsOut.widthCols;
				else
					triggerLeftCols += col.propsOut.widthCols;
			}
			/* TODO: nem működik jól, ha nem találja az "előző" (triggerPage) ablakot, ami nyitotta (branches)
			prevLeft = prevLayer.leftCols + triggerLeftCols;
			if (triggerPage) // valami átmeneti bezáródáskori állapotban lehet, hogy null
				prevWidth = triggerPage.propsOut.widthCols;
			leftFreeCols += triggerLeftCols;
			rightFreeCols += triggerRightCols;
			*/

			let forceRight = layer.pages[0].propsIn.variant === 'attach'; // popup módban is jobbra próbáljuk rakni mert attach
			if (forceRight) {
				pushToKeepTriggerVisible = true;
				dir = 'right';
			}

			if (dir === 'right') {
				if (rightFreeCols >= layer.visibleCols) { // beférek jobbra
					decision = 'jobbra befér';
					layer.leftCols = prevLeft + prevWidth;
					logCalc.debug('?????', prevLeft, prevWidth, triggerLeftCols)();
				} else if (leftFreeCols >= layer.visibleCols && !forceRight) { // beférek balra -> visszapattanunk
					decision = 'balra befér';
					dir = 'left';
					layer.leftCols = prevLeft - layer.visibleCols;
				} else if (rightFreeCols >= leftFreeCols || forceRight) { //jobbra van nagyobb hely -> beprésel -> visszapattan
					decision = 'jobbra nagyobb';
					dir = 'left';
					layer.leftCols = windowWidthCols - layer.visibleCols;
					if (forceRight && rightFreeCols < popupWidth)
						prevLayer.pushLeftCols = rightFreeCols - popupWidth;
				} else { //balra van nagyobb hely -> beprésel -> megint jobbra
					decision = 'balra nagyobb';
					layer.leftCols = 0;
					if (pushToKeepTriggerVisible && leftFreeCols < popupWidth)
						prevLayer.pushLeftCols = popupWidth - leftFreeCols;
				}
			} else { // walking left
				if (leftFreeCols >= layer.visibleCols) { // beférek balra
					decision = 'balra befér';
					layer.leftCols = prevLeft - layer.visibleCols;
				} else if (rightFreeCols >= layer.visibleCols) { // beférek jobbra -> visszapattanunk
					decision = 'jobbra befér';
					dir = 'right';
					layer.leftCols = prevLeft + prevWidth;
				} else if (leftFreeCols >= rightFreeCols) { //balra van nagyobb hely -> beprésel -> visszapattan
					decision = 'balra nagyobb';
					dir = 'right';
					layer.leftCols = 0;
					if (pushToKeepTriggerVisible && leftFreeCols < popupWidth)
						prevLayer.pushLeftCols += popupWidth - leftFreeCols;
				} else { //jobbra van nagyobb hely -> beprésel -> megint jobbra
					decision = 'jobbra nagyobb';
					layer.leftCols = windowWidthCols - layer.visibleCols;
				}
			}
			if (triggerPage && layer.visibleCols < leftFreeCols && layer.visibleCols < rightFreeCols)
				triggerPage.propsOut.visibleOnLayer = 'covered';

			logCalc.debug('... tologat', layer.pages[0].propsIn.title, 'prevDir:', '"'+prevDir+'"', ` freeCols :  ${leftFreeCols}  <-?-> ${rightFreeCols} `, ' decision: ', '"'+decision+'"', ' left:', layer.leftCols, ' newDir: ', '"'+dir+'"')();
		}


		// ----------------------------------------------- ROUND #3 -----------------------------------------


		// ---------- középretolás ----------
		if (_preferences.layoutAlign === 'CENTER') {
			for (let ix = 0; ix < newLayers.length; ix++) {
				let maxWidthCols = 0;
				for (let iy = 0; iy <= Math.min(newLayers.length - 1, ix + 1); iy++) { // a leghátsótól az  eggyel elötte lévőig 
					let layer = newLayers[iy];
					if ((iy < ix && !layer.visible)) //
						continue;
					if (iy > ix && (layer.kind == 'full' || layer.isClosing))
						continue;
					if (iy <= ix)
						maxWidthCols = Math.max(maxWidthCols, Math.min(windowWidthCols, layer.visibleCols + layer.leftCols + layer.pushLeftCols));
					else
						maxWidthCols = Math.max(maxWidthCols, Math.min(windowWidthCols, layer.visibleCols + layer.leftCols));

					newLayers[ix].toCenterLeftCols = (windowWidthCols - maxWidthCols) / 2;
				}
			}
		}

		// ---------- pageWidthPx / padPx beállítása ----------
		let visibleIx = 0;
		for (let ix in newLayers) {

			const layer = newLayers[ix];
			const oldLayer = layersRef.current.find(l => l.layerKey === layer.pages[0].propsOut.key);
			//console.log('... finding old layer', layer.pages[0].key, '  but old layers:  ', Array.from(layersRef.current.keys()).join(', '));
			let size = newLayers.filter(l => l.visible).length;
			if (oldLayer && oldLayer.maxLayerCount > size)
				size = oldLayer.maxLayerCount;
			layer.maxLayerCount = size;
			layer.padPx = visibleIx * (LAYER_PAD / size);
			layer.maxHeightPx = window.innerHeight - 2 * layer.padPx;
			layer.pageWidthPx = (windowWidthPx - (layer.padPx * 2 * .8)) / windowWidthCols;
			if (layer.visible)
				visibleIx++;

			for (let page of layer.pages) {
				page.propsOut.maxHeightPx = layer.maxHeightPx;
				page.propsOut.widthPx = layer.pageWidthPx * page.propsOut.widthCols;
			}

		}



		// - isHiddenEdited: A formTools miatt kell: ha a form főablaka nem látszik, akkor az alányílókra kell a tools-t rakni.
		// - check if full layer closing
		newLayers.forEach(layer => {
			layer.firstVisiblePage = layer.pages.find(c => c.propsOut.visibleOnLayer === 'visible');

			let hiddenEdited = false;
			for (let page of layer.pages) {
				page.propsOut.isHiddenEdited = hiddenEdited;
				if (page.propsIn.isEdited && page.propsOut.visibleOnLayer !== 'visible')
					hiddenEdited = true;
			}
		});

		pagesArrForward.forEach(page => {
			//console.log(page.visibleOnLayer, page.layer?'van':'nincs');
			let layer = newLayers.find(l => l.layerKey === page.propsOut.layerKey);
			page.propsOut.isVisible = !!(layer?.visible) && (page.propsOut.visibleOnLayer === 'visible');
		})

		const tokens = new Map<string, string>();
		const tokenMainPage = new Map<string, WSysPageNode>();
		pagesArrForward.forEach(page => {
			const parent = page.propsIn.parentKey && pagesArrForward.find(c => c.propsOut.key == page.propsIn.parentKey);
			page.propsOut.isParentVisible = !!parent && parent.propsOut.isVisible;
			if (page.propsIn.toolTokens) {
				logToken.debug('TOKENs IN:', '"'+page.propsIn.title+'"',  page.propsIn.toolTokens)();
				page.propsIn.toolTokens.forEach(token => {

					let isMainPage = !tokenMainPage.has(token);
					if (isMainPage)
						tokenMainPage.set(token, page);
					if (page.propsOut.isVisible && (isMainPage || !page.propsOut.isClosing))
						tokens.set(token, page.propsOut.key);
				});
			}
		});

		logToken.debug('TOKENS: ', Array.from(tokens.entries()).map(t => t[0] + '->' + t[1]).join('  ;   '))();

		// ---------- toolTokens ---------
		pagesArrForward.forEach(page => {
			let showToolTokens = '';
			if (page.propsIn.toolTokens) {
				page.propsIn.toolTokens.forEach(token => {
					const main = tokenMainPage.get(token);
					if (tokens.get(token) === page.propsOut.key)
						showToolTokens += token + (((main === page) || main?.propsOut.isVisible) ? '_MAIN' : '_ATTACH');
				});
			}
			logToken.debug('SHOWWW', page.propsOut.key, showToolTokens)();
			page.propsOut.showToolTokens = showToolTokens;
		});


		// ---- finalize ----

		layersRef.current = newLayers;
		setTrigger({});


		logCalc.debug("===========================================================")();
		newLayers.forEach((layer) => {
			logCalc.debug("--------------", layer.pages[0].propsIn.title, " --------------------- ")();
			logCalc.debug(" -- kind: ", layer.kind, 'leftCols:', layer.leftCols, '(scroll:'+layer.scrolledOutCols+'; center:' + layer.toCenterLeftCols + '; push:' + layer.pushLeftCols+')', ' visileCols', layer.visibleCols )();
			for (let page of layer.pages) {
				logCalc.debug(page.propsIn.title, 'mode', '"'+page.propsOut.mode+'"')();
			}

		});
		logCalc.debug("===========================================================")();

	}, [windowWidthPx, windowHeightPx, prefResp]); // ------------------ end of Calculate -------------

	const [trigger, setTrigger] = useState<object>({});

	const [manager] = useState(new WSysLayoutManager());
	manager.setCalculate(calc);
	const ctx = useMemo(() => ({ manager, isMobileFull, widthCols: windowWidthCols }), [manager, isMobileFull, windowWidthCols]);

	// -------------------------------------------- RENDER -------------------------------------------
	// -------------------------------------------- RENDER -------------------------------------------
	// -------------------------------------------- RENDER -------------------------------------------
	const layers = layersRef.current;
	let backDropLayerKey: string | null = null;
	let backDropLayerKeyCandidate: string | null = null;

	for (let layer of [...layers].reverse()) {
		
		if (backDropLayerKeyCandidate && layer && layer.visible && !layer.isClosing) {
			backDropLayerKey = backDropLayerKeyCandidate;
			break;
		}

		for (let col of layer.pages) {
			if (col.propsIn.isEdited && layer && !layer.isClosing) {
				backDropLayerKeyCandidate = layer.layerKey;
				break;
			}
		}
	}

	const scrollTransition = true;

	///-------------------- TODO -----------------
	const onPageClick = (clickedPageKey: string, e: React.MouseEvent<HTMLElement>) => {
	};

	const WSYS_LINE_COLOR = useToken('colors', 'wsysLine');

	return <WSysLayoutContext.Provider value={ctx}>
		<Box className='wsys-portal' overflow='hidden' w='100vw' h='100vh' display='flex'  >
			{nav && <Box className='wsys-nav-container' w={(isMobileFull ? 0 : LAYOUT_LEFT_NAV_WIDTH_PX ) + 'px'} 
				bg={_preferences.colors.menu.value} 
				overflow='hidden' >
				{nav}
			</Box>}

			<Box className='wsys-portal-main' flex='1' position='relative' >
				<Box className="wsys-layer-header-placeholder" borderBottom={`4px solid ${WSYS_LINE_COLOR}`} 
					//bg='#fff' 
					height={`${HEADER_HEIGHT_PX}px`}
					position='absolute' left={0} right={0} top={0}
				></Box>

				{layers.map((layer, layerIx)  => {
					//const layerIx = Array.from(layers.keys()).reverse().findIndex(k => k == layer.layerKey);
					const leftCols = layer.leftCols + layer.pushLeftCols + layer.toCenterLeftCols;
					
					return <FadeIf  transition={{...(layerIx>0)&&{ enter: {duration: 0}}}}  in={layer.visible && !layer.isClosing} unmountOnExit={false} key={layer.layerKey}   >
						{layer.layerKey == backDropLayerKey && <Fade in={true} unmountOnExit={true}>
							<Box className='layer-overlay' position='fixed' left={0} right={0} top={0} bottom={0} bg='#7777' backdropFilter='blur(1px)'
								style={{ zIndex: layerIx * 2 }}
							/>
						</Fade>}
						<Box key={layerIx} className='wsys-layer-outer'
							position='absolute' style={{ zIndex: layerIx * 2 }}
							h={layer.maxHeightPx + 'px'} w='100%' overflow='hidden'
							left={0}

							{...scrollTransition && {
								transition: `width ${LAYOUT_TRANSITION}, top ${LAYOUT_PAD_TRANSITION}, bottom ${LAYOUT_PAD_TRANSITION}, left ${LAYOUT_TRANSITION} `
							}}

							{...layer.kind === 'popup' && {
								...WS_PAGE_STYLES[layer.firstVisiblePage?.propsIn?.wsStyle || 'default'].layer,
								top: layer.padPx + 'px',
								bottom: layer.padPx + 'px',
								h: layer.maxHeightPx + 'px',
								width: (layer.visibleCols * layer.pageWidthPx) + 'px',
								boxShadow: layer.pages.length>1?'dark-lg':'lg',
								borderRadius: 'md',
								left: leftCols * layer.pageWidthPx + layer.padPx + 'px',
							}}
							{...rest}
						>
							<Box
								className='wsys-layer-scroll'
								position='relative' overflowY='hidden'
								//width={((leftCols + layer.scrolledOutCols + layer.visibleCols  ) * layer.pageWidthPx) + 'px'} outline='1px solid red'
								w='1000vw'
								h={layer.maxHeightPx + 'px'}
								display='flex'
								left={(0 + (-1 * layer.scrolledOutCols * layer.pageWidthPx)) + 'px'}
								{...scrollTransition && { transition: `left ${LAYOUT_TRANSITION}` }}
							>
								{layer.kind == 'popup' && <Box className="wsys-layer-header-placeholder" borderBottom={`4px solid ${WSYS_LINE_COLOR}`} 
									//bg='#fff' 
									height={`${HEADER_HEIGHT_PX}px`}
									position='absolute' left={0} right={0} top={0}
									{...{ borderBottomWidth: '2px' }}
								></Box>}
								{layer.pages.map(page => {
									// ------------------------------ PAGE-CONTAINER ------------------
									return <FadeIf isFade={false} key={page.propsOut.key} unmountOnExit={false}
										in={(['visible', 'scrolled-out'].indexOf(page.propsOut.visibleOnLayer) >= 0) && !layer.isClosing
											&& (!page.propsOut.isClosing || layer.visibleCols >= windowWidthCols)}
										style={{ zIndex: layerIx * 2 }}
									>
										<Box className="wsys-page-outer wsys-set-portal-height-full"
											onClick={e => onPageClick(page.propsOut.key, e)}
											flex='0 0 auto'
											h={(layer.maxHeightPx - 4) + 'px'} //TODO!!! -4 ???
											position='relative' overflow='hidden'
											width={`${page.propsOut.widthCols * layer.pageWidthPx}px`}
											{...scrollTransition && { transition: `width ${LAYOUT_TRANSITION}, margin-left ${LAYOUT_TRANSITION}` }}

											{...(layer.kind != 'popup' && layer.firstVisiblePage == page) && { ml: (leftCols * layer.pageWidthPx) + 'px' }}
											{...WS_PAGE_STYLES[page.propsIn.wsStyle].page}

											display='flex' flexDir='column' border='0px solid purple	'

											ref={(elem) => manager.setPagePortal(page.propsOut.key, elem)}
										>

											


										</Box>
									</FadeIf>
								})}

							</Box>

						</Box></FadeIf>
				})}
			</Box>
		</Box>
		<Box bg='red' >{children}</Box>
	</WSysLayoutContext.Provider>
}

export interface FadeIfProps extends FadeProps {
	isFade?: boolean;
}

export function FadeIf ({ isFade=true, children, ... rest} : FadeIfProps) {
	return isFade 
		? <Fade {...rest} >{children}</Fade>
		: <>{children}</>
}
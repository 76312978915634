import { ADMIN_SUNMONEY_NET_ROUTES, SUNMONEY_NET_ROUTES } from "@coimbra-its/api.sunmoney.net";
import { WSysApi } from "@coimbra-its/websys-ux-core";
import { createContext, useContext } from "react";
import { useSessionId } from "./authSlice";

export const SunmoneyNetApi = createContext(new WSysApi(process.env.REACT_APP_API_BASE_URL + '' || '', SUNMONEY_NET_ROUTES,
    headers => {
        const sessionId = useSessionId();
        (headers as any)['authorization'] = `Bearer ${sessionId}`;
    }
));


export function useSunmoneyNetApi() {
    const api = useContext(SunmoneyNetApi);
    return api.ep;
}



export const AdminSunmoneyNetApi = createContext(new WSysApi(process.env.REACT_APP_API_BASE_URL_ADMIN + '' || '', ADMIN_SUNMONEY_NET_ROUTES,
    headers => {
        const sessionId = useSessionId();
        (headers as any)['authorization'] = `Bearer ${sessionId}`;
    }
));


export function useAdminSunmoneyNetApi() {
    const api = useContext(AdminSunmoneyNetApi);
    return api.ep;
}
import { Box, Grid, GridItem, GridProps } from "@chakra-ui/react";
import { Children, useContext, useEffect, useState } from "react";

import { useWSysCoreSlice } from "../store";
import { IWSysLayoutContext, WSysLayoutContext } from "./LayoutManager";


export interface WSysGridProps extends GridProps {
	cols?: Array<number>;
	minCols?: number;
}

export function WSysGrid({ cols: pCols, minCols: pMinCols, children, gap: pGap, 
		//templateColumns: templateColumnsIn,
		...rest }: WSysGridProps) {

	const dump = useWSysCoreSlice(state => state.layout.dumpLayout);

	const gap = pGap === undefined ? 4 : pGap;
	const parentCtx = useContext(WSysLayoutContext);
	const arrayChildren = Children.toArray(children);
	const cols = pCols || [1, 1];
	const minCols = typeof pMinCols === 'number' ? pMinCols : cols.reduce((p, c) => p + c, 0);

	const [templateColumns, setTemplateColumns] = useState('1fr');
	const [collapsed, setCollapsed] = useState(true);
	const [childCtxs, setChildCtxs] = useState<Array<IWSysLayoutContext>>([parentCtx]);

	useEffect(() => {
		if (parentCtx.widthCols < minCols) {
			setTemplateColumns('1fr');
			setCollapsed(true);
		} else {
			setTemplateColumns(cols.map(c => `${c}fr`).join(' '));
			setCollapsed(false);
		}
	}, [parentCtx.widthCols]);

	useEffect(() => {
		if (collapsed) {
			setChildCtxs([parentCtx]);
			return;
		}
		let ctxs: Array<IWSysLayoutContext> = [];
		let sumFragment = cols.reduce((p, c) => p + c, 0);
		let colsLeft = 0;
		for (let col of cols) {
			let c = (parentCtx.widthCols / sumFragment) * col;
			ctxs.push({
				...parentCtx
				, widthCols: c
				//, columnsLeftVisible: parentCtx.columnsLeftVisible + colsLeft
			});
			colsLeft += c;
		}
		setChildCtxs(ctxs)
	}, [parentCtx, templateColumns, collapsed]);


	return <>
		{dump && <Box bg='navy' borderBottom='1px solid red' color='white'>{`"${templateColumns}"  min: ${minCols}  len: ${childCtxs.length} ${collapsed ? 'C+' : 'C-'}`}</Box>}
		<Grid className="wsys-grid" gap={gap} templateColumns={templateColumns} {...rest} >
			{Children.map(arrayChildren, (child, ix) => {
				const ctx = childCtxs[ix % (childCtxs.length)];
				if (typeof child === 'object' && (child as any).type === GridItem)
					return <WSysLayoutContext.Provider value={ctx} key={ix}>{child}</WSysLayoutContext.Provider>
				else
					return <WSysLayoutContext.Provider value={ctx} key={ix}><GridItem className="wsys-grid-item-auto" display='inline-block'>{child}</GridItem></WSysLayoutContext.Provider>
			})}
		</Grid>
	</>
}

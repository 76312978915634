
/*===================================================
------------------ ENDPOINT FIELD -------------------
=====================================================
Az endpoint típusa generikus WSysApiEndpoint<TRequest, TResponse>
ahol: TRequest amivel meg lehet hívni. pl:
{
	id: number;
	search: string;
}
TResponse meg a visszatérő érték

- TRequest-ben egybe mosom a param|query|payload helyeket, ezeket úgyis mind egyben át kell adni híváskor
- Szükségem van a natív típusokból álló TRequest/TResponse típusokra, mert ezeket fogom használni híváskor: call(req: TRequest) : TResponse
- Viszont szükségem van egy Field -listára is! Ezekhez tudok validátorokat, ellenőrzéseket rendelni.
- Ez úgy lett megoldva, hogy az Endpoint konstruktor-nak factory függvényt kell átadni:
	- ami egy olyan objektummal tér vissza, aminek minden eleme WSysEndpointField<TNatívtípus>
	- van egy Wrapper típus-definicióm:   az osztály minden eleme TNatívtípus-ból ->  WSysEndpointField<TNatívtípus>
	- ebből a typescript infer-eli az TRequest típust, egy menetben megcsinálom az api-t nem kell egyszer típusokat definiálnom, egyszer meg validátorokat hozzáadogatnom és a kettőt egyeztetnem:

export const MKVK_PM_ROUTES  = {
	szemelyTagNewList: new WSysEndpoint('/szemely-tag/new', 'GET',
						// ---  request factory -----
		req => ({		// builder
			q: req.query().str(),
			statusz: req.query().value<TPmStatuszNewFilter>(),
		}),   // visszatérek egy WSysEndpointField<TNatívtípus> -okból álló objetummal, abből a ts tudja a TRequest-et.
		res => ({
			LIST: res.payload().list<ISZEMELY_ALAPADAT_DISP>()
		})
	)
};

- két típuson keresztül építem fel a field-et, hogy biztosan legyen helye és típusa:
	- a builder elsőre 'WSysEndpointFieldUntyped' Field-eket csinál
	- az untyped függvényei adnak vissza WSysEndpointField-eket

	req => ({    	// req = builder object
		q: 			// a field neve
			req.query()			// a builder query|param|payload-ja untypedField-et ad vissza (request-nél fontos: tudom a helyét: param|query|payload)
			.str()				// az untyped .str|num|value<>|list|etc ... függvényei már WSysEndpointField<>-et adnak vissza
			
	- Így az egész 'factory' függvény egy objektumot ad vissza, aminek minden eleme WSysEndpointField<>
		- ebből a typescript ki tudja infer-elni a TRequest és TResponse típusát, használhatom késpőbb a híváskor, visszatéréskor
		- mégis saját függvényekkel építem fel, tehát adhatok hozzá validátorokat, paramétereket etc.

*/


// ---- field helye ----
export type TWSysEndpointRequestFieldPlace = 'param' | 'query' | 'payload';
// ---- field típusa ----
export type TWSysEndpointFieldType = 'string' | 'number' | 'list' | 'item' | 'bool';

// ---- 1.lépés: pl a builder.query() -még csak egy ilyet ad vissza, ennek még meg kell hívni valamelyik függvényét, hogy WSysEndpointField<> legyen
class WSysEndpointFieldUntyped {
	constructor(public kind: TWSysEndpointRequestFieldPlace) { }

	public str(): WSysEndpointField<string> {
		return new WSysEndpointField<string>(this.kind, 'string');
	}
	public idNum(): WSysEndpointField<number> {
		return new WSysEndpointField<number>(this.kind, 'number');
	}
	public num(): WSysEndpointField<number> {
		return new WSysEndpointField<number>(this.kind, 'number');
	}
	public value<T>(): WSysEndpointField<T> {
		return new WSysEndpointField<T>(this.kind, 'item');
	}
	public list<T>(): WSysEndpointField<Array<T>> {
		return new WSysEndpointField<Array<T>>(this.kind, 'list');
	}
	public bool(): WSysEndpointField<boolean> {
		return new WSysEndpointField<boolean>(this.kind, 'bool');
	}
}

// ---- 2.lépés : a végső field osztály ----
export class WSysEndpointField<TField> {
	constructor(public kind: TWSysEndpointRequestFieldPlace, public type: TWSysEndpointFieldType) {
	}
	public name: string = '';
	public default(v? : TField) : WSysEndpointField<TField|undefined> {
		return this as WSysEndpointField<TField|undefined>;
	};
}


export interface IWSysEndpointReqFieldBuilder {
	param<T>(): WSysEndpointFieldUntyped;
	query<T>(): WSysEndpointFieldUntyped;
	payload<T>(): WSysEndpointFieldUntyped;
}

export interface IWSysEndpointResFieldBuilder {
	payload<T>(): WSysEndpointFieldUntyped;
}

// ---- helper:   TRequest -ből   olyan osztályt definiál, aminek minden tagja field. pl.:   string -> WSysEndpointField<string> ----
export type TWSysEndpointFieldsWrapped<T> = {
	[P in keyof T]: WSysEndpointField<T[P]>;
};



// ============================================================
// ----------------------- WSysEndpoint -----------------------
// ============================================================
export class WSysEndpoint<TRequest, TResponse> {
	constructor(
		private _path: string,
		private _method: 'GET' | 'POST' | 'PUT' | 'DELETE',
		private _requestFactory: (a: IWSysEndpointReqFieldBuilder) => TWSysEndpointFieldsWrapped<TRequest>,
		private _responseFactory?: (a: IWSysEndpointResFieldBuilder) => TWSysEndpointFieldsWrapped<TResponse>
	) {
		// ----------- create request fields ---------------
		if (this._requestFactory) {
			const wrapped = this._requestFactory({ // a röptében előállított 'builder'-t adom át
				param: () => { return new WSysEndpointFieldUntyped('param') },
				query: () => { return new WSysEndpointFieldUntyped('query') },
				payload: () => { return new WSysEndpointFieldUntyped('payload') },
			});
			// a factory függvény meghívásával visszakaptam a field-listát
			//     elmentem a field-be a nevét is, azt csak így tudom
			Object.entries(wrapped).forEach(([key, value]) => {
				const field = (value as WSysEndpointField<any>);
				field.name = key;
				this.reqFields.push(field); // és elmentem, hogy tömbben legyenek meg a field-ek.
			});

		}

		// ----------- create response fields ---------------
		if (this._responseFactory) {
			const wrapped = this._responseFactory({
				payload: () => { return new WSysEndpointFieldUntyped('payload') },
			});
			Object.entries(wrapped).forEach(([key, value]) => {
				const field = (value as WSysEndpointField<any>);
				field.name = key;
				this.resFields.push(field);
			});

		}
	}

	public get path() { return this._path };
	public get method() { return this._method };

	public reqFields = new Array<WSysEndpointField<any>>();
	public resFields = new Array<WSysEndpointField<any>>();

	public get forReqType() : TRequest { return undefined as any }
	public get forResType() : TResponse { return undefined as any }

	// ============= pár helper, amit a call() -nál tudok használni =============
	// ---- menj végig a TRequest 'param'-okon ----
	public foreachReqParams(cb: (field: WSysEndpointField<any>) => void) {
		this.reqFields.forEach(field => {
			if (field.kind === 'param')
				cb(field);
		});
	}

	// ---- menj végig a TRequest 'query'-ken ----
	public foreachReqQuery(cb: (field: WSysEndpointField<any>) => void) {
		this.reqFields.forEach(field => {
			if (field.kind === 'query')
				cb(field);
		});
	}

	// ---- add vissza egy TRequest objektumból ami a 'payload'-ba való  ----
	public getRequestPayload(values: any): object | false {
		let p: object | false = false;
		this.reqFields.forEach(field => {
			if (field.kind === 'payload') {
				if (p === false)
					p = {};
				(p as any)[field.name] = values[field.name];
			}
		});
		return p;
	}

	public invalidatesTags : ((req : TRequest) => string[]) | undefined;
	public invalidates(cb: (req : TRequest) => string[]) {
		this.invalidatesTags = cb;
		return this;
	}

	public providesTags : ((req : TRequest) => string[]) | undefined;
	public provides(cb: (req : TRequest) => string[]) {
		this.providesTags = cb;
		return this;
	}

}

// tök jó lenne, de nem használható, onnantól valmiért minden any- lesz... valamit egyszerüsít a ts
//		interface IWSysEndpointList { [key: string]: WSysEndpoint<any, any> };


import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { Location, NavigateFunction, NavigateOptions, BrowserRouter as Router, useLocation, useNavigate } from "react-router-dom";
import { WSysLog, WSysLogSeverity } from "./utils.log";
import { useWSysCoreSlice } from "../store/coreSlice";


/*
TODO: 
+ useParam - boolean etc.
+ useChild('valami/összetett')
+ useChild('valam/:id/hello/:id2')
+ check special chars in 
    + param name
    + param value
    + path name
    - :id //nem kell egyelőre csak int-et tud
- Vissza gomb  // túl bonyi
+ params Map ot tároljuk string-ként is, úgy könnyű összehasonlítani és nem kell újragyártogatni.

*/


const PARAM_OPEN = '[';
const PARAM_CLOSE = ']';
const BRANCH_OPEN = '[';
const BRANCH_CLOSE = ']';

const log = WSysLog.asSource({ name: 'PATH', dark: '#645', light: 'pink' }, (sev) => sev >= WSysLogSeverity.INFO 
        || useWSysCoreSlice.getState().layout.dumpPath
    );

export interface IWSysPathContext {
    mgr: WSysPathClass;
    remaining: string;
    pathBefore: string;
    parent: WSysPathItem;
}

export const WSysPathContext = createContext<IWSysPathContext>({
    mgr: null as any,
    remaining: '',
    pathBefore: '',
    parent: null as any,
});

export interface WSysPathProps {
    group: number;
    disabled?: boolean;
}

// ============================================================== PATH ITEM ======================================================
export class WSysPathItem {
    constructor(
        private mgr: WSysPathClass,
        public parent: WSysPathItem | undefined,
        public pattern: string,
        public pathAfter: string,
        props?: WSysPathProps
    ) { 
        this.props = props || { group: 0 };
    }

    public props: WSysPathProps;

    public children: WSysPathItem[] = [];  // useChild() hook immediately adds this, holds all the children open or not. Order is the useChild() order 
    public openChildren: WSysPathItem[] = []; // array of open children. Source of truth for isOpen() and order. Order is the changing: last called open() is always jumps last.

    public slug: string = ''; // actual url-part found by pattern

    private _paramValues = new Map<string, string | boolean>();
    private _paramDefaults = new Map<string, string | boolean>(); // set by useParam() or useBool()
    private _paramsStr = ''; // urlEncoded string representation of params. Used to compare if params changed. (instead of compare two Map-s)

    // --------------------------------------- Handle Params --------------------------------------
    private _paramsToStr() {
        let str = '';
        this._paramValues.forEach((val, name) => {
            if (val !== this._paramDefaults.get(name)) {
                if (str)
                    str += '&'
                if (typeof val === 'string')
                    str += encodeURIComponent(name) + '=' + encodeURIComponent(val);
                else if (val)
                    str += encodeURIComponent(name)
            }
        });
        this._paramsStr = str;
        return str;
    }

    // ----------- useParam() calls it in every render cycle ----------
    public ensureParam(name: string, def: string | boolean) {
        if (this._paramDefaults.get(name) !== def) {
            this._paramDefaults.set(name, def);
            if (!this._paramValues.has(name))
                this._paramValues.set(name, def);
            this._paramsToStr();
        }
    }

    public setParam(name: string, val: string | boolean) {
        log.debug('setParam', val, 'def: ', this._paramDefaults.get(name))();
        this._paramValues.set(name, val);
        this._paramsToStr();
    }

    public getParam(name: string) {
        return this._paramValues.get(name);
    }

    // ----------- every render cycle calls it: check if url changed ----------
    private setParamsByStr(paramsStr: string) {
        if (this._paramsStr === paramsStr)
            return;
        let params = paramsStr.split('&');
        this._paramValues = new Map<string, string>();
        params.forEach(p => {
            let kv = p.split('=');
            let name = decodeURIComponent(kv[0]);
            let val: string | boolean = true;
            if (kv.length > 1) {
                val = decodeURIComponent(kv[1]);
            }
            this._paramValues.set(name, val);
        })
        log.debug('setParamsByStr', this._paramsStr, ' -> ', paramsStr)();
        this._paramsStr = paramsStr;
    }

    // ----------- every render cycle calls it if child is open ----------
    public ensureOpen(branchIx: number, slug: string, remainingPath: string, paramsStr: string) {
        this.slug = slug;
        if (this.parent) {
            let openIx = this.parent.openChildren.indexOf(this);
            if (openIx < 0)
                log.info('OPEN PATH ', '"' + this.slug + '"')();
            if (openIx >= 0 && openIx != branchIx) {
                this.parent.openChildren.splice(openIx, 1);
                openIx = -1;
            }
            if (openIx < 0)
                this.parent.openChildren.splice(branchIx, 0, this);
        }
        this.pathAfter = remainingPath;
        this.setParamsByStr(paramsStr);

    }

    // ----------- every render cycle calls it if child is closed ----------
    public ensureClosed() {
        if (this.parent) {
            let openIx = this.parent.openChildren.indexOf(this);
            if (openIx >= 0) {
                log.info('CLOSE PATH ', '"' + this.slug + '"')();
                this.parent.openChildren.splice(openIx, 1);
            }
        }
        this.slug = '';
        this._paramsStr = '';
        if (this._paramDefaults.size > 0) {
            this._paramDefaults = new Map<string, any>();
            this._paramValues = new Map<string, any>();
        }
        if (this.openChildren.length > 0)
            this.openChildren = [];
        if (this.children.length > 0)
            this.children = [];
    }

    // ------------------------------------------- recursively called and returns the remaining path of item and it's children ---------------------------------------
    public getSubPath(): string {
        let paramsStr = this._paramsStr;
        if (paramsStr)
            paramsStr = PARAM_OPEN + paramsStr + PARAM_CLOSE;

        const slug = this.slug.split('/').map(s => encodeURIComponent(s)).join('/');
        if (this.openChildren.length == 0) {
            return slug + paramsStr;
        }
        let subPathes = '';
        for (let i = 0; i < this.openChildren.length - 1; i++) {
            let sp = this.openChildren[i].getSubPath();
            subPathes += BRANCH_OPEN + sp + BRANCH_CLOSE;
        }
        if (subPathes)
            subPathes = '/' + subPathes;

        return slug + paramsStr + subPathes + '/' + this.openChildren[this.openChildren.length - 1].getSubPath();
    }

    public open(slug?: string) {
        if (slug)
            this.slug = slug;
        else
            this.slug = this.pattern;
        log.info('OPEN slug:', this.slug)();
        if (!this.parent)
            throw(`Can't open path '${slug}' on root!`)

        // --- close other children from same group ---
        const newOpenChildren = new Array<WSysPathItem>();
        this.parent.openChildren.forEach(item => {
            if (item.props.group != this.props.group) {
                newOpenChildren.push(item);
            }
        });
        // --- and adds this as latest opened at the end ---
        newOpenChildren.push(this);

        this.parent.openChildren = newOpenChildren;
        this.mgr.navigate();
    }

    public close() {
        log.debug('close ', '"' + this.slug + '"')();
        if (!this.parent)
            return;
        const openIx = this.parent.openChildren.indexOf(this);
        if (openIx >= 0)
            this.parent.openChildren.splice(openIx, 1);
        this.mgr.navigate();
    }

    public get order() {
        return this.parent?.openChildren.indexOf(this) || 0
    }
}

// ------------------------------- object in context: holds root child and useNavigate ----------------------------
export class WSysPathClass {
    private __root = new WSysPathItem(this, undefined, '', '');
    public get root() { return this.__root; }

    constructor(private _navigate: NavigateFunction, public location: Location) { };



    public navigate(options?: NavigateOptions) {
        let newPath = this.__root.getSubPath();
        if (newPath.endsWith(PARAM_CLOSE)) {
            newPath = newPath.substring(0, newPath.length - 1);
            let x = newPath.lastIndexOf(PARAM_OPEN);
            if (x >= 0) {
                newPath = newPath.substring(0, x) + '?' + newPath.substring(x + 1);
            }
        }


        log.info('NAVIGATE from ', '"' + this.location.pathname + '"', '  to   ', '"' + newPath + '"')();

        this._navigate(newPath, options);
    }
}



// ========================================== ROOT COMPONENT ==========================================
// provisions root context 
// use it in <App> root instead of <Router>
export function WSysPath({ children }: { children: React.ReactNode }) {
    return <Router><WSysPath0>{children}</WSysPath0></Router>
}

function WSysPath0({ children }: { children: React.ReactNode }) {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const [mgr] = useState(() => new WSysPathClass(navigate, location));
    mgr.location = location;

    //log.debug('-- set remaining: ', pathname)();
    const ctx = useMemo(() => ({
        mgr,
        parent: mgr.root,
        remaining: pathname + (search ? PARAM_OPEN + search.substring(1) + PARAM_CLOSE : ''),
        pathBefore: '',
    }), [pathname, search, mgr]);

    return <WSysPathContext.Provider value={ctx} >
        {children}
    </WSysPathContext.Provider>
}



// ========================================== USE PATH ==========================================
export function useWSysPath() {
    const parentCtx = useContext(WSysPathContext);

    // ensure we are inside <WSysPath> tags
    if (!parentCtx.mgr)
        throw new Error(`useWSysPath outside of <WSysPath>`);


    const RenderNode = (ctx: Partial<IWSysPathContext> | undefined, node: ReactNode) => {
        return ctx && <WSysPathContext.Provider value={{ ...parentCtx, ...ctx }}>
            {node}
        </WSysPathContext.Provider>
    }



    // ------------------------------------- SPLITS next part of remaining path into BRANCHES ------------------------------------
    // like:  /[child1[param=2]][child2]/child3
    //      is split into:  ["child1[param=2]",  "child2",  "child3"]
    const branches = new Array<string>();
    if (parentCtx.remaining) {
        let rem = parentCtx.remaining;
        if (rem.startsWith('/'))
            rem = rem.substring(1);
        while (rem.startsWith(BRANCH_OPEN)) {
            let depth = 0;
            for (let i = 1; i < rem.length; i++) {
                let char = rem[i];
                if (char == BRANCH_OPEN)
                    depth += 1;
                else if (char == BRANCH_CLOSE && depth > 0)
                    depth -= 1;
                else if (char == BRANCH_CLOSE) {
                    let p = rem.substring(1, i);
                    branches.push(p);
                    rem = rem.substring(p.length + 2);
                    break;
                }
            }
        }

        if (rem.startsWith('/'))
            rem = rem.substring(1);
        branches.push(rem);
        log.debug('branches from remaining: ', '"' + parentCtx.remaining + '"', ' branches: ', branches)();
    }

    const on = (pattern: string, node: React.ReactNode, props? : WSysPathProps) => {

        childIx++;
        let childItem = parentCtx.parent.children[childIx];
        if (!childItem) {
            log.debug('NEW CHILD ', '"' + pattern + '"')();
            childItem = parentCtx.parent.children[childIx] = new WSysPathItem(parentCtx.mgr, parentCtx.parent, pattern, '', props);
        }
        childItem.pattern = pattern;
        if (props)
            childItem.props = props;

        const { branchIx, slug, remainingPath, paramsStr } = checkPattern(branches, childItem.pattern);
        // -------------- handle if opened or closed --------------
        if (branchIx >= 0) { // ----  is open now
            childItem.ensureOpen(branchIx, slug, remainingPath, paramsStr);
        } else {
            childItem.ensureClosed();
        }
        if (branchIx >= 0) {
            return RenderNode({
                parent: childItem,
                remaining: childItem.pathAfter,
                pathBefore: parentCtx.pathBefore + '/' + childItem.slug,
            }, node);
        } else {
            return <></>;
        }
    }

    const elseOpen = (node: React.ReactNode, props? : WSysPathProps) => {
        if (parentCtx.parent.openChildren.length===0)
            return node;
    }


    // -------- react useChild order -------
    let childIx = 0;

    // ------------------------------------------------------------------------------------ useChild() --------------------------------------------
    const useChild = (pattern: string, props?: WSysPathProps) => {
        childIx++;
        let childItem = parentCtx.parent.children[childIx];
        if (!childItem) {
            log.debug('NEW CHILD ', '"' + pattern + '"')();
            childItem = parentCtx.parent.children[childIx] = new WSysPathItem(parentCtx.mgr, parentCtx.parent, pattern, '', props);
        }
        childItem.pattern = pattern;
        if (props)
            childItem.props = props;

        // -------------------------------------------- DO ACTUAL PATTERN MATCH against BRANCHES ----------------------------------
        {
            const { branchIx, slug, remainingPath, paramsStr } = checkPattern(branches, childItem.pattern);
            // -------------- handle if opened or closed --------------
            if (branchIx >= 0) { // ----  is open now
                childItem.ensureOpen(branchIx, slug, remainingPath, paramsStr);
            } else {
                childItem.ensureClosed();
            }
        }


        const openIx = parentCtx.parent.openChildren.indexOf(childItem);
        return {
            on: (node: ReactNode) => {
                if (parentCtx.parent.openChildren.indexOf(childItem) >= 0) {
                    return RenderNode({
                        parent: childItem,
                        remaining: childItem.pathAfter,
                        pathBefore: parentCtx.pathBefore + '/' + childItem.slug,
                    }, node);
                }


            },
            open: (slug?: string) => {
                childItem.open(slug);
            },
            close: childItem.close.bind(childItem),
            isOpen: openIx >= 0,
            slug: childItem.slug,
        }

    }

	const useChildRow = <TEntity, >(xPrefix: string, itemList: TEntity[] | undefined, extractId : (row : TEntity) => string|number) => {
		const prefix = xPrefix && !xPrefix.endsWith('/') ? xPrefix+'/' : xPrefix;
		const [selectedRow, setSelectedRow] = useState<TEntity | undefined>(undefined)
		const pathChild = useChild(prefix+':id', {disabled: !selectedRow, group: 0});
		const onRowSelect = (item: TEntity) => {
			pathChild.open(prefix + extractId(item));
		}
		const pathSelectedId = pathChild.slug.substring(prefix.length); 
		useEffect(() => {
			if (itemList && pathSelectedId) {
				setSelectedRow(itemList.find(s => extractId(s) == pathSelectedId))
			}
		}, [
			itemList, pathSelectedId
		]);
		useEffect(() => {
			if (!pathChild.isOpen && !!selectedRow)
				setSelectedRow(undefined);
		}, [pathChild.isOpen, !!selectedRow])
		return {
			...pathChild,
			forTable: {
				onRowSelect,
				selectedRow: selectedRow as TEntity,
			},
			pathSelectedId,
		}
	}

    const useParam = (name: string, def: string = ''): [string, (v: string) => void] => {
        parentCtx.parent.ensureParam(name, def);
        const setValue = (val: string) => {
            parentCtx.parent.setParam(name, val);
            parentCtx.mgr.navigate();
        }
        let value = parentCtx.parent.getParam(name);
        if (typeof value !== 'string')
            value = '';
        return [value, setValue];
    }

    const useParamValue = <T,>(name: string, def: T, encode: (val:T)=>string|boolean, decode :(p : string|boolean|undefined)=>T): [T, (v: T) => void] => {
        parentCtx.parent.ensureParam(name, encode(def));
        const setValue = (val: T) => {
            parentCtx.parent.setParam(name, encode(val));
            parentCtx.mgr.navigate();
        }
        let value = parentCtx.parent.getParam(name);
        return [decode(value), setValue];
    }    

    const useBool = (name: string, def: boolean = false): [boolean, (v: boolean) => void] => {
        parentCtx.parent.ensureParam(name, def);
        const setValue = (val: boolean) => {
            parentCtx.parent.setParam(name, val);
            parentCtx.mgr.navigate();
        }
        let value = parentCtx.parent.getParam(name);
        if (typeof value !== 'boolean')
            value = false;
        return [value, setValue];
    }

    const useNumber = (name : string, def : number) =>
        useParamValue<number>(name, def
            , val => ''+val
            , p => typeof p === 'string' ? parseInt(p) : def)
    


    //log.debug('... child order? ', parentCtx.parent.parent?.slug , '  ', parentCtx.parent.slug, parentCtx.parent.parent?.openChildren.indexOf(parentCtx.parent), '   ?  ', parentCtx.parent.parent?.openChildren.map(c => c.slug).join(', '))()
    const open = (slug: string) => {
        const child = parentCtx.parent.children.find(c => c?.pattern === slug);
        if (child) {
            child.open();
        } else {
            log.error('path.open()', '"' + slug + '"', ' not found in: ', parentCtx.parent.children.map(c => c?.pattern).join('; '))();
        }

    }



    return {
        order: parentCtx.parent.order,
        on, open, elseOpen,
        useChild, useChildRow,
        useParam, useParamValue, useBool, useNumber,
        ...parentCtx
    }
}








// ============================================================ HELPERS =======================================================


// -------------------------------------------- DO ACTUAL PATTERN MATCH against BRANCHES ----------------------------------
function checkPattern(branches: string[], pattern: string) {

    let patternParts = pattern.split('/');

    for (let bix in branches) {
        let slugParts = new Array<string>();
        let paramsStrParts = new Array<string>();

        const origPath = branches[bix];
        let path = origPath;
        let pathParts = path.split('/');
        if (patternParts.length > pathParts.length)
            continue;

        let ok = true;
        for (let pix in patternParts) {
            let patternPart = patternParts[pix];
            let pathPart = pathParts[pix];

            // cut the params if present
            let slug = pathPart; // pathPart without params
            let six = slug.indexOf(PARAM_OPEN);
            if (six > 0) {
                paramsStrParts.push(slug.substring(six + 1, slug.length - 1));
                slug = slug.substring(0, six);
            }
            slug = decodeURIComponent(slug);


            // ------------------ check :id  pattern --------------
            if (patternPart.startsWith(':')) {
                if (true || parseInt(slug) >= 0) {
                    slugParts.push(slug);
                } else {
                    ok = false;
                    break;
                }
                // ------------------ check normal pattern --------------
            } else {
                if (patternPart == slug) {
                    slugParts.push(slug);
                } else {
                    ok = false;
                    break;
                }
            }
        } // foreach patternPart
        if (ok) {
            let x = {
                branchIx: parseInt(bix),
                slug: slugParts.join('/'),
                remainingPath: pathParts.slice(patternParts.length).join('/'),
                paramsStr: paramsStrParts.join('&')
            }
            log.debug('(!) PATH MATCH ', pattern, ' in ', branches[x.branchIx], '    SLUG: ', x.slug, ' PARAMS: ', x.paramsStr, ' REMAINS: ', x.remainingPath)();
            return x;
        }
    }


    return { branchIx: -1, slug: '', remainingPath: '', paramsStr: '' }
}
import { WSysEndpoint } from "@coimbra-its/websys-lib";
import { IMLM_USER_PUB } from "./auto.interface/IMLM_USER_PUB.auto.interface";
import { IMLM_USER } from "./auto.interface/IMLM_USER.auto.interface";
import { IMLM_CHART } from "./auto.interface/IMLM_CHART.auto.interface";






export const ADMIN_SUNMONEY_NET_ROUTES = {

	// ===================================================================== USER ===============
	userGet: new WSysEndpoint('/user', 'GET',
		req => ({
			q: req.query().str(),
		}),
		res => ({
			USERS: res.payload().list<IMLM_USER_PUB>(),
		})
	)
		.provides(req => ['user']),

	userIdGet: new WSysEndpoint('/user/:id', 'GET',
		req => ({
			id: req.param().idNum(),
		}),
		res => ({
			USER: res.payload().value<IMLM_USER>(),
		})

	)
		.provides(req => [`user/${req.id}`]),

	userIdPost: new WSysEndpoint('/user/:id', 'POST',
		req => ({
			id: req.param().idNum(),
			USER: req.payload().value<IMLM_USER>(),
		}),
		res => ({
		})
	)
		.invalidates(req => [`user/${req.id}`]),


	chartPost: new WSysEndpoint('/chart', 'POST',
		req => ({ ITEM: req.payload().value<IMLM_CHART>() }),
		res => ({})
	).invalidates(req => [`chart`])
};



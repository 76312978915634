import { Box } from "@chakra-ui/react";
import { TableMeta, WSysPage, WSysSearch, WSysTable, WSysVert, useWSysPage, useWSysPath } from "@coimbra-its/websys-ux-core";

import { useAdminSunmoneyNetApi, useSunmoneyNetApi } from "../../store/sunmoney.net.api";
import { MLM_USER_PUB_META } from "@coimbra-its/api.sunmoney.net";
import { SunMainPage } from "../../components/Sun";
import { User } from "./User";

const CELLS = new TableMeta(MLM_USER_PUB_META).forTable();


export function UserList() {

	const path = useWSysPath();
	const [q] = path.useParam('q');

	const api = useAdminSunmoneyNetApi();
	const [data, hub] = api.userGet.useData({q});

	const pathDetail = path.useChildRow('', data?.USERS, s => s.id);

	// ---- page ----
	const page = useWSysPage({ title: "Users", maxCols: 4, onClosed: false })


	return <SunMainPage
		page={page}
		hubs={[hub]}
		tools={<Box display='flex' gap={2}>
			<WSysSearch name='q' />
		</Box>}
	>
		<WSysVert mode='rest'>

			<WSysTable
				rows={data?.USERS || []}
				cells={CELLS}
				{...pathDetail.forTable}

			/>
		</WSysVert>
		{pathDetail.on(<User id={pathDetail.forTable.selectedRow?.id||0} />)}
	</SunMainPage>

}


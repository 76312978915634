export enum WSysLogSeverity { DEBUG, INFO, WARNING, ERROR };
export interface IWSysLogSource  {
    name: string;
    dark: string;
    darkFont?: string;
    light: string;
}




function log(src : IWSysLogSource,  severity : WSysLogSeverity,  msgs : any[]) {
    let style0= 'color:#888;padding:2px;font-size:8px;';

    let color = src.darkFont || '#fff';
    let bg = src.dark;
    let fontWeight = 'bold';
    if (severity === WSysLogSeverity.DEBUG) {
        color = src.light;
        bg = 'inherit';
        fontWeight = 'normal';
    }

    let style1= `background:${bg};color:${color};font-weight:${fontWeight};padding:2px;`;

    let str = '%c%s%c';
    msgs.forEach(m => {
        switch (typeof m) {
            case 'object' : str += ' %o'; break;
            case 'number' : str += ' %d'; break;
            default : str += ' %s';
        }
    });
    return console.log.bind.apply(console.log, [console, str, style0, src.name + ' ' + WSysLogSeverity[severity],style1, ...msgs]);

}

export const WSysLog =  {


    
    asSource : (src : IWSysLogSource, check : (severity : WSysLogSeverity) => boolean) => {

        return {
            debug : (...msgs: any) => (check(WSysLogSeverity.DEBUG) ? log(src, WSysLogSeverity.DEBUG, msgs) : ()=>{}),
            info : (...msgs: any) => (check(WSysLogSeverity.INFO) ? log(src, WSysLogSeverity.INFO, msgs) : ()=>{}),
            warninig : (...msgs: any) => (check(WSysLogSeverity.WARNING) ? log(src, WSysLogSeverity.WARNING, msgs) : ()=>{}),
            error : (...msgs: any) => (check(WSysLogSeverity.ERROR) ? log(src, WSysLogSeverity.ERROR, msgs) : ()=>{}),
        }
    }


};








import { Box, DarkMode, Icon, IconButton, Img, Link, Menu, MenuButton, MenuItem, MenuList, Spacer, Stack, Text, Tooltip } from "@chakra-ui/react";
import { ICONS, WSysPreferences, useWSysPath } from "@coimbra-its/websys-ux-core";
import { NavLink } from "react-router-dom";
import { APPICONS } from "../Icons";
import { UserList } from "../admin/user/UserList";
import { Article } from "../features/Article";
import { Dashboard } from "../features/Dashboard";
import { SunChart } from "../features/SunChart";
import { Tree } from "../features/community/Tree";
import { Profile } from "../features/profile/Profile";
import { Shop } from "../features/shop/Shop";
import { useWSysAuthSlice } from "../store/authSlice";
import { IconType } from "react-icons";



export function AppHub() {
	const path = useWSysPath();

	return <>
		{path.on('profile', <Profile />)}
		{path.on('monthly-solar-royalty', <SunChart />)}
		{path.on('shop', <Shop />)}
		{path.on('article', <Article />)}
		{path.on('community/tree', <Tree />)}

		{path.on('users', <UserList />)}
		{path.on('preferences', <WSysPreferences />, { group: 99 })}
		{path.elseOpen(<Dashboard />)}
	</>
}

interface IMenuItem {
	caption: string, 
	to: string, 
	icon: IconType, submenu?: IMenuItem[]
}

export function WebSysNav({ isWide }: { isWide?: boolean }) {

	const authSlice = useWSysAuthSlice();
	const path = useWSysPath();
	const style = {
		fontSize: '28px', fontWeight: 'normal', css: { color: '#fffa' }
	}

	const items : Array<IMenuItem> = [
		{ caption: 'My account', to: '/profile/form', icon: APPICONS.User },
		{ caption: 'Monthly Solar Royalty', to: '/monthly-solar-royalty', icon: APPICONS.Chart },
		{
			caption: 'Webshop', to: '/shop', icon: APPICONS.Shop, /*submenu: [
				{ caption: 'Community package', to: '/shop/community-package', icon: APPICONS.Shop },
				{ caption: 'Solar package', to: '/shop/solar-package', icon: APPICONS.Shop },
				{ caption: 'SDBN package', to: '/shop/SDBN-package', icon: APPICONS.Shop },
				{ caption: 'Voucher', to: '/shop/voucher', icon: APPICONS.Shop },
			]*/
		},
		{ caption: 'Community tree', to: '/community/tree', icon: APPICONS.Tree }
	]

	return <><Box className="ws-nav" display="flex" flexDir="column" height='100%'>
		<DarkMode>

			<Stack mt={1} align={isWide ? 'left' : 'center'} height='100%' gap={3}>

				<Tooltip label='Nyitólap' ><Link as={NavLink} to='/'><Img width='48px' src='/sunmoney-logo-white.png' my={2} /></Link></Tooltip>
				{process.env.REACT_APP_STAGE !== 'production' && <Text textTransform='uppercase' bg='#b72929' color='#fff' p='1px 1em' fontSize='xs' borderRadius='md' >{(process.env.REACT_APP_STAGE || '???').substring(0, 3)}</Text>}

				{isWide
					? items.map((item, ix) => <Link key={ix} as={NavLink} to={item.to} display='flex' alignItems='center'>
						<Icon as={item.icon} {...style} />&nbsp;&nbsp;{item.caption}
					</Link>)

					: items.map((item, ix) => !item.submenu
						? <Tooltip key={ix} label={item.caption} ><Link as={NavLink} to={item.to}><Icon as={item.icon} {...style} /></Link></Tooltip>
						: <Menu key={ix}>
							<MenuButton as={IconButton} bg='transparent' ><Icon as={item.icon} {...style} /></MenuButton>
							<MenuList>
								{item.submenu.map((sub, ix) => <MenuItem key={ix} as={NavLink} to={sub.to}>
									<Icon as={sub.icon} />
									<span>{sub.caption}</span>
								</MenuItem>)}
							</MenuList>

						</Menu>
					)}

				<Spacer mt='auto'></Spacer>
				<Tooltip label='ADMIN:Felhasználók' >
					<IconButton variant='ghost' onClick={() => path.open('users')} icon={<APPICONS.User />} aria-label="Beállítások" />
				</Tooltip>
				<Tooltip label='Kijelentkezés' >
					<IconButton variant='ghost' onClick={() => authSlice.setShowSignOut(true)} icon={<APPICONS.SignOut />} aria-label="Kijelentkezés" />
				</Tooltip>
				<Tooltip label='Beállítások' >
					<IconButton variant='ghost' onClick={() => path.open('preferences')} icon={<ICONS.Preferences />} aria-label="Beállítások" />
				</Tooltip>
			</Stack>
		</DarkMode>

	</Box>


	</>;
}



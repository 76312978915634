import { Box, BoxProps, Button, ButtonProps, Heading, IconButton, useToken } from "@chakra-ui/react";
import { Children as ReactChildren, ReactNode, cloneElement, createContext, isValidElement, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { HEADER_HEIGHT_PX } from "./Layout.TODO";
import { IWSysLayoutContext, WSysLayoutContext, WSysLayoutPage, WSysPageNode, useWSysLayoutContext } from "./LayoutManager";
import { ICONS } from "./theme";
import { WSysScroll } from "./utils";
import { WSysHubDisplay, WSysHubDisplayProps } from "./api.hub";


export interface WSysHeaderButtonProps extends Omit<ButtonProps, 'leftIcon'> {
    icon: React.ReactElement<any>
    minCols?: number;
}

export function WSysHeaderButton({ minCols = 1, icon, ...rest }: WSysHeaderButtonProps) {
    const layoutCtx = useContext(WSysLayoutContext);
    return !layoutCtx.isMobileFull && ((layoutCtx.page?.propsOut.widthCols || minCols) >= 1)
        ? <Button size='sm' leftIcon={icon}  {...rest} />
        : <IconButton px={4} size='sm' icon={icon} {...rest} aria-label={typeof rest.children === 'string' ? rest.children : ''} />
}


export interface WSysHeaderProps extends BoxProps {
    headerTitle?: ReactNode;
    tools?: ReactNode;
}

export function WSysHeader({ headerTitle, tools, ...rest }: WSysHeaderProps) {

    const { page, isMobileFull }: IWSysLayoutContext = useWSysLayoutContext();
    const navigate = useNavigate();

    if (!headerTitle) {
        headerTitle = <Heading size='md' textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' >{page.propsIn.title}</Heading>;
    }

    return <Box
        className='wsys-page-head'
        h={`${HEADER_HEIGHT_PX}px`} flex='0 0 auto'
        display='flex' flexDir='row' justifyContent='space-between' alignItems='center'

        borderBottom={`${page.propsOut.layerKind === 'popup' ? 2 : 4}px solid ${useToken('colors', 'wsysLine')}`}
        {...rest}
    >
        <Box >
            {page.closable && <IconButton aria-label='vissza' onClick={() => page.close()} isDisabled={page.propsIn.isEdited} size='md' variant='wsysHeadIconButton' icon={<ICONS.Back />} />}
            {(!page.closable && isMobileFull && !page.propsIn.isEdited) && <IconButton aria-label="home" onClick={() => navigate('/')} size='md' variant='wsysHeadIconButton' icon={<ICONS.Home />} />}

        </Box>
        <Box className='wsys-page-head-title' 
            flex={1} display='flex' flexDir='row' alignItems='center' justifyContent='flex-start'
            overflow='hidden' whiteSpace='nowrap'
            px={2}
        >
            {headerTitle}
        </Box>
        {tools && <Box px={2}>
            {tools}
        </Box>}
    </Box>
}

// ==================================================== PAGE INNER ==================================================
export interface WSysPageInnerProps extends Omit<BoxProps, 'order' | 'title'>, Partial<WSysHubDisplayProps> {
    header?: ReactNode;
    headerTitle?: ReactNode;
    tools?: ReactNode;

}

export function WSysPageInner({ header, headerTitle, tools, children, hubs, ...rest }: WSysPageInnerProps) {
    if (!header) {
        header = <WSysHeader headerTitle={headerTitle} tools={tools} />;
    }

    return <Box className='wsys-page' display='flex' flexDir='column' w='100%' h='100%' overflow='hidden'
        bg='var(--chakra-colors-chakra-body-bg)'
        {...rest}
    >
        {header}
        <Box className='wsys-page-body' position='relative'
            flex={1}
        >
            <WSysHubDisplay hubs={hubs}>
                {children}
            </WSysHubDisplay>
        </Box>
    </Box>
}




// ==================================================== PAGE 0 ==================================================
/*export interface WSysPageProps extends  WSysPageInnerProps, Partial<WSysHubDisplayProps> {
    page : WSysPageNode;
}

export function WSysPage0({page, children, hubs, ...rest}: WSysPage0Props) {


    return <WSysLayoutPage page={page}>
        <WSysPageInner {...rest} >
            <WSysHubDisplay hubs={hubs} >
                {children}
            </WSysHubDisplay>
        </WSysPageInner>
    </WSysLayoutPage>
}
    */

// ==================================================== PAGE ==================================================
export interface WSysPageProps extends WSysPageInnerProps {
    page: WSysPageNode;
    vertMode?: WSysVertGroupMode;
    fullScroll?: number;
}

export function WSysPage({ page, vertMode, children, fullScroll = 1, ...rest }: WSysPageProps) {


    if (!vertMode && page)
        vertMode = page.propsOut.widthCols > fullScroll ? 'grow' : 'scroll'

    return <WSysLayoutPage {...rest} page={page} >
        <WSysPageInner {...rest} >
            <WSysVertGroup mode={vertMode} >
                {children}
            </WSysVertGroup>
        </WSysPageInner>
    </WSysLayoutPage>
}

//  ============================================================ VERTICAL ==================================================
//  ============================================================ VERTICAL ==================================================
//  ============================================================ VERTICAL ==================================================

export type WSysVertGroupMode = 'fix' | 'grow' | 'scroll';

export interface IWSysVertContext {
    groupMode: WSysVertGroupMode;
}

export const WSysVertContext = createContext<IWSysVertContext>({
    groupMode: 'grow'
});

export interface WSysVertGroupProps extends BoxProps {
    mode?: WSysVertGroupMode;
}


export function WSysVertGroup({ children, mode = 'grow', ...rest }: WSysVertGroupProps) {

    const ctx = useMemo(() => ({
        groupMode: mode
    }), [mode])

    /*const clonedChildren = <>
        {ReactChildren.map(children, child => (
            isValidElement(child)
                ? cloneElement(child as any, { __groupMode: mode })
                : child
        ))}
    </>*/

    return <WSysVertContext.Provider value={ctx}>

        {mode === 'fix' &&
            <Box className='wsys-vert-group-fix'
                flex={1} display='flex' flexDir='column' justifyContent='stretch' w='100%' h='100%' overflow='hidden'
                {...rest}
            >
                {children}
            </Box>}


        {mode === 'grow' &&
            <Box className='wsys-vert-group-grow'
                flex={1} display='flex' flexDir='column' w='100%' h='100%' overflow='hidden'
                {...rest}
            >
                {children}
            </Box>}

        {mode === 'scroll' &&
            <WSysScroll>
                <Box className='wsys-vert-group-scroll'
                    minHeight='100%'
                    display='flex' flexDir='column'
                    {...rest}
                >
                    {children}
                </Box>
            </WSysScroll>
        }
    </WSysVertContext.Provider>
}

export interface WSysVertStaticProps extends BoxProps {
    //__groupMode?: WSysVertGroupMode;
    mode: 'static' | 'inline' | 'rest';
    grow?: number;
}

export function WSysVert({ mode, grow = 1, children, ...rest }: WSysVertStaticProps) {

    const { groupMode } = useContext(WSysVertContext);

    return <>
        {/* ========================  group: FIX  ======================== */}
        {groupMode === 'fix' &&
            <Box className='wsys-vert wsys-vert-fix'
                flex={`0 1 ${grow * 100}%`}
                {...rest}
                h={(200 * grow)}
            >
                <WSysScroll>
                    {children}
                </WSysScroll>
            </Box>
        }
        {/* ========================  group: GROW  ======================== */}

        {mode === 'static' && groupMode === 'grow' &&
            <Box className='wsys-vert-static wsys-vert-mode-grow'
                flex='1 1 auto' {...rest}
            >
                {children}
            </Box>
        }
        {mode != 'static' && groupMode === 'grow' &&
            <Box className='wsys-vert-table wsys-vert-mode-grow'
                flex='1 1 100%' {...rest} >
                <WSysScroll>
                    {children}
                </WSysScroll>
            </Box>
        }


        {/* ========================  group: SCROLL  ======================== */}
        {mode === 'static' && groupMode === 'scroll' &&
            <Box {...rest}>
                {children}
            </Box>
        }
        {mode != 'static' && groupMode === 'scroll' &&

            children

        }

    </>

}
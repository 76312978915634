import { Box, Button, FormControl, FormLabel, Input } from "@chakra-ui/react";




export function ProfilePassword() {

    return <Box>
        <FormControl >
            <FormLabel >Old password:</FormLabel>
            <Input type='password' />
        </FormControl>
        <FormControl >
            <FormLabel >New password:</FormLabel>
            <Input type='password' />
        </FormControl>
        <FormControl >
            <FormLabel >Ensure new password:</FormLabel>
            <Input type='password' />
        </FormControl>
        <Box mt={3} display='flex' flexDir='row' justifyContent='flex-end' >
            <Button variant='solid' colorScheme='primary' >Change password</Button>
        </Box>
    </Box>

}
import { Icon, Input, InputGroup, InputLeftElement, InputProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useWSysPath } from "../Path";
import { useDebounce } from "../utils";
import { ICONS } from "../theme";


export interface WSysSearchProps extends InputProps {
    
    name: string;
}

export function WSysSearch({name, ...rest}: WSysSearchProps) {
    const path = useWSysPath();

	const [paramQ, setParamQ] = path.useParam('q');
	const [typedQ, setTypedQ] = useState<string>(paramQ);
	const debouncedQ = useDebounce(typedQ);
	useEffect(() => {
		setParamQ(debouncedQ);
	}, [debouncedQ]);


    return <InputGroup size='sm' width='auto' >
    <InputLeftElement
        pointerEvents='none'
        children={<Icon color='gray.300' as={ICONS.Search} />}
    />
    <Input
        value={typedQ}
        onChange={(e) => setTypedQ(e.currentTarget.value)}
        textTransform='uppercase'
        //maxWidth='14em'
        {...rest}
    />
</InputGroup>
}